<template>
    <div class="row">
        <div class="col-md-12" v-bind:class="[showSender ? 'col-lg-6' : 'col-lg-12' ]" v-if="this.displayRecipientBlock">
            <div class="card">
                <div class="card-header" :class="[boxColor(recipient)]">
                  <h4>Destinataire</h4>
                </div>

                <div class="card-body">
                  <div class="row">
                    <div v-bind:class="[showSender ? 'col-lg-12' : 'col-lg-6' ]">
                        <contact :entity="recipient" :extended-zone-allowed="false" :show-customer-reference="isRegisteredLetterWithAR"></contact>
                    </div>
                    <div class="col-md-6" v-if="!showSender && !letterSent">
                        <p>
                            Le formulaire ci-contre permet de générer automatiquement une page porte-adresse avec les informations que vous avez renseignées.
                            <br/><br/>
                            Si toutefois votre premier document contient une adresse valide, vous avez la possibilité de l'utiliser sans avoir à ajouter une page porte-adresse.
                        </p>
                        <br/>
                        <button class="btn btn-lg btn-warning"
                                @click="autoRecipient"
                                v-tooltip="{content: 'Cliquez ici pour  extraire l\'adresse de la première page de votre premier document',
                                                placement: 'right'}"
                                :disabled="letterLocked || hideContactSheetPrice">
                            <i class="fa fa-address-card"></i>
                            Utiliser l'adresse du premier document

                            <i v-if="letterPriceRefreshing || hideContactSheetPrice" class="fas fa-circle-notch fa-spin"></i>
                            <span v-else-if="canDisplayContactSheetPriceDifference">({{ getDisplayContactSheetPriceDifference | formatFloat }}€)</span>
                        </button>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <transition name="fade">
            <div class="col-lg-6 col-md-12" v-show="showSender">
                <div class="card">
                    <div class="card-header" :class="[boxColor(sender)]">
                        <b-row>
                          <b-col class="col-5 m-sm-0">
                            <h4>Expéditeur</h4>
                          </b-col>
                          <b-col class="col-7 m-sm-0" v-if="!letterLocked" @click="reuseAccount">
                            <span class="btn btn-warning btn-sm float-right"><i class="fa fa-user"></i>&nbsp;Utiliser vos coordonnées</span>
                          </b-col>
                        </b-row>
                    </div>

                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                            <contact :entity="sender" :extended-zone-allowed="true"></contact>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Contact from './Contact.vue'
import {mapState, mapGetters, mapActions} from 'vuex'
import {RecipientState} from '../../../../constants/Recipient'
import {letterSent} from "../../../../store/module/letter/getters";

export default {
  data () {
    return {
      hideContactSheetPrice: true
    }
  },
  mounted () {

  },
  components: {
    'contact': Contact
  },
  methods: {
    ...mapActions('letter', [
      'reuseAccount'
    ]),
    boxColor (object) {
      if (this.letterSent) {
        return 'bg-info text-white'
      }

      if (this.letterSyncError) {
        return 'bg-danger text-white'
      } else if (object.status === RecipientState.VALID) {
        return 'bg-info text-white'
      } else {
        return 'bg-secondary text-white'
      }
    },
    ...mapActions('letter', [
      'putAutoRecipient'
    ]),
    autoRecipient () {
      this.hideContactSheetPrice = true
      this.putAutoRecipient()
    }
  },
  computed: {
    ...mapState('letter', {
      recipient: state => state.recipient,
      sender: state => state.sender,
      showSender (state) {
        return state.sendMode && state.sendMode.senderAvailable
      },
      isRegisteredLetterWithAR (state) {
        return state.sendMode && state.sendMode.id === 5
      }
    }),
    ...mapGetters('letter', [
      'letterLocked',
      'letterSent',
      'letterSyncError',
      'letterPriceRefreshing',
      'displayRecipientBlock',
      'canDisplayContactSheetPriceDifference',
      'getDisplayContactSheetPriceDifference'
    ])
  },
  watch: {
    letterLocked () {
      if (!this.letterLocked) {
        this.hideContactSheetPrice = false
      }
    }
  }
}
</script>

<style scoped>
    .fade-enter-active {
        transition: opacity .5s;
    }
    .fade-enter {
        opacity: 0;
    }
</style>
