<template>
  <div>
    <b-container>
      <b-row>
        <b-col sm="8" offset="2">
          <b-card-group>
            <b-card
              border-variant="info"
              header-bg-variant="info"
              header-text-variant="white"
              header-class="text-center"
              header-tag="header"
            >
              <template v-slot:header>
                <div class="card-header-big">Changement de votre mot de passe</div>
              </template>

              <b-card-text>
                <b-form v-on:submit.prevent="changePassword">
                  <b-form-group
                    id="fieldset-vertical"
                    label="Mot de passe actuel"
                    label-class="font-weight-bold"
                  >
                    <b-form-input
                      type="password"
                      v-model="currentPassword"
                      name="currentPassword"
                      v-validate="'required'"></b-form-input>
                    <div class="text-danger" v-show="errors.has('currentPassword')">{{ errors.first('currentPassword') }}</div>
                  </b-form-group>

                  <b-form-group
                    id="fieldset-vertical"
                    label="Nouveau mot de passe"
                    label-class="font-weight-bold"
                  >
                    <b-form-input
                      type="password"
                      v-model="plainPassword.first"
                      name="firstPassword"
                      v-validate="'required|passwordComplicity:8,1,1'"
                      ref="confirmation"></b-form-input>
                    <div class="text-danger" v-show="errors.has('firstPassword')">{{ errors.first('firstPassword') }}</div>
                  </b-form-group>

                  <b-form-group
                    id="fieldset-vertical"
                    label="Répéter le nouveau mot de passe"
                    label-class="font-weight-bold"
                  >
                    <b-form-input
                      type="password"
                      v-model="plainPassword.second"
                      name="secondPassword"
                      v-validate="'confirmed:confirmation'"></b-form-input>
                    <div class="text-danger" v-show="errors.has('secondPassword')">{{ errors.first('secondPassword') }}</div>
                  </b-form-group>

                  <router-link to="/batchletters/ag_list" class="btn btn-lg btn-danger"><i class="fa fa-chevron-left"></i> Annuler</router-link>
                  <b-button type="submit" variant="warning" size="lg" class="float-right"><i class="fas fa-check"></i>&nbsp;Changer</b-button>
                </b-form>
              </b-card-text>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import user from '@/api/user'

export default {
  data () {
    return {
      currentPassword: null,
      plainPassword: {
        first: null,
        second: null
      }
    }
  },
  methods: {
    changePassword () {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return
        }

        let formData = {
          currentPassword: this.currentPassword,
          plainPassword: this.plainPassword
        }

        user.changePassword(formData).then(
          response => {
            this.$router.push({
              name: 'default'
            })
          }
        ).catch(
          error => {
            this.$swal('Erreur de traitement', '', 'error')
          })
      })
    }
  }
}
</script>
