export const state = {
  notifications: {
    queue: [],
    max: 5
  }
}

export const mutations = {
  push (state, notifications) {
    if (notifications instanceof Array) {
      for (let notification of notifications) {
        state.notifications.queue.push(notification)

        if (state.notifications.queue.length > state.notifications.max) {
          state.notifications.queue.shift()
        }
      }
    } else {
      state.notifications.queue.push(notifications)
    }

    if (state.notifications.queue.length > state.notifications.max) {
      state.notifications.queue.shift()
    }
  },
  shift (state) {
    state.notifications.queue.shift()
  }
}
