<template>
  <div class="modal-preview">
    <div class="modal-header">
      <div class="text-center w-100">Ajouter un nouveau Copropriétaire</div>
      <span class="close-button" @click="$emit('close')">
            <i class="fa fa-times"></i>
        </span>
    </div>
    <div class="modal-body">
      <b-form>
        <co-owner-profile-form :coOwner="coOwner"></co-owner-profile-form>
        <co-owner-sending-options-form :coOwner="coOwner"></co-owner-sending-options-form>
      </b-form>
    </div>
    <div class="modal-footer">
      <b-button variant="danger" @click="$emit('close')"><i class="fa fa-times"/>&nbsp;Annuler</b-button>
      <b-button variant="primary" @click="addCoOwner"><i class="fas fa-user-plus"/>&nbsp;Ajouter</b-button>
    </div>
  </div>
</template>

<script>
import CoOwnerProfileForm from '@/components/form/CoOwnerProfileForm'
import CoOwnerSendingOptionsForm from '@/components/form/CoOwnerSendingOptionsForm'
import api from '@/api'
import authService from '@/service/auth'

export default {
  props: {
    batchLetter: Object
  },
  provide () {
    return {
      $validator: this.$validator
    }
  },
  data () {
    return {
      coOwner: {
        'reference': '',
        'gender': null,
        'lastName': '',
        'firstName': '',
        'email': '',
        'phone': '',
        'address': {
          address: '',
          postcode: '',
          city: '',
          country: 'FRANCE'
        },
        'sendLevel': this.batchLetter.sendLevel,
        'sendType': '',
        'isCompany': '',
        'companyName': ''
      }
    }
  },
  components: {
    CoOwnerProfileForm,
    CoOwnerSendingOptionsForm
  },
  methods: {
    addCoOwner () {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return
        }

        let formData = {
          'reference': this.coOwner.reference,
          'isCompany': this.coOwner.isCompany,
          'sendLevel': this.coOwner.sendLevel,
          'sendType': this.coOwner.sendType,
          'contact': {
            'companyName': this.coOwner.companyName,
            'gender': this.coOwner.gender,
            'lastName': this.coOwner.lastName,
            'firstName': this.coOwner.firstName,
            'email': this.coOwner.email,
            'phone': this.coOwner.phone,
            'address': this.coOwner.address
          }
        }

        api.postCoOwner(this.batchLetter.id, formData).then(response => {
          this.$emit('close')
        }).catch(
          error => {
            if (!authService.redirectToLoginIfNotLogged(error.response)) {
              let msg = ''
              if (error.response.data.errors.errors) {
                msg = error.response.data.errors.errors[0]
              } else {
                msg = 'Erreur lors de l\'exécution de votre demande'
              }

              this.$swal('Erreur lors de l\'exécution de votre demande', msg, 'error')
            }
          })
      })
    }
  }
}
</script>
