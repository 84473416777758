<template>
  <div>
    <div class="text-center">
      <div v-show="!loaded || contactRegionRefreshing" class="contact-img-refreshing">
        <i class="fas fa-circle-notch fa-spin fa-2x"></i>
      </div>
      <transition name="fade">
        <img v-auth-resource="getContactRegion()" v-on:load="onLoaded" class="contact-thumbnail" v-if="!contactRegionRefreshing" v-show="loaded">
      </transition>
      <br/><br/>
    </div>
    <div>
      <div>
        <i class="fa fa-info-circle"></i> Cette zone correspond à l'adresse du destinataire qui sera visible par la fenêtre de l'enveloppe.
        Assurez-vous qu'elle y apparaisse entièrement.
      </div>
      <br/>
      <div class="pretty p-default p-smooth" v-if="!this.letterProgrammedPreview">
        <input type="checkbox" :value="sendConfirmed" @change="$emit('update:sendConfirmed', $event.target.checked )"/>
        <div class="state p-warning">
          <label :class="{'confirm-checkbox-highlight': highlightCheckbox}">
            Je confirme vouloir envoyer mon courrier à l'adresse indiquée dans le cadre ci-dessus.
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters} from 'vuex'

  export default {
    data () {
      return {
        loaded: false,
      }
    },
    props: {
      highlightCheckbox: Boolean,
      sendConfirmed: Boolean
    },
    computed: {
      ...mapState('letter', {
        letter: state => state,
        letterStep: state => state.step,
      }),

      ...mapGetters('letter', [
        'contactRegionRefreshing',
        'letterProgrammedPreview'
      ])
    },
    methods: {
      onLoaded () {
        this.loaded = true
      },
      getContactRegion () {
        return '/api/paperletter/letters/' + this.letter.id + '/contact_region'
      }
    }
  }
</script>

<style scoped>
  .contact-thumbnail {
    width: auto;
    height: 125px;
    padding-top: 5px;
    padding-left: 5px;
    margin-bottom: 5px;

    box-shadow: 1px 2px 6px 3px rgba(0,0,0,.3);
    border-radius: 20px;
  }

  .pretty {
    white-space: inherit;
  }

  .pretty .state label{
    text-indent: 0;
    padding-left: 2rem;
  }

  .pretty .state label:after,
  .pretty .state label:before{
    top: 0;
  }

  .fade-enter-active {
    transition: opacity 1s ease-in-out;
  }

  .fade-enter-to {
    opacity: 1;
  }

  .fade-enter {
    opacity: 0;
  }
</style>
