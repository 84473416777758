import user from "../store/module/user";
import axios from 'axios'
import AuthService from "../service/auth";

const axiosAuth = axios.create()

axiosAuth.CancelToken = axios.CancelToken
axiosAuth.isCancel = axios.isCancel

/*
 * The interceptor here ensures that we check for the token in local storage every time an axiosAuth request is made
 */
axiosAuth.interceptors.request.use(
  (config) => {
    let bearerHeader = AuthService.getBearerHeader()
    if (bearerHeader) {
      config.headers['Authorization'] = bearerHeader
    }

    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

export default axiosAuth
