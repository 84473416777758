<template>
  <div id="page-container">
    <div id="content-wrap">
      <content-header></content-header>
      <div class="d-none d-sm-block">
        <b-img class="mt-3" center style="height: 100px" fluid :src="require('@/assets/images/onesyndic.png')"></b-img>
      </div>
      <dashboard-content></dashboard-content>
    </div>
    <content-footer></content-footer>
  </div>
</template>
<script>
import ContentHeader from '@/layout/shared/ContentHeader.vue'
import ContentFooter from '@/layout/shared/ContentFooter.vue'
import DashboardContent from '@/layout/shared/Content.vue'

export default {
  components: {
    ContentHeader,
    ContentFooter,
    DashboardContent
  }
}
</script>
