import { render, staticRenderFns } from "./ContentFooter.vue?vue&type=template&id=797e4515&scoped=true&"
import script from "./ContentFooter.vue?vue&type=script&lang=js&"
export * from "./ContentFooter.vue?vue&type=script&lang=js&"
import style0 from "./ContentFooter.vue?vue&type=style&index=0&id=797e4515&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "797e4515",
  null
  
)

export default component.exports