<template>
  <div>
    <template v-if="isAdminView && userSpecific">
      <h1 class="text-center">Facturation pour {{ user.commercialName }}
        <router-link :to="{ name: 'profile', params: {id: user.id}}">
          <b-button variant="success" class="fa-pull-right">
            <i class="fas fa-eye"></i>&nbsp;Voir profil
          </b-button>
        </router-link>
      </h1>
    </template>
    <template v-else>
      <h1 class="text-center">Facturation</h1>
    </template>
    <hr/>
    <b-card
      border-variant="info"
      header-bg-variant="info"
      header-text-variant="white"
      header-class="text-center card-body-condensed"
      header-tag="header"
    >
      <template v-slot:header>
        <h3>Liste des factures</h3>
      </template>

      <div class="vld-parent">
        <loading :isLoading="isLoading"></loading>
        <v-server-table ref="billTable" name="billTable" :columns="getColumns()" :options="tableOptions()" @loaded="onTableLoaded" @loading="onTableLoading">
          <template  slot="customReferences" slot-scope="props">
            {{ getCoOwnershipName(props.row.customReferences) }}
          </template>

          <template  slot="user" slot-scope="props">
            <router-link :to="{ name: 'profile', params: {id: props.row.user.id}}"> {{ props.row.user.commercialName }} </router-link>
          </template>

          <template  slot="action" slot-scope="props">
            <button class="btn btn-sm btn-success" @click="downloadBill(props.row.id)">
              <font-awesome-icon icon="eye" slot="action" class="fa-action"></font-awesome-icon>
              Voir
            </button>
            <button class="btn btn-sm btn-warning mt-1" @click="notifyBill(props.row.id)">
              <font-awesome-icon icon="paper-plane" slot="action" class="fa-action"></font-awesome-icon>
              Notifier
            </button>
          </template>
        </v-server-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import billApi from '@/api/bills'
import PreviewPopup from '@/components/PreviewPopup'
import moment from 'moment'
import {mapGetters} from 'vuex'
import authService from '@/service/auth'
import Loading from '../Loading'
import {PaymentStatus} from "../../service/payment";

export default {
  props: {
    user: Object,
    userSpecific: Boolean
  },
  components: {
    Loading
  },
  computed: {
    ...mapGetters('user', [
      'isAdminView'
    ])
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    onTableLoaded () {
      this.isLoading = false
    },
    onTableLoading(){
      this.isLoading = true
    },
    getColumns () {
      let cols
      if (this.isAdminView && !this.userSpecific) {
        cols = ['id', 'user', 'customReferences', 'paidBy', 'generationDate', 'status', 'valueHT', 'valueTVA', 'action']
      } else {
        cols = ['id', 'customReferences', 'paidBy', 'generationDate', 'status', 'valueHT', 'valueTVA', 'action']
      }

      return cols
    },
    tableOptions() {
      return {
        requestFunction: (data) => {
          if (this.user) {
            data.filters.user = this.user.id
          }

          return billApi.getBills(data)
            .catch(function (e) {
              if (!authService.redirectToLoginIfNotLogged(e.response)) {
                this.dispatch('error', e)
              }
            }.bind(this))
        },
        requestAdapter (data) {
          return {
            limit: data.limit,
            page: data.page,
            sort: data.orderBy ? data.orderBy : 'id',
            orderBy: data.ascending ? 'asc' : 'desc',
            filters: {
              user: data.user,
              payed: data.status,
            }
          }
        },
        responseAdapter: function (resp) {
          const data = []
          for (let row of resp.data.data) {
            data.push({
              'id': row['id'],
              'user': row['owner'],
              'customReferences': row['customReferences'],
              'paidBy': row['destContact']['companyName'],
              'generationDate': moment(row['generationDate']).format('DD/MM/YYYY'),
              'status': (row['payed']) ? 'Payé' : 'A payer',
              'valueHT': row['valueHT'] + ' €',
              'valueTVA': row['valueTva'] + ' €',
            })
          }

          return {
            data: data,
            count: resp.data.count
          }
        },
        listColumns: this.listColumns(),
        headings: {
          id: 'Numéro',
          user: 'Utilisateur',
          customReferences: 'Copropriété',
          paidBy: 'Paiement par',
          generationDate: 'Date',
          status: 'Statut',
          valueHT: 'Montant',
          valueTVA: 'Montant TVA',
          action: 'Actions',
        },
        orderBy: { ascending:false },
        filterByColumn: true,
        filterable: ['status'],
        customFilters: ['status'],
        sortable: ['id', 'status', 'generationDate'],
        saveState: true,
        storage: 'session'
      }
    },
    listColumns () {
      return {
        status: [
          { id: 1, text:  PaymentStatus.Paid },
          { id: 0, text:  PaymentStatus.NotPaid }
        ]
      }
    },
    getCoOwnershipName (customReferences) {
      for (let ref of customReferences) {
        if (ref.label === 'Copropriété') {
          return ref.value
        }
      }
    },
    downloadBill (id) {
      let url = billApi.downloadBillLink(id)
      this.$modal.show(PreviewPopup, {
        url: url,
        name: `Facture_${id}.pdf`
      }, {
        width: '55%',
        height: 'auto',
        scrollable: true,
        adaptive: true
      })
    },
    notifyBill (id) {
      billApi.notifyBill(id)
        .then(response => {
            this.$swal('Facture notifiée avec succès', '', 'success')
        })
        .catch(
          error => {
            if (!authService.redirectToLoginIfNotLogged(error.response)) {
              this.$swal('Erreur de traitement', 'Impossible de notifier la facture', 'error')
            }
          }
        )
    }
  }
}
</script>
