import {state, mutations} from './mutations'
import * as actions from './actions'
import * as getters from './getters'
import {contactModule} from '../contact'
import cloneDeep from 'lodash/cloneDeep'

export default {
  namespaced: true,
  modules: {
    recipient: cloneDeep(contactModule),
    sender: cloneDeep(contactModule)
  },
  state,
  mutations,
  actions,
  getters
}
