<template>
  <div>
    <b-card
      border-variant="info"
    >
    <router-link :to="{ name: 'batchletters_detail', params: {id: batchLetterId}}" class="btn btn-lg btn-default btn-warning"><i class="fa fa-chevron-left"></i> Retour</router-link>
    </b-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      batchLetterId: parseInt(this.$route.params.batchLetterId)
    }
  }
}
</script>

<style scoped>
</style>
