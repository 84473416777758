export const DocumentState = {
  UPLOADING: 'UPLOADING',
  UPLOADED: 'UPLOADED',
  ERROR: 'ERROR',
  REMOVING: 'REMOVING'
}

export const FileAcceptList = {
  LIST: '.pdf,.jpg,.jpeg,.png,.gif,.doc,.docx,.dot,.xls,.xlsx,.xlt,.ppt,.pot,.odt,.ott,.ods,.odp,.txt,.tiff,.tif,.bmp'
}
