import axios from 'axios'
import axiosAuth from './axios-auth'

export default {
  login (username, password) {
    return axios.post(`/api/login`, {
      username: username,
      password: password
    })
  },
  logout () {
    return axiosAuth.get(`/api/logout`)
  },
  getInfo (id) {
    return axiosAuth.get(`/api/users/` + id)
  },
  resetRequest (username) {
    return axiosAuth.post(`/api/resetting/request`, {
      username: username
    })
  },
  verifyPasswordToken (token) {
    return axiosAuth.get(`/api/resetting/reset/` + token)
  },
  resetPassword (token, plainPassword) {
    return axiosAuth.post(`/api/resetting/reset/` + token, {
      plainPassword
    })
  },
  help (message) {
    return axiosAuth.post(`/api/user/help`, {
      'message': message
    })
  },
  updateProfile (id, user) {
    return axiosAuth.put(`/api/users/${id}`, user)
  },
  changePassword (formDate) {
    return axiosAuth.post(`/api/user/change_password`, formDate)
  },
  refreshJwtToken () {
    return axiosAuth.get('/api/token/refresh')
  },
  getUsers (params) {
    return axiosAuth.get('/api/users', {params})
  }
}
