<template>
  <div v-if="sendMode" class="box-body mode-container letter-type-name">
    <i class="fa fa-envelope" :style="{color: sendMode.displayColor}"></i>&nbsp;
    <b>{{ sendMode.name }}</b>
    <div v-if="letter.trackingNumber">
      <br>
      Numéro de suivi :
      <a target="_blank" :href="getTrackingUrl()">
        <b><img height="20" src="@/assets/images/laposte.png"/> {{ letter.trackingNumber }}</b>
      </a>

      <br/>
      Statut de l'envoi : <b>{{ getTrackingStatus() }}</b>
      <br/>
      Obtenir l'avis de dépôt :
      <a target="_blank" :href="getDepositSlipUrl()">
        <i class="glyphicon glyphicon-file"></i>Télécharger ici
      </a>
      <br/>
      <br/>

      Tarif facturé : {{ letter.price | formatFloat }} € TTC
    </div>

  </div>
</template>

<script>
  import {mapGetters, mapState} from 'vuex'
  import LetterStatusService from "../../../../service/LetterStatusService";

  export default {
    methods: {
      getDepositSlipUrl() {
        return '/api/paperletter/letters/' + this.letter.id + '/deposit_slip';
      },
      getTrackingUrl() {
        return LetterStatusService.getTrackingUrl(this.letter);
      },
      getTrackingStatus() {
        return LetterStatusService.getTrackingStatusText(this.letter);
      }
    },
    computed: {
      ...mapState([
        'loading'
      ]),
      ...mapState('letter', {
        letter: state => state,
      }),
      ...mapState('letter', [
        'sendMode',
        'letterSent'
      ]),
      ...mapGetters('letter', [
        'letterLocked',
        'letterPriceRefreshing'
      ])
    }
  }
</script>

<style scoped>
</style>
