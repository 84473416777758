<template>
  <div id="page-container">
    <div id="content-wrap">
      <content-header></content-header>

      <header class="masthead normal-blue-section text-white text-center">
        <div class="container  align-items-center">
          <b-row>
            <b-col md="6">
              <img class="masthead-avatar" :src="require('@/assets/images/onesyndic_gauche_text_white.png')" alt="" />
            </b-col>
            <b-col md="6">
              <p class="masthead-subheading font-weight-light">Solution simple, rapide et économique d’envoi de convocations aux A.G de copropriétés</p>
              <router-link :to="{ name: 'register' }"><button class="btn btn-warning btn-lg"><font-awesome-icon icon="user-plus"></font-awesome-icon>&nbsp;Inscription gratuite</button></router-link>
            </b-col>
          </b-row>
        </div>
      </header>

      <section class="page-section brief">
        <div class="container">
          <h2 class="page-section-heading text-center mb-0">En bref</h2>
          <div class="divider-custom">
            <div class="divider-custom-line"></div>
            <div class="divider-custom-icon"><i class="fas fa-paper-plane"></i></div>
            <div class="divider-custom-line"></div>
          </div>

          <div class="page-section-content">
            <div class="row text-center">
              <div class="col-md-6 col-lg-4">
                <div class="brief-item mx-auto">

                  <font-awesome-icon icon="hourglass-half" size="4x"></font-awesome-icon>
                  <h4 class="my-4 font-weight-bold">Gain de temps</h4>
                  <p class="grey-text">
                    Fini le temps passé à l'impression et à la mise sous pli.
                    Tout se passe désormais depuis votre ordinateur, de l'envoi au suivi !
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="brief-item mx-auto">

                  <b-img height="65px" :src="require('@/assets/images/tirelire.png')"></b-img>
                  <h4 class="my-4 font-weight-bold">Coûts maitrisés</h4>
                  <p class="grey-text">
                    L'inscription est gratuite, sans abonnement ni engagement. Vous payez uniquement ce que vous consommez.
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="brief-item mx-auto">

                  <b-img height="65px" :src="require('@/assets/images/eidas.png')"></b-img>
                  <h4 class="my-4 font-weight-bold">100% légal</h4>
                  <p class="grey-text">
                    Choisissez un envoi électronique via un opérateur certifié ou un envoi papier selon le choix de vos copropriétaires
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="block-service" class="page-section dark-blue-section">
        <h2 class="page-section-heading text-center mb-0">Fonctionnement du service</h2>

        <div class="divider-custom">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon"><i class="fas fa-paper-plane"></i></div>
          <div class="divider-custom-line"></div>
        </div>

        <div class="page-section-content container">
          <div class="page-section-main-text mb-5">
            OneSyndic est une solution simple, rapide et économique d’envoi de convocations aux A.G de copropriétés.<br/>
            <br/>
            Sans installation ni abonnement, OneSyndic vous permet d’envoyer en quelques clics l’ensemble des convocations à une assemblée générale. <br/>
            <br/>
            Que ce soit en collaboration avec votre logiciel de gestion de syndic ou alors de manière totalement indépendante, OneSyndic s’occupe de tout pour vous !
          </div>

          <b-row>
            <b-col md="6" class="text-center  d-none d-md-block">
              <b-row class="mt-1">
                <b-col md="5">
                  <font-awesome-icon style="font-size: 6rem;" icon="file-alt"></font-awesome-icon>
                  <div class="h5 mt-3">1. Vos destinataires</div>
                </b-col>
                <b-col md="5" offset-md="1">
                  <font-awesome-icon color="orange" style="font-size: 6rem;" icon="envelope"></font-awesome-icon>
                  <div class="h5 mt-2">2. Vos pièces jointes</div>
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col md="7" offset-md="1">
                  <font-awesome-layers style="height: 6rem; width: 8rem;">
                    <font-awesome-icon size="6x" icon="paper-plane"></font-awesome-icon>
                    <font-awesome-icon size="6x" class="pt-4 pl-3" color="green" icon="check" transform="shrink-5"></font-awesome-icon>
                  </font-awesome-layers>
                  <div class="text-center h5 mt-3">3. C'est prêt !</div>
                </b-col>
              </b-row>
            </b-col>
          <b-col md="6">
            <ol>
              <li>
                <div class="step-content">
                  <h3 class="title">Transmettez la liste de vos destinataires</h3>
                  <div class="subtitle">
                    Utilisez directement le fichier fourni par votre logiciel de syndic.<br/>
                    Vous pouvez aussi saisir manuellement et modifier à tout moment les coordonnées de chaque copropriétaire.
                  </div>
                </div>
              </li>
              <li>
                <div class="step-content">
                  <h3>Ajoutez vos pièces jointes</h3>
                  <div class="subtitle">ap
                    Joignez tous les documents souhaités à votre courrier : la convocation, les pouvoirs, les devis, etc.<br/>
                    Nous nous chargeons de les répartir dans chacune des enveloppes.
                  </div>
                </div>
              </li>
              <li>
                <div class="step-content">
                  <h3>Envoi de votre courrier</h3>
                  <div class="subtitle">
                    Après avoir choisi la date d'envoi souhaitée, nous nous chargeons d'acheminer votre courrier à son destinataire par voie papier ou électronique.
                  </div>
                </div>
              </li>
            </ol>
          </b-col>
          </b-row>
          <br/>

        </div>
      </section>

      <section class="page-section ">
        <h2 class="page-section-heading text-center mb-0">Modes d'envoi</h2>
        <div class="divider-custom">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon"><i class="fas fa-paper-plane"></i></div>
          <div class="divider-custom-line"></div>
        </div>

        <div class="page-section-content container">
          <div class="page-section-main-text mb-5">
            L'envoi de vos convocations peut être effectué de manière électronique ou en courrier papier.
            <br/><br/>

            Tout envoi de courrier électronique est possible depuis octobre 2015 et, en application du décret 2020-834, l’envoi en Courrier Confirmé OneSyndic peut, valablement, être utilisé pour les convocations aux A.G, les P.V d'assemblées et les mises en demeure.
            <br/>
            Grâce à un Opérateur Postal Numérique certifié eIDAS, OneSyndic vous permet d'envoyer vos courriers électroniques aux copropriétaires qui en ont fait la demande.
            <br/><br/>
            Et pour tous les autres destinataires n'ayant pas encore fait ce choix, nous continuons à envoyer les documents par lettre recommandée papier.
          </div>

          <div class="text-center">
            <b-img class="mr-5" height="100px" :src="require('@/assets/images/eidas.png')"></b-img>
            <b-img height="100px" :src="require('@/assets/images/visa-anssi.png')"></b-img>
          </div>
        </div>
      </section>

      <section class="page-section normal-blue-section">
        <h2 class="page-section-heading text-center mb-0">Tarifs et facturation</h2>
        <div class="page-section-content container">
          <b-row>
            <b-col sm="9"  class="page-section-main-text mt-4">
              <div>
                Aucun d’abonnement, aucun frais d’inscription ou de résiliation. Vous ne payez que vos envois.
                <br/>

                Les envois électroniques sont facturés 2,69€ HT indépendamment du nombre de documents et de feuilles.<br/>
                Les tarifs des envois papier, quant à eux, incluent l'impression, la mise sous pli et l'affranchissement selon les tarifs postaux en vigueur.
              </div>
              <br/>
              <div>
                Au moment de l'envoi, vous pouvez facilement répartir les coûts entre la copropriété et le syndic.
                Nous générons ensuite des factures détaillées et toujours conformes à votre choix.<br/>
              </div>
            </b-col>
            <b-col sm="3">
              <b-img height="250px" :src="require('@/assets/images/tirelire.png')"></b-img>
            </b-col>
          </b-row>
        </div>
      </section>

      <section class="page-section">
        <h2 class="page-section-heading text-center mb-0">Qui sommes nous ?</h2>
        <div class="page-section-content container">
          <b-row>
            <b-col lg="4" class="text-center">
              <b-img height="200px" :src="require('@/assets/images/clicissimo.png')"></b-img>
            </b-col>
            <b-col lg="8"  class="page-section-main-text mt-4">
              <p>OneSyndic est une marque de Clic’issimo.</p>
              <p>Notre société, créée en 2015, a déjà envoyé plusieurs millions de courriers en papier et en lettres recommandées électroniques pour le compte de ses utilisateurs.</p>
              <p>Nos clients sont principalement des professionnels, la confidentialité de vos données est donc une priorité pour nous et aucune de vos données ne sera jamais utilisée à des fins commerciales.</p>
            </b-col>
          </b-row>
        </div>
      </section>
    </div>
    <content-footer></content-footer>
  </div>

</template>
<script>

  import { mapGetters, mapMutations } from 'vuex'
  import ContentHeader from '@/layout/shared/ContentHeader.vue'
  import ContentFooter from '@/layout/shared/ContentFooter.vue'

  export default {
    components: {
      ContentHeader,
      ContentFooter
    },
    created () {
      if (!this.$store.getters['user/isAuthenticated']) {
        this.$store.dispatch('user/checkLogin').then(
          () => {
            this.$router.push({name: 'default'})
          }
        ).catch((error) => {})
      }
    },
    data () {
      return {
        login: null,
        password: null
      }
    },
    computed: {
      ...mapGetters('user', [
        'isAuthenticated',
        'isAuthenticating'
        // ...
      ])
    },
    methods: {

    }
  }
</script>

<style>
  .dark-blue-section {
    background-color: #2c3e50;
    color: white;
  }

  .normal-blue-section {
    background-color: #567f93;
    color: white;
  }

  .masthead {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .masthead .masthead-subheading {
    font-size: 1.25rem;
    padding-top: 1rem;
    margin-bottom: 2rem;
  }
  .masthead .masthead-avatar {
    width: 20rem;
  }

  /*.hide-xs {*/
  /*  display: none;*/
  /*}*/

  /*@media (min-width: 768px) {*/
  /*  .hide-xs {*/
  /*    display: block;*/
  /*  }*/
  /*}*/

  @media (min-width: 992px) {
    .masthead {
      padding-top: 4rem;
      padding-bottom: 6rem;
    }
    .masthead .masthead-subheading {
      font-size: 2rem;
    }
    .masthead .masthead-avatar {
      width: 25rem;
    }
  }

  .brief .brief-item {
    position: relative;
    display: block;
    max-width: 25rem;
    border-radius: 0.5rem;
    overflow: hidden;
    padding-right: 2rem;
  }
  .brief .brief-item .brief-item-caption {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    background-color: rgba(26, 188, 156, 0.9);
  }
  .brief .brief-item .brief-item-caption:hover {
    opacity: 1;
  }
  .brief .brief-item .brief-item-caption .brief-item-caption-content {
    font-size: 1.5rem;
  }

  .brief-modal .brief-modal-title {
    font-size: 2.25rem;
    line-height: 2rem;
  }
  @media (min-width: 992px) {
    .brief-modal .brief-modal-title {
      font-size: 3rem;
      line-height: 2.5rem;
    }
  }
  .brief-modal .close {
    position: absolute;
    z-index: 1;
    right: 1.5rem;
    top: 1rem;
    font-size: 3rem;
    line-height: 3rem;
    color: #1abc9c;
    opacity: 1;
  }

  .divider-custom {
    margin: 1.25rem 0 1.5rem;
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .divider-custom .divider-custom-line {
    width: 100%;
    max-width: 7rem;
    height: 0.25rem;
    background-color: #2c3e50;
    border-radius: 1rem;
    border-color: #2c3e50 !important;
  }

  .dark-blue-section .divider-custom .divider-custom-line, .normal-blue-section .divider-custom .divider-custom-line {
    background-color: #ffffff;
    border-color: #ffffff !important;
  }

  .dark-blue-section .divider-custom .divider-custom-icon, .normal-blue-section .divider-custom .divider-custom-icon {
    color: #ffffff !important;
  }

  .divider-custom .divider-custom-line:first-child {
    margin-right: 1rem;
  }
  .divider-custom .divider-custom-line:last-child {
    margin-left: 1rem;
  }
  .divider-custom .divider-custom-icon {
    color: #2c3e50 !important;
    font-size: 2rem;
  }
  .divider-custom.divider-light .divider-custom-line {
    background-color: #fff;
  }
  .divider-custom.divider-light .divider-custom-icon {
    color: #fff !important;
  }

  .page-section {
    padding: 3rem 0;
  }
  .page-section .page-section-heading {
    font-size: 2.25rem;
    line-height: 2rem;
  }

  .page-section .page-section-content {
    padding: 2rem 2rem 0rem 2rem;
  }

  @media (min-width: 992px) {
    .page-section .page-section-heading {
      font-size: 3rem;
      line-height: 2.5rem;
    }
  }

  #block-service ol li:before {
    position: absolute;
    counter-increment: a;
    content: counter(a);
    display: inline-block;
    vertical-align: middle;
    font-size: 24px;
    font-weight: 500;
    left: -45px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 50%;
    background: #f86700;
    color: #fff;
    /*transition: all .4s cubic-bezier(.15, .78, .46, .99)*/
  }

  #block-service ol li {
    position: relative;
    padding-left: 15px;
    margin-bottom: 2rem;
  }

  .step-content {
    display: inline;
  }

  #block-service ol {
    counter-reset: a;
    list-style: none;
  }

  .page-section-main-text {
    font-size: 1.5rem;
    /*margin-bottom: 3rem;*/
  }

  .subtitle {
    font-size: 1.05rem;
  }

</style>
