export default {
  isComplete(obj) {
   for (var key in obj) {
      if (obj[key] == "" || obj[key] == null) {
        return false
      }
    }
    return true
  }
}
