<template>
    <div class="row" v-if="this.displayContactRegion">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header box-remove-padding" :class="[boxColor]">
                    <h4 v-if="!letterSent && !letterCanceled">
                        <span v-if="recipientDetected">Assurez-vous que l'adresse apparaisse entièrement dans le cadre suivant :</span>
                        <span v-else><i class="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;Adresse non détectée sur votre premier document</span>
                    </h4>
                    <h4 v-else>Adresse du destinataire</h4>
                </div>

                <div class="card-body row">
                    <div class="col-md-6 text-center">
                        <div>
                            <div v-show="!loaded || contactRegionRefreshing" class="contact-img-refreshing">
                                <i class="fas fa-circle-notch fa-spin fa-2x"></i>
                            </div>
                            <transition name="fade">
                                <img v-auth-resource="getContactRegion()" v-on:load="onLoaded" class="contact-thumbnail" fluid v-if="!contactRegionRefreshing" v-show="loaded">
                            </transition>
                        </div>
                        <div>
                            <br/>
                            <i><i class="fa fa-info-circle"></i> Les caractères spéciaux ajoutés sont indispensables à l'envoi du courrier.</i>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div v-if="recipientDetected">
                            Cette zone correspond à l'adresse du destinataire qui sera visible par la fenêtre de l'enveloppe.
                            Elle est extraite de la première page de votre premier document.<br/>
                            <br/>
                        </div>
                        <div v-if="!letterSent && !letterCanceled">
                            <p v-if="recipientDetected">
                                <i class="fa fa-info-circle"></i>&nbsp;Si besoin, réorganisez l'ordre des documents de votre lettre en les glissant.
                                <br/>L'adresse doit être entièrement visible pour que la lettre arrive à destination.
                            </p>

                            <p v-else style="color: red;">
                                <i class="fa fa-exclamation-triangle text-red"></i>
                                Nous n'arrivons pas à détecter d'adresse sur votre premier document.<br/>
                                Vous pouvez :
                                <ul>
                                    <li>Réorganiser vos documents en les glissant pour faire apparaître une adresse valide.</li>
                                    <li> Ou alors saisir manuellement l'adresse du destinataire via le bouton ci-dessous.</li>
                                </ul>
                            </p>
                            <br/>

                            <button id="btn-custom-recipient"
                                    class="btn btn-warning"
                                    @click="customRecipient"
                                    v-tooltip="{content: 'Cliquez ici pour ins\érer une page porte adresse',
                                                placement: 'right'}"
                                    :disabled="letterLocked || hideContactSheetPrice">
                                <i class="fas fa-pencil-alt"></i> Saisir manuellement l'adresse du destinataire
                                <i v-if="letterPriceRefreshing || hideContactSheetPrice" class="fas fa-circle-notch fa-spin"></i>
                                <span v-else-if="canDisplayContactSheetPriceDifference">({{ getDisplayContactSheetPriceDifference | formatFloat }}€)</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

export default {
  data: () => ({
    loaded: false,
    hideContactSheetPrice: true
  }),
  computed: {
    ...mapState('letter', {
      letter: state => state
    }),
    ...mapGetters('letter', [
      'contactRegionRefreshing',
      'displayContactRegion',
      'recipientDetected',
      'letterSent',
      'letterCanceled',
      'letterPriceRefreshing',
      'letterLocked',
      'canDisplayContactSheetPriceDifference',
      'getDisplayContactSheetPriceDifference'
    ]),
    boxColor () {
      if (this.letterSent || this.letterCanceled || this.recipientDetected) {
        return 'bg-info text-white'
      } else {
        return 'bg-danger text-white'
      }
    }
  },
  methods: {
    onLoaded () {
      this.loaded = true
      this.hideContactSheetPrice = false
    },
    getContactRegion: function () {
      if (this.letter.id) {
        return '/api/paperletter/letters/' + this.letter.id + '/contact_region'
      }
    },
    ...mapActions('letter', [
      'putCustomRecipient'
    ]),
    customRecipient () {
      this.putCustomRecipient()
    }
  }
}
</script>

<style scoped>
    #btn-custom-recipient {
      font-size: 1.05rem;
    }
    .contact-thumbnail {
        width: auto;
        height: 175px;
        padding-top: 5px;
        padding-left: 5px;
        margin-bottom: 5px;

        box-shadow: 1px 2px 6px 3px rgba(0,0,0,.3);
        border-radius: 20px;
    }

    .contact-img-refreshing {
        font-size: 24pt;
        margin-top: 10px;
    }

    .fade-enter-active {
        transition: opacity 1s ease-in-out;
    }

    .fade-enter-to {
        opacity: 1;
    }

    .fade-enter {
        opacity: 0;
    }

    @media (max-width: 576px) {
      .contact-thumbnail {
        height: 150px;
      }
    }
</style>
