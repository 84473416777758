import axiosAuth  from './axios-auth'

export default {
  getBills(params) {
    return axiosAuth.get(`/api/bills`,{params})
  },
  downloadBillLink (id) {
    return `/api/bills/${id}/download`
  },
  notifyBill (id) {
    return axiosAuth.post(`/api/bills/${id}/notify`)
  },
  getUserReceipts (params) {
    return axiosAuth.get(`/api/receipts`, {params})
  },
  downloadReceiptLink (id) {
    return `/api/receipts/${id}/download`
  },
}
