<template>
    <div>
        <div class="global-error" v-if="letterSyncError">
            Une erreur est survenue lors de la validation du destinataire de votre lettre. Merci de vérifier que tous les champs soient remplis et corrects.
        </div>

        <div v-if="letterSent" class="info-box">
          <span class="info-box info-box-icon bg-warning"><i class="fas fa-user"></i></span>
        </div>

        <div v-if="letterSent">
          <template v-if="fullname">
            {{ fullname.toUpperCase() }}
          </template>
        </div>
        <div v-else class="form-group" :class="{'has-error': errors.has('fullname')}">
            <label class="control-label" for="fullname">Nom et prénom / Entreprise *</label>
            <input id="fullname" class="form-control" name="fullname" autocomplete="off" v-model="fullname"
                   v-validate="'required|max:38'"
                   :data-vv-delay="debounceTime"
                   :disabled="fieldsDisabled">
            <div class="text-danger" v-show="errors.has('fullname')">{{ errors.first('fullname') }}</div>
        </div>

        <div v-if="letterSent">
          <template v-if="address">
            <!-- eslint-disable-next-line vue/require-v-for-key -->
            <div v-for="address in address.split('\n')">
              {{ address.toUpperCase() }}
            </div>
          </template>
        </div>
        <div v-else class="form-group" :class="{'has-error': errors.has('address')}">
            <label class="control-label" for="address">Adresse et complément d'adresse *</label>
            <i class="fa fa-info-circle"
              v-tooltip="'Il s\'agit de l\'adresse complète du destinataire qui apparaîtra sur l\'enveloppe'"></i>
            <textarea id="address" class="form-control" name="address" v-model="address" rows="4"
                   autocomplete="off"
                   placeholder="1 à 4 lignes de 38 caractères maxiumum"
                   v-validate="'required|addressLimit'"
                   :data-vv-delay="debounceTime"
                   :disabled="fieldsDisabled">
            </textarea>
            <div class="text-danger" v-show="errors.has('address')">{{ errors.first('address') }}</div>
        </div>

        <div v-if="letterSent">
          {{ postcode }}
          <template v-if="city">
            {{ city.toUpperCase() }}
          </template>
        </div>
        <div v-else class="form-group row">
            <div class="col-12 col-md-4">
                <auto-complete
                        name="postcode"
                        v-validate="'required|digits:5'"
                        v-model="postcode"
                        :postcode="postcode"
                        :inputChange="postCodeChange"
                        :itemClicked="postCodeClicked">
                </auto-complete>
            </div>
            <div class="col-12 col-md-8">
                <div class="form-group" :class="{'has-error': errors.has('city')}">
                    <label class="control-label" for="city">Ville</label>
                    <input id="city" class="form-control" name="city" v-model="city"
                           placeholder="Saisir un code postal"
                           v-validate="'required'"
                           :data-vv-delay="debounceTime"
                           :disabled="fieldsDisabled"
                           :readonly="!isAdminView">
                    <div class="text-danger" v-show="errors.has('city')">{{ errors.first('city') }}</div>
                </div>
            </div>
        </div>

        <div v-if="letterSent">
          <template v-if="customerReference">
            (Référence client : {{ customerReference }})
          </template>
        </div>
        <div v-else class="form-group" :class="{'has-error': errors.has('customerReference')}" v-show="showCustomerReference">
            <label class="control-label" for="customerReference">Votre référence client : </label>
            <i class="fa fa-info-circle"
               v-tooltip="'Il s\'agit d\'une référence qui sera automatiquement reportée sur l\'accusé de réception'"></i>
            <input id="customerReference" class="form-control" name="customerReference" autocomplete="off" v-model="customerReference"
                   placeholder="[optionnel] 38 caractères au max"
                   v-validate="'max:38'"
                   :data-vv-delay="debounceTime"
                   :disabled="fieldsDisabled">
            <div class="text-danger" v-show="errors.has('customerReference')">{{ errors.first('customerReference') }}</div>
        </div>

    </div>
</template>

<script>
import AutoComplete from './AutoComplete.vue'
import {mapState, mapGetters, mapActions} from 'vuex'
import {RecipientState} from '../../../../constants/Recipient'

const VALIDATION_DEBOUNCE_TIME = 1000

/**
     * Return a getter/setter field to be used as model for editing a recipient property
     *
     * @param {String} field The recipient field to read/write from the state
     * @param {Boolean} debounced If set to true, actions will be sent every 300msec.
     */
let mapEntity = (field) => ({
  get () {
    return this.entity[field]
  },
  set: function (value) {
    this.updateContact({object: this.entity, field, value})
  }
})

export default {
  data () {
    return {
      debounceTime: VALIDATION_DEBOUNCE_TIME
    }
  },
  components: {
    'auto-complete': AutoComplete
  },
  mounted () {
    this.$nextTick(function () {
      this.$watch(() => this.formFields, () => {
        if (this.isFormValid() && this.entity.status !== RecipientState.VALID) {
          this.updateContactStatus({object: this.entity, status: RecipientState.VALID})
          return
        }

        if (this.isFormInvalid() && this.entity.status !== RecipientState.EDITING) {
          this.updateContactStatus({object: this.entity, status: RecipientState.EDITING})
        }
      }, {deep: true})
    })
  },
  props: {
    entity: Object,
    extendedZoneAllowed: Boolean,
    showCustomerReference: Boolean
  },
  computed: {
    fullname: mapEntity('fullname'),
    address: mapEntity('address'),
    postcode: mapEntity('postcode'),
    city: mapEntity('city'),
    customerReference: mapEntity('customerReference'),
    ...mapState([
      'loading',
      'isAdminView',
    ]),
    ...mapGetters('letter', [
      'letterLocked',
      'letterSyncError',
      'letterSent'
    ]),
    fieldsDisabled () {
      return false
    }
  },
  methods: {
    isFormValid () {
      return Object.keys(this.formFields).every(key => this.formFields[key].valid)
    },
    isFormInvalid () {
      return !this.isFormValid() &&
                    Object.keys(this.formFields).some(key => this.formFields[key].invalid)
    },
    ...mapActions('letter', [
      'updateContact',
      'updateContactStatus'
    ]),
    postCodeChange (value) {
      this.postcode = value
      this.city = ''
    },
    postCodeClicked (item) {
      if (item.nom_de_la_commune && (this.extendedZoneAllowed || !item.extended_zone)) {
        this.city = item.nom_de_la_commune
        this.postcode = item.code_postal
      } else {
        this.$swal('Pour le moment, les envois vers cette destination ne sont pas pris en charge par notre service', 'Destination non prise en charge', 'warning')
      }
    }
  }
}
</script>

<style scoped>
    .form-group .text-danger {
        float: right;
    }

    .text-danger {
        color: #dd4b39;
    }

    .success-msg {
        position: relative;
        top: 5px;
    }

    a[disabled=disabled] {
        pointer-events: none;
    }

    .global-error {
        color: #dd4b39;
        font-weight: bold;
        margin-bottom: 15px;
        font-size: 16px;
    }

    .info-box-icon {
      border-top-left-radius: 2px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 2px;
      display: block;
      float: left;
      height: 90px;
      width: 90px;
      text-align: center;
      font-size: 45px;
      line-height: 90px;
      background: rgba(0,0,0,.2);
      color : white;
      margin-right: 10px;
    }

    .global-error {
      color: #dd4b39;
      font-weight: bold;
      margin-bottom: 15px;
      font-size: 16px;
    }
</style>
