<template>
    <div class="card" :class="[boxClass]" @dragenter="dragEnter" @dragleave="dragLeave" @dragover="dragOver" @drop="drop">
        <transition name="overlay-transition">
            <div id="drag-overlay" v-show="isDragging && !letterLocked">
                <div id="overlay-text">
                    <i class="fa fa-upload"></i>
                    Déposez vos fichiers ici
                </div>
            </div>
        </transition>
        <div class="card-header bg-info text-white">
          <b-row>
            <b-col>
              <span class="h4">Les documents de votre lettre</span>
            </b-col>
            <b-col cols="12" md="auto">
              <span v-show="printedPageCount" class="pull-right-md">
                <i class="fa fa-info-circle" v-tooltip="{content: 'L\'impression étant en recto / verso, le nombre de feuilles peut être différent du nombre de pages. Cependant, les pages de deux documents différents ne seront jamais réunies sur la même feuille.'}"></i>
                {{ printedPageCount }}
                <span>
                    <template v-if="printedPageCount > 1">
                        feuilles
                    </template>
                    <template v-else>
                        feuille
                    </template>
                    (Total : {{ pageCount }}
                    <template v-if="pageCount > 1">
                        pages)
                    </template>
                    <template v-else>
                        page)
                    </template>
                </span>
            </span>
            </b-col>
          </b-row>
        </div>
        <div class="card-body">
          <div class="text-center mb-2">
              <label class="file-select">
                    <button v-if="!letterSent() && !letterCanceled()" class="btn btn-lg btn-warning" :disabled="letterLocked" @click="chooseFile()">
                      <i class="fa fa-plus"></i><span>&nbsp;Ajouter un document</span>
                    </button>
                    <input v-if="!documentOperationInProgress()"
                           type="file"
                           name="input_document[file]"
                           ref="fileUploadInput"
                           v-bind:accept="acceptList"
                           @change="addDocument"
                           :disabled="letterLocked"
                    />
              </label>

                  <button class="btn btn-lg btn-warning" :disabled="letterLocked" v-if="isStaplingAvailable && (!letterSent() && !letterCanceled())" @click="toggleStapling()">
                    <i class="fa fa-paperclip"></i>
                    <span>
                        <span v-if="letter.stapled">Désagrafer tout</span>
                        <span v-else>&nbsp;Agrafer tout</span>

                        <span v-if="canDisplayPriceDifference && !hideStaplingPrice">({{ getDisplayablePriceDifference | formatFloat }}€)</span>
                        <i v-else class="fas fa-circle-notch fa-spin"></i>
                    </span>
                  </button>
          </div>
            <div>
                <draggable v-model="documentList" :options="{animation: 300, disabled: !canDragDocuments}">
                    <transition-group class="row" name="file-list">
                        <file class="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-xs-6" v-for="(doc, index) in documentList" :key="doc.internalId" :internalId="doc.internalId" :position="index">
                        </file>
                    </transition-group>
                </draggable>
            </div>
            <div v-if="isLetterCleared">
                <i><small style="color: red"> Fichiers supprimés 15 jours après l'envoi de la lettre</small></i>
            </div>
            <div v-else>
                <i><small>Taille maximale : 10 Mo par fichier / 95 feuilles maximum par enveloppe.<br/>
                    Formats autorisés : fichiers PDF / images PNG, JPEG, TIFF, GIF / formats bureautiques (Word, Excel, Powerpoint et LibreOffice : mise en forme non garantie)</small></i>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import File from './File.vue'
import draggable from 'vuedraggable'
import {FileAcceptList} from '../../../../constants/Document'

export default {
  components: {
    'file': File,
    'draggable': draggable
  },
  data: () => ({
    value: null,
    isDragging: false,
    dragTarget: null,
    hideStaplingPrice: false,
    acceptList: FileAcceptList.LIST
  }),
  methods: {
    ...mapGetters('letter', [
      'documentOperationInProgress',
      'letterSent',
      'letterCanceled'
    ]),
    ...mapActions('letter', [
      'addDroppedDocument',
      'addPickedDocument',
      'reorderDocuments',
      'putToggleStapling'
    ]),
    dragEnter (e) {
      e.preventDefault()
      e.stopPropagation()

      // If types contains "Files", then its means files is dragged and drop from pc
      let dt = e.dataTransfer
      let isFileDraggedFromOS = dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') !== -1 : dt.types.contains('Files'))

      this.dragTarget = e.target
      if (!this.documentOperationInProgress() && isFileDraggedFromOS) {
        this.isDragging = true
      }
    },
    dragLeave (e) {
      e.preventDefault()
      e.stopPropagation()
      if (this.dragTarget === e.target) {
        this.isDragging = false
      }
    },
    dragOver (e) {
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
      return false
    },
    drop (e) {
      e.preventDefault()

      if (this.canAddDocuments) {
        this.addDroppedDocument(e)
      }

      this.isDragging = false
    },
    addDocument (event) {
      if (!this.canAddDocuments) {
        return
      }

      this.addPickedDocument(event)
    },
    toggleStapling () {
      this.hideStaplingPrice = true
      this.putToggleStapling()
    },
    chooseFile () {
      this.$refs.fileUploadInput.click()
    }
  },
  computed: {
    ...mapState('letter', {
      pageCount: state => state.pageCount,
      doubleSidedPrinting: state => state.doubleSidedPrinting,
      printedPageCount: state => state.printedPageCount,
      letter: state => state,
      priceDifferences: state => state.priceDifferences
    }),
    documentList: {
      get () {
        return this.letter.documents
      },
      set: function (value) {
        this.reorderDocuments(value)
      }
    },
    ...mapGetters('letter', [
      'letterLocked',
      'canSendLetter',
      'isPublipostage',
      'canAddDocuments',
      'isLetterCleared',
      'isStaplingAvailable',
      'hasDocuments',
      'hasDocumentsWithError',
      'letterPriceRefreshing',
      'canDragDocuments'
    ]),
    boxClass () {
      if (this.hasDocumentsWithError) {
        return 'card-danger'
      } else if (this.hasDocuments) {
        return 'card-info'
      } else {
        return 'card-secondary'
      }
    },
    canDisplayPriceDifference () {
      return this.getPriceDifference !== 0 && !this.letterPriceRefreshing
    },
    getDisplayablePriceDifference () {
      let price = this.getPriceDifference
      if (price > 0) {
        return '+' + this.getPriceDifference
      } else {
        return this.getPriceDifference
      }
    },
    getPriceDifference () {
      if (!this.priceDifferences || !this.priceDifferences.sendMode) {
        return 0
      }

      return this.priceDifferences.sendMode['STAPLING'].price
    }
  },
  watch: {
    canDisplayPriceDifference () {
      if (this.canDisplayPriceDifference) {
        this.hideStaplingPrice = false
      }
    }
  }
}
</script>

<style scoped>
    @keyframes flickerAnimation {
        0%   { opacity:0.6; }
        50%  { opacity:0.2; }
        100% { opacity:0.6; }
    }
    @-o-keyframes flickerAnimation{
        0%   { opacity:0.6; }
        50%  { opacity:0.2; }
        100% { opacity:0.6; }
    }
    @-moz-keyframes flickerAnimation{
        0%   { opacity:0.6; }
        50%  { opacity:0.2; }
        100% { opacity:0.6; }
    }
    @-webkit-keyframes flickerAnimation{
        0%   { opacity:0.6; }
        50%  { opacity:0.2; }
        100% { opacity:0.6; }
    }
    .sortable-ghost {

        animation: flickerAnimation 1.5s infinite;
        opacity: .2;
    }

    .file-select-middle {
        margin-top: 50px;
    }

    /* Don't forget to hide the original file input! */
    .file-select > input[type="file"] {
        display: none;
    }

    #drag-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 2;
    }

    #overlay-text {
        position: absolute;
        top: 50%;
        left: calc(50% - 100px);
        color: #FFFFFF;
        font-size: 32px;
    }

    .overlay-transition-enter-active, .overlay-transition-leave-active {
        transition: all .5s ease
    }
    .overlay-transition-enter, .overlay-transition-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0
    }
    .file-list-item {
        display: inline-block;
        margin-right: 10px;
    }
    .file-list-enter-active, .file-list-leave-active {
        transition: all 0.5s;
    }
    .file-list-enter, .file-list-leave-to /* .list-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: translateY(30px);
    }

    @media (min-width: 1200px) {
        .col-lg-2_5 {
            width: 20%;
        }
    }

    @media (min-width: 992px) {
        .pull-right-md {
            float: right;
        }
    }

    @media (max-width: 991.98px) {
      span.h4 {
        font-size: 1.2rem;
      }
    }
</style>
