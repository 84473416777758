<template>
  <div>
    <h1 class="text-center">Détail de l'envoi pour {{ this.coOwnerName }}</h1>
    <br/>
    <b-row>
      <b-col md="6">
        <co-owner-informations :co-owner="coOwner" :batch-letter-id="batchLetterId"></co-owner-informations>
      </b-col>
      <b-col md="6">
        <co-owner-tracking :co-owner="coOwner" :batch-letter-id="batchLetterId"></co-owner-tracking>
      </b-col>
    </b-row>
    <br/>
    <b-row>
      <b-col md="12">
        <co-owner-documents :co-owner="coOwner" :batch-letter-id="batchLetterId"></co-owner-documents>
      </b-col>
    </b-row>
    <br/>
    <b-row>
      <b-col cols="12">
        <co-owner-return-button></co-owner-return-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from '@/api'
import CoOwnerInformations from '@/components/coowner/CoOwnerInformations'
import CoOwnerDocuments from '@/components/coowner/CoOwnerDocuments'
import CoOwnerTracking from '@/components/coowner/CoOwnerTracking'
import CoOwnerReturnButton from '@/components/coowner/CoOwnerReturnButton'
import authService from '@/service/auth'
import {EventBus} from '../../EventBus'

export default {
  created () {
    EventBus.$emit('showLoading')
    api.getBatchLetterCoOwner(this.batchLetterId, this.coOwnerId)
      .then(response => {
        this.coOwner = response.data
        EventBus.$emit('hideLoading')
      })
      .catch(
        error => {
          EventBus.$emit('hideLoading')
          authService.redirectToLoginIfNotLogged(error.response)
        }
      )
  },
  components: {
    CoOwnerInformations,
    CoOwnerDocuments,
    CoOwnerTracking,
    CoOwnerReturnButton
  },
  computed: {
    coOwnerName () {
      if (this.coOwner !== null) {
        if (this.coOwner.isCompany) {
          return this.coOwner.contact.companyName
        } else {
          let lastName = this.coOwner.contact.lastName ? this.coOwner.contact.lastName : ''
          let firstName = this.coOwner.contact.firstName ? this.coOwner.contact.firstName : ''

          return lastName + ' ' + firstName
        }
      }

      return null
    }
  },
  data () {
    return {
      coOwner: null,
      batchLetterId: parseInt(this.$route.params.batchLetterId),
      coOwnerId: parseInt(this.$route.params.coOwnerId)
    }
  }
}
</script>

<style scoped>

</style>
