
class ErrorResponseService {
  getJsonFieldSafer (obj, key) {
    return key.split('.').reduce(function (o, x) {
      return (typeof o === 'undefined' || o === null) ? o : o[x]
    }, obj)
  }
  getErrorData (error) {
    // eslint-disable-next-line no-prototype-builtins
    if (error.hasOwnProperty('responseJSON')) {
      return error.responseJSON
    } else {
      return error.response.data
    }
  }

  handleError (error, field, defaultMessage) {
    let notifications = []

    try {
      if (Array.isArray(error.errors.errors)) {
        for (let errorEntry of error.errors.errors) {
          notifications.push({type: 'error', title: 'Erreur', text: errorEntry})
        }
      }
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
    try {
      if (field) {
        for (let error of field) {
          notifications.push({type: 'error', title: 'Erreur', text: error})
        }
      }
      // eslint-disable-next-line no-empty
    } catch (e) {
    }

    // In case of errors different than 400
    if (notifications.length === 0) {
      notifications.push({type: 'error', title: 'Erreur', text: defaultMessage})
    }

    return notifications
  }

  handleLetterWithDocumentError (error) {
    let json = this.getErrorData(error)
    let field = this.getJsonFieldSafer(json, 'errors.children.document.children.file.errors')
    return this.handleError(json, field, 'Erreur inconnue lors de l\'envoi du fichier')
  }

  handleDocumentError (error) {
    let json = null
    let field = null
    if (error.response) {
      json = this.getErrorData(error)
      field = this.getJsonFieldSafer(json, 'errors.children.file.errors')
    }
    return this.handleError(json, field, 'Erreur inconnue lors de l\'envoi du fichier')
  }

  handleSendModeError (error) {
    let json = null
    let field = null
    if (error.response) {
      json = this.getErrorData(error)
      field = this.getJsonFieldSafer(json, 'children.sendMode.errors')
    }

    return this.handleError(json, field, 'Erreur inconnue lors du changement de mode d\'envoi')
  }

  handleCreditInsufficientError (error) {
    let json = null
    let field = null
    if (error.response) {
      json = this.getErrorData(error)
      field = this.getJsonFieldSafer(json, '')
    }
    return this.handleError(json, field, 'Impossible d\'envoyer la lettre')
  }

  handleConfirmationError (error) {
    let json = null
    let field = null
    if (error.response) {
      json = this.getErrorData(error)
      field = this.getJsonFieldSafer(json, '')
    }
    return this.handleError(json, field, 'Impossible d\'envoyer la lettre.')
  }

  handleUploadedDocumentWarnings (document) {
    let notifications = []
    if (document.converted) {
      notifications.push({type: 'warning', title: 'Vérification requise', text: 'Le document ' + document.name + ' que vous venez de fournir a été automatiquement converti au format PDF.\nNous vous recommandons de le vérifier, notamment pour vérifier que la mise en forme a bien été conservée.'})
    }

    return notifications
  }
}

export default new ErrorResponseService()
