<template>

        <transition name="fade">
                <img @click="previewDocument()" class="doc-thumbnail" v-auth-resource="getPreviewLink()" v-on:load="onLoaded" v-show="loaded">

        </transition>
</template>

<script>
import PreviewPopup from '@/components/PreviewPopup'
import axios from 'axios'
import AuthService from "../../../../service/auth";

export default {
  props: {
    letter: Object,
    document: Object
  },
  data: () => ({
    loaded: false
  }),
  methods: {
    onLoaded () {
      this.loaded = true
    },
    getPreviewLink: function () {
      return '/api/paperletter/letters/' + this.letter.id + '/documents/' + this.document.id + '/thumbnail'
    },
    getDocumentUrl () {
      return '/api/paperletter/letters/' + this.letter.id + '/documents/' + this.document.id + '/download'
    },
    previewDocument () {
      this.$modal.show(PreviewPopup, {
        url: this.getDocumentUrl(),
        name: this.document.name
      }, {
        width: '55%',
        height: 'auto',
        adaptive: true
      })
    }
  }
}
</script>

<style scoped>
    @media (min-width: 768px), (max-width: 576px) {
      .doc-thumbnail {
        height: 100% !important;
      }
    }

    .doc-thumbnail {
        border-radius: 2px;
        height: 90%;
        margin-bottom: 10px;
        margin-top: 8px;
    }

    .doc-thumbnail:hover {
        border: 2px solid #f0ad4e;
        border-radius: 4px;
        cursor: pointer;
    }

    .fade-enter-active {
        transition: opacity 1s ease-in-out;
    }

    .fade-enter-to {
        opacity: 1;
    }

    .fade-enter {
        opacity: 0;
    }

</style>
