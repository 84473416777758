<template>
    <span>
        <i v-if="loading" class="fas fa-circle-notch fa-spin mr-2"></i>
        <i v-else :class="iconClass" class="mr-2 fas"></i>
    </span>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    iconClass: String
  }
}
</script>
