<template>
  <b-container>
    <b-row>
      <b-col sm="8" offset="2">
        <b-card-group>
          <b-card
            border-variant="info"
            header-bg-variant="info"
            header-text-variant="white"
            header-class="text-center"
            header-tag="header"
          >
            <template v-slot:header>
              <div class="card-header-big">Réinitialisation du mot de passe</div>
            </template>

            <b-card-text>
              <b-form v-on:submit.prevent="resetPasswordRequest">
                <b-form-group
                  label="E-mail de l'utilisateur :"
                  label-for="input-email"
                  label-class="font-weight-bold"
                >
                  <b-form-input
                    type="email"
                    v-model="username"
                    name="email"
                    v-validate="'required|email'"
                  ></b-form-input>
                  <div class="text-danger" v-show="errors.has('email')">{{ errors.first('email') }}</div>
                </b-form-group>

                <router-link to="/login" class="btn btn-lg btn-danger"><i class="fa fa-chevron-left"></i> Annuler</router-link>
                <b-button type="submit" variant="warning" size="lg" class="float-right"><i class="fas fa-key"></i>&nbsp;Réinitialisation</b-button>
              </b-form>
            </b-card-text>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import user from '@/api/user'
export default {
  data () {
    return {
      username: null
    }
  },
  methods: {
    resetPasswordRequest () {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return
        }

        user.resetRequest(this.username).then(
          response => {
            let msg = 'Si l\'adresse renseignée est valide et correspond à un compte utilisateur, vous avez normalement reçu un email. Il contient un lien sur lequel il vous faudra cliquer pour réinitialiser votre mot de passe.\n' +
              ' Si vous ne recevez rien, vérifiez votre email et votre dossier spam.'

            this.$swal({
              title: 'Réinitialiser votre mot de passe',
              icon: 'success',
              text: msg,
              closeOnClickOutside: false,
              closeOnEsc: false
            }).then(
              this.$router.push({
                name: 'login'
              })
            )
          }).catch(error => {
          this.$swal('Erreur', 'Erreur lors de la tentative de réinitialisation du mot de passe', 'error')
        })
      })
    }
  }
}
</script>
