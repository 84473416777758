<template>
    <p class="contact">{{ contact.fullname | capitalize }}<br>
      <template v-if="contact.address">
        <span v-for="address in contact.address.split('\n')" v-bind:key="address">{{ address | capitalize }}<br/></span>
      </template>
      {{ contact.postcode }}
      <template v-if="contact.city">
        {{ contact.city | capitalize}}
      </template>
      <template v-if=" contact.customerReference">
        <br>(Référence client : {{ contact.customerReference }})
      </template>
    </p>
</template>

<script>
  export default {
    data () {
      return {

      }
    },
    props: {
      contact: {
        type: Object
      }
    },
    created () {

    },
  }
</script>

<style scoped>
  .contact {
    padding-left: 15px;
    padding-top: 5px;
  }
</style>
