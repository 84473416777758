import {LetterStep, LetterType} from '../../../constants/Letter'

import {DocumentState} from '../../../constants/Document'
import {types} from './mutation-types'
import Vue from 'vue'
import {RecipientState} from '../../../constants/Recipient'

const getDefaultState = () => {
  return {
    id: null,
    documents: [],
    pageCount: 0,
    printedPageCount: 0,
    pageRefreshing: false,
    color: true,
    sendMode: null,
    doubleSidedPrinting: true,
    price: null,
    stampPrice: null,
    priceDifferences: null,
    priceRefreshing: false,
    programmedDate: null,
    sendDate: null,
    step: LetterStep.CREATION,
    status: null,
    trackingNumber: null,
    collab: null,
    type: LetterType.SIMPLE,
    cleared: false,
    isAddressPageNeeded: false,
    contactRegionRefreshing: false,
    stapled: false,
    contactRegion: null,
    user: null
  }
}

export const state = getDefaultState()

export const mutations = {
  [types.SET_SEND_MODE] (state, sendMode) {
    state.sendMode = sendMode
  },
  [types.SET_COLOR] (state, color) {
    state.color = color
  },
  [types.SET_DOUBLE_SIDED_PRINTING] (state, doubleSidedPrinting) {
    state.doubleSidedPrinting = doubleSidedPrinting
  },
  [types.SET_LETTER] (state, letter) {
    state.id = letter.id
    state.pageCount = letter.pageCount
    state.printedPageCount = letter.printedPageCount
    state.doubleSidedPrinting = letter.doubleSidedPrinting
    state.color = letter.color
    state.sendMode = letter.sendMode
    state.programmedDate = letter.programmedDate
    state.sendDate = letter.sendDate
    state.step = letter.status
    state.status = letter.status
    state.recipient = {
      ...state.recipient,
      ...letter.recipient
    }
    state.sender = {
      ...state.sender,
      ...letter.sender
    }
    state.trackingNumber = letter.trackingNumber
    state.collab = letter.collab
    state.type = letter.type
    state.latestTracking = letter.latestTracking
    state.validRequest = letter.validRequest
    state.cleared = letter.cleared
    state.isAddressPageNeeded = letter.addressPageNeeded
    state.stapled = letter.stapled
    state.contactRegion = letter.contactRegion

    if (!state.isAddressPageNeeded && state.contactRegion && state.contactRegion.contactDetected === true) {
      state.recipient.status = RecipientState.VALID
    }

    if (letter.user) {
      state.user = letter.user
    }
  },
  [types.RESET_LETTER] (state) {
    Object.assign(state, getDefaultState())
  },
  [types.SET_LETTER_ID] (state, id) {
    state.id = id
  },
  [types.REFRESH_PRICE] (state, data) {
    if (data) {
      state.price = data.price
      state.stampPrice = data.stampPrice
      state.priceDifferences = data.differences
    } else {
      state.price = null
      state.stampPrice = null
      state.priceDifferences = null
    }
    state.priceRefreshing = false
  },
  [types.REFRESH_PAGES] (state, data) {
    if (data) {
      state.pageCount = data.pageCount
      state.printedPageCount = data.printedPageCount
      state.sendMode = data.sendMode
      state.contactRegion = data.contactRegion
      state.stapled = data.stapled
    } else {
      state.pageCount = 0
      state.printedPageCount = 0
    }
    state.pageRefreshing = false
  },
  [types.SET_PRICE_REFRESHING] () {
    state.priceRefreshing = true
  },
  [types.SET_PAGES_REFRESHING] () {
    state.pageRefreshing = true
  },
  [types.SET_PROGRAMMED_DATE] (state, programmedDate) {
    state.programmedDate = programmedDate
  },
  [types.SET_LETTER_STEP] (state, step) {
    state.step = step
  },
  [types.SET_ADDRESSPAGE_NEEDED_MODE] (state, isAddressPageNeeded) {
    state.isAddressPageNeeded = isAddressPageNeeded
  },
  [types.DOCUMENT_UPLOADING] (state, {internalId, fileName}) {
    state.documents.push({
      internalId: internalId,
      name: fileName,
      status: DocumentState.UPLOADING,
      pageCount: 0,
      percent: 0
    })
  },
  [types.DOCUMENT_UPLOADING_UPDATE_PROGRESS] (state, {internalId, percentCompleted}) {
    state.documents.find(doc => doc.internalId === internalId).percent = percentCompleted
  },
  [types.ADD_DOCUMENT] (state, {internalId, document}) {
    let storeDocument = {
      ...document,
      internalId,
      status: DocumentState.UPLOADED,
      percent: 100
    }

    if (!document.normalized) {
      storeDocument.status = DocumentState.ERROR
    }

    state.documents.push(storeDocument)
  },
  [types.DOCUMENT_UPLOADED] (state, {internalId, document}) {
    let storeDocument = state.documents.find(doc => doc.internalId === internalId)

    Vue.set(storeDocument, 'status', DocumentState.UPLOADED)
    Vue.set(storeDocument, 'id', document.id)
    Vue.set(storeDocument, 'percent', 100)
    Vue.set(storeDocument, 'pageCount', document.pageCount)
    Vue.set(storeDocument, 'normalized', document.normalized)
    Vue.set(storeDocument, 'name', document.name)

    if (!storeDocument.normalized) {
      Vue.set(storeDocument, 'status', DocumentState.ERROR)
    }
  },
  [types.DOCUMENT_ERROR] (state, internalId) {
    state.documents = state.documents.filter(doc => doc.internalId !== internalId)
  },
  [types.DOCUMENT_REMOVING] (state, internalId) {
    let document = state.documents.find(doc => doc.internalId === internalId)
    document.status = DocumentState.REMOVING
  },
  [types.REMOVE_DOCUMENT] (state, internalId) {
    state.documents = state.documents.filter(doc => doc.internalId !== internalId)
  },
  [types.DOCUMENT_REMOVING_ERROR] (state, internalId) {
    let document = state.documents.find(doc => doc.internalId === internalId)
    document.status = DocumentState.ERROR
  },
  [types.STAPLING_CHANGING] (state) {
    state.step = LetterStep.LOADING
  },
  [types.STAPLING_CHANGE_FINISHED] (state, {letterResponse}) {
    state.stapled = letterResponse.stapled
    state.step = LetterStep.CREATION
  },
  [types.STAPLING_CHANGE_ERROR] (state) {
    state.step = LetterStep.CREATION
  },
  [types.REORDERING_CHANGING] (state, data) {
    state.step = LetterStep.LOADING
    Vue.set(state, 'documents', data)
  },
  [types.REORDERING_ERROR] (state, data) {
    state.step = LetterStep.CREATION
    Vue.set(state, 'documents', data)
  },
  [types.REORDERING_FINISHED] (state) {
    state.step = LetterStep.CREATION
  },
  [types.UPDATE_CONTACT_FIELD] (state, {object, field, value}) {
    object[field] = value
  },
  [types.SET_CONTACT_STATUS] (state, {object, status}) {
    object.status = status
  },
  [types.RESET_CONTACT] (state, object) {
    object.fullname = null
    object.address = null
    object.postcode = null
    object.city = null
    object.customerReference = null
    object.status = RecipientState.EMPTY
    object.error = []
  },
  [types.REUSE_ACCOUNT] (state, {data, validCity}) {
    let object = state.sender
    object.fullname = data.commercialName
    object.address = data.address
    object.customerReference = data.customerReference
    if (data.addressComplement) {
      object.address = data.addressComplement + '\n' + object.address
    }

    if (validCity) {
      object.postcode = data.postCode
      object.city = validCity
      object.status = RecipientState.VALID
      object.error = []
    } else {
      object.status = RecipientState.EDITING
      object.error = []
    }
  },
  [types.SET_CONTACT_REGION_REFRESHING] () {
    state.contactRegionRefreshing = true
  },
  [types.REFRESH_CONTACT_REGION] () {
    state.contactRegionRefreshing = false
  },
  [types.PREFILL_RECIPIENT] (state, {data, prefill}) {
    let object = state.recipient

    if (prefill) {
      object.fullname = data.fullname
      object.address = data.address
      object.postcode = data.postcode
      object.city = data.city
      object.customerReference = data.customerReference
    } else {
      // state.recipient = null;
      object.fullname = null
      object.address = null
      object.postcode = null
      object.city = null
      object.customerReference = null
    }

    if (!state.isAddressPageNeeded &&
            ((state.sendMode && state.sendMode.senderAvailable) ||
              (state.contactRegion && !state.contactRegion.contactDetected))
    ) {
      object.status = RecipientState.EDITING
    } else {
      object.status = RecipientState.VALID
    }
  },
  [types.UPDATE_CONTACT_REGION] (state, data) {
    state.contactRegion = data
  }
}
