<template>
    <b-container>
        <b-row>
            <b-col sm="8" offset="2">
                <b-card
                        border-variant="info"
                        header="Primary"
                        header-bg-variant="info"
                        header-text-variant="white"
                        header-class="text-center"
                        header-tag="header"
                >
                    <template v-slot:header>
                        <div class="card-header-big">Inscription</div>
                    </template>

                  <b-form-group
                    label="Email"
                    label-for="input-vertical"
                    label-class="font-weight-bold"
                  >
                    <b-form-input
                      size="sm"
                      name="email"
                      v-model="user.email"
                      v-validate="'required|email'"
                    ></b-form-input>
                    <div class="text-danger" v-show="errors.has('email')">{{ errors.first('email') }}</div>
                  </b-form-group>

                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label="Mot de passe"
                        label-for="input-vertical"
                        label-class="font-weight-bold"
                      >
                        <b-form-input
                          type="password"
                          size="sm"
                          v-model="user.plainPassword.first"
                          name="password"
                          v-validate="'required|passwordComplicity:8,1,1'"
                          ref="confirmation"
                        ></b-form-input>
                        <div class="text-danger" v-show="errors.has('password')">{{ errors.first('password') }}</div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Vérifier le mot de passe"
                        label-for="input-vertical"
                        label-class="font-weight-bold"
                      >
                        <b-form-input
                          type="password"
                          size="sm"
                          v-model="user.plainPassword.second"
                          name="confirm-password"
                          v-validate="'confirmed:confirmation'"
                        ></b-form-input>
                        <div class="text-danger" v-show="errors.has('confirm-password')">{{ errors.first('confirm-password') }}</div>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <user-profile-form :user="user"></user-profile-form>
                  <hr/>
                  <b-button variant="warning" size="lg" @click="register" class="fa-pull-right">
                      <i class="fas fa-user-plus"></i>&nbsp;S'inscrire
                  </b-button>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import api from '@/api/index'
import UserProfileForm from './user/UserProfileForm'

export default {
  data () {
    return {
      user: {
        commercialName: null,
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        address: null,
        postCode: null,
        city: null,
        plainPassword: {
          first: null,
          second: null
        }
      }
    }
  },
  components: {
    UserProfileForm
  },
  provide () {
    return {
      $validator: this.$validator
    }
  },
  methods: {
    register () {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return
        }

        api.registration(this.user).then(
          response => {
            this.$router.push({
              name: 'registration_success'
            })
          },
          error => {
            this.$swal('Merci de vérifier les champs', '', 'error')
          }
        )
      })
    }
  }
}
</script>
