<template>
  <div class="modal-preview">
    <div class="modal-header">
      <div class="text-center w-100">Modification pour {{ fullName }}</div>
      <span class="close-button" @click="$emit('close')">
            <i class="fa fa-times"></i>
        </span>
    </div>
    <div class="modal-body">
      <b-form>
        <co-owner-profile-form :coOwner="coOwner"></co-owner-profile-form>
      </b-form>
    </div>
    <div class="modal-footer">
      <b-button variant="danger" @click="$emit('close')"><i class="fa fa-times"/>&nbsp;&nbsp;Annuler</b-button>
      <b-button variant="primary" @click="updateCoOwnerDetails"><i class="fas fa-pencil-alt"/>&nbsp;&nbsp;Modifier</b-button>
    </div>
  </div>
</template>

<script>
import api from '../../api'
import CoOwnerProfileForm from '@/components/form/CoOwnerProfileForm'
import authService from '@/service/auth'
import coOwnerService from '@/service/coowner'

export default {
  props: {
    batchLetterId: Number,
    coOwner: Object
  },
  components: {
    CoOwnerProfileForm
  },
  data () {
    return {
      fullName: this.getFullName()
    }
  },
  provide () {
    return {
      $validator: this.$validator
    }
  },
  methods: {
    getFullName () {
      if (this.coOwner.isCompany) {
        return this.coOwner.companyName
      } else {
        return this.coOwner.lastName + ' ' + this.coOwner.firstName
      }
    },
    updateCoOwnerDetails () {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return
        }
        let formData = {
          'reference': this.coOwner.reference,
          'isCompany': this.coOwner.isCompany,
          'contact': {
            'companyName': this.coOwner.companyName,
            'gender': this.coOwner.gender,
            'lastName': this.coOwner.lastName,
            'firstName': this.coOwner.firstName,
            'email': this.coOwner.email,
            'phone': this.coOwner.phone,
            'address': this.coOwner.address
          }
        }

        api.putCoOwner(this.batchLetterId, this.coOwner.id, formData).then(response => {
          this.$emit('close')
        }).catch(
          error => {
            if (!authService.redirectToLoginIfNotLogged(error.response)) {
              let msg = ''
              if (error.response.data.errors.errors) {
                msg = error.response.data.errors.errors[0]
                if (msg === coOwnerService.getTextInvalidationReason('REASON_FULLNAME_LIMIT')) {
                  msg += '\nLe total de caractères est actuellement de '
                    + (this.coOwner.gender + ' ' + this.coOwner.firstName + ' ' + this.coOwner.lastName).length
                }
              } else {
                msg = 'Erreur lors de l\'exécution de votre demande'
              }

              this.$swal('Erreur lors de l\'exécution de votre demande', msg, 'error')
            }
          }
        )
      })
    }
  }
}
</script>
