export const LetterStep = {
  CREATION: 'CREATION',
  LOADING: 'LOADING',
  SYNCHRONIZING: 'SYNCHRONIZING',
  SYNCHRONIZING_ERROR: 'SYNCHRONIZING_ERROR',
  PREVIEW_PAGE: 'PREVIEW_PAGE',
  SENDING: 'SENDING',
  SENDING_ERROR: 'SENDING_ERROR',
  SENT: 'SENT',
  DEPOSIT: 'DEPOSIT',
  CANCELED: 'CANCELED'
}

export const LetterState = {
  CREATION: 'CREATION',
  DEPOSIT_FAILED: 'DEPOSIT_FAILED',
  SENT: 'SENT',
  DEPOSIT: 'DEPOSIT',
  CANCELED: 'CANCELED',
  LARGE_FILE_SIZE: 'LARGE_FILE_SIZE',
  MERGED_FILE_ERROR: 'MERGED_FILE_ERROR',
  TAGG_SENDING_ERROR: 'TAGG_SENDING_ERROR'
}

export const LetterTracking = {
  SUBMITTED: 'SUBMITTED',
  DELIVERED: 'DELIVERED',
  RETURNED: 'RETURNED',
  CANCELED: 'CANCELED'
}

export const LetterRegisteredTracking = {
  ADDRESS_FAIL: 'PB ADRESSE',
  REFUSED: 'REFUSÉ',
  NOT_CLAIMED: 'NON RECLAMÉ',
  OTHER: 'AUTRE'
}

export const LetterType = {
  PUBLIPOSTAGE: 'PUBLIPOSTAGE',
  SIMPLE: 'SIMPLE'
}
