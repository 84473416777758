// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router/index'

import './assets/js/bootstrap-vue'
import './assets/js/fontawesome'

import { ServerTable } from 'vue-tables-2'
import VModal from 'vue-js-modal'
import VueSwal from 'vue-swal'
import store from './store'
import VTooltip from 'v-tooltip'
import VeeValidate from 'vee-validate'
import fr from 'vee-validate/dist/locale/fr'
import 'vue2-datepicker/locale/fr'

import VueAuthImage from '@/directives/vue-auth-image.js'

import './validator/validator.js'
import Loading from 'vue-loading-overlay'

// css assets.
import '@/assets/css/main.css'
import '@/assets/css/tooltip.css'
import '@/assets/css/box.css'
import 'pretty-checkbox/dist/pretty-checkbox.css'
import 'vue2-datepicker/index.css'
import 'vue-loading-overlay/dist/vue-loading.css'

let tableOptions = {
  sortIcon: {
    base: 'fas',
    is: 'fa-sort',
    up: 'fa-sort-up',
    down: 'fa-sort-down'
  },
  texts: {
    count: 'Affichage de {from} à {to} sur {count} résultats|{count} résultats|un résultat',
    noResults: 'Aucun résultat',
    filterPlaceholder: 'Filter',
    filterBy: 'Filtrer par {column}',
    defaultOption: 'Filtrer par {column}'
  },
  perPageValues: []
}

Vue.use(ServerTable, tableOptions, false, 'bootstrap4')

Vue.use(VModal, { dialog: true, dynamic: true, injectModalsContainer: true, dynamicDefaults: { clickToClose: true } })
Vue.use(VueSwal)
Vue.use(VTooltip)
Vue.use(VeeValidate, {
  locale: 'fr',
  fieldsBagName: 'formFields', // Prevents conflict with vuetable
  dictionary: {
    fr: {
      ...fr,
      messages: {
        required: () => 'Ce champ est obligatoire',
        digits: (field, [length]) => `Ce champ doit comporter ${length} chiffres`,
        max: (field, [length]) => `Ce champ ne doit pas dépasser ${length} caractères`,
        addressLimit: () => 'Ce champ ne doit pas dépasser 4 lignes et 38 caractères par ligne',
        referenceLimit: () => 'Ce champ ne doit comporter que des chiffres, 50 au maximum',
        email: () => 'L\'adresse e-mail est invalide',
        passwordComplicity: (field, [minLength, minAlphaLength, minNumericLength]) => `Votre mot de passe doit comporter au moins ${minLength} caractères, ${minAlphaLength} lettres et ${minNumericLength} chiffres`,
        confirmed: () => `Les deux mots de passe ne sont pas identiques.`
      }
    }
  }})


Vue.use(Loading)
Vue.use(VueAuthImage);

Vue.filter('formatFloat', function (value) {
  if (value === null || value === undefined) {
    return ''
  }
  return value.toString().replace('.', ',');
})

Vue.filter('capitalize', function (value) {
  if(!value) return ''
  let line = value.toLowerCase().split(' ');
  return line.map( function ( item ) {
    return item.slice( 0, 1 ).toUpperCase() + item.slice( 1 );
  }).join(' ');
})

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
})
