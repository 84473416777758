import { library, dom, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

import {
  faAddressCard,
  faArrowsAlt,
  faArrowLeft,
  faCalendar,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faCircleNotch,
  faClock,
  faCopy,
  faEdit,
  faEnvelope,
  faExchangeAlt,
  faExclamationTriangle,
  faEye,
  faFileAlt,
  faFileDownload,
  faHandPointer,
  faHome,
  faHourglassHalf,
  faKey,
  faInfoCircle,
  faList,
  faLock,
  faMinus,
  faPaperclip,
  faPaperPlane,
  faPencilAlt,
  faPiggyBank,
  faPlus,
  faPrint,
  faRedo,
  faQuestionCircle,
  faSignOutAlt,
  faSort,
  faSortDown,
  faSortUp,
  faTimes,
  faTimesCircle,
  faTint,
  faTrash,
  faTrashAlt,
  faUser,
  faUserEdit,
  faUserFriends,
  faUserPlus,
  faUsers,
  faEuroSign,
  faUserCog
} from '@fortawesome/free-solid-svg-icons'
import Vue from 'vue'

library.add(
  faAddressCard,
  faArrowsAlt,
  faArrowLeft,
  faCalendar,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faCircleNotch,
  faClock,
  faCopy,
  faEdit,
  faEnvelope,
  faExchangeAlt,
  faExclamationTriangle,
  faEye,
  faFileAlt,
  faFileDownload,
  faHandPointer,
  faHome,
  faHourglassHalf,
  faKey,
  faInfoCircle,
  faList,
  faLock,
  faMinus,
  faPaperclip,
  faPaperPlane,
  faPencilAlt,
  faPiggyBank,
  faPlus,
  faPrint,
  faQuestionCircle,
  faRedo,
  faSignOutAlt,
  faSort,
  faSortDown,
  faSortUp,
  faTimes,
  faTimesCircle,
  faTint,
  faTrash,
  faTrashAlt,
  faUser,
  faUserFriends,
  faUserEdit,
  faUserPlus,
  faUsers,
  faEuroSign,
  faUserCog
)

dom.watch()
config.autoReplaceSvg = 'nest'
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
