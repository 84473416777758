import {FacturationMode} from '../constants/Credit'
import store from '../store'

export default {
  hasEnoughCreditToBuy(creditAmount, user) {
    if (!user) {
      user = store.getters['user/getUser']
    }

    if (user && user.credit) {
      if (user.credit.facturationMode === FacturationMode.POST_PAID) {
        return true
      } else if (user.credit.allowNegative) {
        return true
      } else {
        return user.credit.value >= creditAmount
      }
    } else {
      return false
    }
  },
  getUserCreditValue(user){
    if (!user) {
      user = store.getters['user/getUser']
    }

    if (user && user.credit) {
      return user.credit.value
    } else {
      return 0.0
    }
  }
}
