/**
 * Return informations about PDF plugin availability
 * @returns {{browser, acrobat: *, acrobatVersion}}
 */
export default class AcrobatInfo {
  static getBrowserName () {
    return function () {
      let userAgent = navigator ? navigator.userAgent.toLowerCase() : 'other'

      if (userAgent.indexOf('chrome') > -1) {
        return 'chrome'
      } else if (userAgent.indexOf('safari') > -1) {
        return 'safari'
      } else if (userAgent.indexOf('msie') > -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        return 'ie'
      } else if (userAgent.indexOf('firefox') > -1) {
        return 'firefox'
      } else {
        // return "ie";
        return userAgent
      }
    }
  }

  static getActiveXObject (name) {
    try {
      // eslint-disable-next-line no-undef
      return new ActiveXObject(name)
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
  }

  static getNavigatorPlugin (name) {
    for (let key in navigator.plugins) {
      var plugin = navigator.plugins[key]
      if (plugin.name === name) return plugin
    }
  }

  static getPDFPlugin () {
    return function () {
      if (this.getBrowserName() === 'ie') {
        //
        // load the activeX control
        // AcroPDF.PDF is used by version 7 and later
        // PDF.PdfCtrl is used by version 6 and earlier
        return this.getActiveXObject('AcroPDF.PDF') ||
                    this.getActiveXObject('PDF.PdfCtrl')
      } else {
        return this.getNavigatorPlugin('Adobe Acrobat') ||
                    this.getNavigatorPlugin('Chrome PDF Viewer') ||
                    this.getNavigatorPlugin('Chromium PDF Viewer') ||
                    this.getNavigatorPlugin('WebKit built-in PDF') ||
                    this.getNavigatorPlugin('Edge PDF Viewer')
      }
    }
  }

  static isAcrobatInstalled () {
    return !!this.getPDFPlugin()
  }

  static getAcrobatVersion () {
    try {
      var plugin = this.getPDFPlugin()

      if (this.getBrowserName() === 'ie') {
        var versions = plugin.GetVersions().split(',')
        var latest = versions[0].split('=')
        return parseFloat(latest[1])
      }

      if (plugin.version) return parseInt(plugin.version)
      return plugin.name
    } catch (e) {
      return null
    }
  }

  static needsPdfJsFallback () {
    if (this.getBrowserName() === 'ie' || this.getAcrobatVersion() == null) {
      return true
    } else {
      return false
    }
  }
}
