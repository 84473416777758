<template>
  <div class="modal-preview">
    <div class="modal-content">
      <div class="modal-header">
        <div class="text-center w-100">Validation de l'envoi</div>
        <span class="close-button"  @click="$emit('close')">
            <i class="fa fa-times"></i>
        </span>
      </div>
      <div class="modal-body">
        <b-form>
          <div v-if="batchLetter.priceTtc[BillItemType.BILL_ITEM_TYPE_PAPER_LETTER_SERVICE]">
            <div class="h4">Options d'envoi</div>
            <div>
              <b-row class="my-1">
                <b-col sm="5">
                  <label> Impression en  : </label>
                </b-col>
                <b-col sm="7">
                  <b-form-radio-group
                    v-model="batchLetter.color"
                    :options="colorOptions"
                  ></b-form-radio-group>
                </b-col>
              </b-row>

              <b-row class="my-1">
                <b-col sm="5">
                  <label> Agrafer  : </label>
                </b-col>
                <b-col sm="7">
                  <b-form-checkbox
                    v-model="batchLetter.stapled"
                    :options="staplingOptions"
                  ></b-form-checkbox>
                </b-col>
              </b-row>
              <hr/>
            </div>
          </div>

          <b-row class="my-1">
            <b-col sm="5">
              <label> Date limite d'affranchissement* : </label>
            </b-col>
            <b-col sm="7">
              <datepicker :disabled="!minimumProgrammedDate" lang="fr" v-model="programmedDate" :first-day-of-week="1" :disabled-date="minimumDate"></datepicker>
            </b-col>
          </b-row>
          <hr/>
          <div class="h4">Facturation</div>
          <b-row class="my-1" v-if="batchLetter.priceTtc[BillItemType.BILL_ITEM_TYPE_PAPER_LETTER_SERVICE]">
            <b-col sm="5">
              <label> Impression / mise sous pli / agrafage ({{ batchLetter.priceTtc[BillItemType.BILL_ITEM_TYPE_PAPER_LETTER_SERVICE] }} € HT) : </label>
            </b-col>
            <b-col sm="7">
              <b-form-radio-group
                id="radio-group-1"
                v-model="batchLetter.billableItemPayers[BillItemType.BILL_ITEM_TYPE_PAPER_LETTER_SERVICE]"
                :options="payerOptions"
              ></b-form-radio-group>
            </b-col>
          </b-row>
          <b-row class="my-1" v-if="batchLetter.priceTtc[BillItemType.BILL_ITEM_TYPE_PAPER_LETTER_STAMP]">
            <b-col sm="5">
              <label> Affranchissement : Évaluation ({{ batchLetter.priceTtc[BillItemType.BILL_ITEM_TYPE_PAPER_LETTER_STAMP] }} €) : </label>
            </b-col>
            <b-col sm="7">
              <b-form-radio-group
                id="radio-group-2"
                v-model="batchLetter.billableItemPayers[BillItemType.BILL_ITEM_TYPE_PAPER_LETTER_STAMP]"
                :options="payerOptions"
              ></b-form-radio-group>
            </b-col>
          </b-row>
          <b-row class="my-1" v-if="batchLetter.priceTtc[BillItemType.BILL_ITEM_TYPE_SELF_DELIVERY]">
            <b-col sm="5">
              <label> Remise en main propre ({{ batchLetter.priceTtc[BillItemType.BILL_ITEM_TYPE_SELF_DELIVERY] }} € HT) : </label>
            </b-col>
            <b-col sm="7">
              <b-form-radio-group
                id="radio-group-3"
                v-model="batchLetter.billableItemPayers[BillItemType.BILL_ITEM_TYPE_SELF_DELIVERY]"
                :options="payerOptions"
              ></b-form-radio-group>
            </b-col>
          </b-row>
          <b-row class="my-1" v-if="batchLetter.priceTtc[BillItemType.BILL_ITEM_TYPE_LRE]">
            <b-col sm="5">
              <label> Courrier électronique ({{ batchLetter.priceTtc[BillItemType.BILL_ITEM_TYPE_LRE] }} € HT) : </label>
            </b-col>
            <b-col sm="7">
              <b-form-radio-group
                id="radio-group-4"
                v-model="batchLetter.billableItemPayers[BillItemType.BILL_ITEM_TYPE_LRE]"
                :options="payerOptions"
              ></b-form-radio-group>
            </b-col>
          </b-row>
          <b-row @click="arManagementClicked" class="my-1" v-if="batchLetter.priceTtc[BillItemType.BILL_ITEM_TYPE_PAPER_LETTER_SERVICE]">
            <b-col sm="5">
              <label> Gestion des AR par OneSyndic</label>
            </b-col>
            <b-col sm="7">
              <b-form-checkbox disabled="disabled"
                v-model="batchLetter.arManagement"
              ></b-form-checkbox>
            </b-col>
          </b-row>
          <hr/>
          <b-row class="my-1">
            <b-col sm="5">
              <label class="font-weight-bold"> Confirmer l'envoi*  </label>
            </b-col>
            <b-col sm="7">
              <b-form-checkbox class="mb-2 mr-sm-2 mb-sm-0" v-model="sendConfirmed"></b-form-checkbox>
            </b-col>
          </b-row>
        </b-form>
      </div>

      <div class="modal-footer">
        <b-button variant="danger" @click="$emit('close')"><i class="fa fa-times"/>&nbsp;&nbsp;Annuler</b-button>
        <b-button variant="primary" @click="sentLetter" :disabled="!allowSend">
          <loading-icon :loading="sendingLoading" icon-class="fa-paper-plane"></loading-icon>
          Valider l'envoi
        </b-button>
      </div>

    </div>
  </div>
</template>

<script>
import datepicker from 'vue2-datepicker'
import api from '../api/index'
import {BillItemTypePayer, BillItemType} from '../constants/BillItemTypePayer'
import authService from '@/service/auth'
import moment from 'moment'
import {mapGetters} from 'vuex'
import LoadingIcon from './LoadingIcon'

export default{
  mounted () {
    api.getMinimumProgrammedDate(this.batchLetter.id)
      .then(response => {
        this.minimumProgrammedDate = response.data.minimumProgrammedDateAction
      }).catch(
      error => {
        console.log(error)
      })
  },
  components: {
    datepicker,
    LoadingIcon
  },
  props: {
    batchLetter: Object
  },
  data () {
    return {
      programmedDate: null,
      minimumProgrammedDate: null,
      sendingLoading: false,
      sendConfirmed: null,
      BillItemType: BillItemType,
      payerOptions: [
        { text: 'Syndic', value: BillItemTypePayer.PAYER_SYNDIC },
        { text: 'Copropriété', value: BillItemTypePayer.PAYER_COPRO }
      ],
      colorOptions: [
        { text: 'Couleur', value: true },
        { text: 'Noir et blanc', value: false }
      ],
      staplingOptions: [
        { text: 'Oui', value: true },
        { text: 'Non', value: false }
      ]
    }
  },
  watch: {
    'batchLetter.color': function () {
      this.getPriceEstimation()
    },
    'batchLetter.stapled': function () {
      this.getPriceEstimation()
    }
  },
  computed: {
    allowSend () {
      return this.programmedDate !== null && this.sendConfirmed
    },
    ...mapGetters('user', [
      'isAdminView'
    ])
  },
  methods: {
    arManagementClicked() {
      this.$swal('Gestion des AR papier', 'Contactez nous pour activer cette fonctionnalité de gestion des AR papier.', 'info')
    },
    isEmpty(obj) {
      for (var key in obj) {
        if (typeof obj[key] === "object") {
          return this.isEmpty(obj[key]);
        } else {
          if (obj[key] == "" || obj[key] == null) {
            return true
          }
        }
      }

      return false
    },
    minimumDate(date) {
      if (!this.minimumProgrammedDate) {
        return true
      }

      let miniDate = moment(this.minimumProgrammedDate).toDate()
      if (this.isAdminView) {
        miniDate = new Date();
      }
      miniDate.setHours(0, 0, 0, 0);
      console.log(miniDate)
      return date.getTime() < miniDate.getTime();
    },
    getPriceEstimation () {
      let params = {
        color: this.batchLetter.color,
        stapled: this.batchLetter.stapled
      }

      api.getBatchLetterPriceEstimation(this.batchLetter.id, params)
        .then(response => {
          this.batchLetter.priceTtc = response.data
        }).catch(
          error => {
            console.log(error)
          })
    },
    sentLetter () {
      let billableItemPayers = []

      Object.keys(this.batchLetter.billableItemPayers).forEach((key) => {
        billableItemPayers.push({'payer': this.batchLetter.billableItemPayers[key], 'item_type': key})
      })

      let formData = {
        'programmedDate': moment(this.programmedDate).format('YYYY-MM-DD'),
        'billableItemPayers': billableItemPayers,
        'arManagement': this.batchLetter.arManagement,
        'color': this.batchLetter.color,
        'stapled': this.batchLetter.stapled
      }

      if (this.isEmpty(this.batchLetter.event)) {
        formData['event'] = this.batchLetter.event
      }

      if (this.isEmpty(this.batchLetter.coOwnership)) {
        formData['coOwnership'] = this.batchLetter.coOwnership
      }

      this.sendingLoading = true

      api.putBatchLetterSend(this.batchLetter.id, formData)
        .then(response => {
          this.$emit('close')

          this.$router.push({
            name: 'batchletters_all_sending_list'
          })
        })
        .catch(
          error => {
            this.sendingLoading = false
            if (!authService.redirectToLoginIfNotLogged(error.response)) {
              console.log(error.message)
              this.$swal('Données invalides', 'Merci de vérifier les données renseignées dans le formulaire ci-dessus.', 'error')
            }
          }
        )
    }
  }
}
</script>
