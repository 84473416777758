<template>
  <div>
    <bill-table :user-specific="false"></bill-table>
  </div>
</template>

<script>
import BillTable from "../../components/billing/BillTable";
export default {
  components: {
    BillTable
  }
}
</script>
