<template>

  <transition name="fade">
    <b-card header-bg-variant="info" :header="document.name" header-class="text-center" header-text-variant="white">
      <b-img v-auth-resource="getPreviewLink()" @click="showDocument" class="doc-thumbnail" rounded alt="Chargement..."></b-img>
      <b-card-footer class="text-center">
        {{document.pageCount}} <template v-if="document.pageCount === 1">page</template><template v-else>pages</template>
      </b-card-footer>
    </b-card>
  </transition>
</template>

<script>

import PreviewPopup from '@/components/PreviewPopup';

export default {
  props: {
    batchLetterId: Number,
    coOwner: Object,
    document: Object
  },
  data: () => ({
  }),
  methods: {
    getPreviewLink: function () {
      return '/api/batch_letters/' + this.batchLetterId + '/co_owners/' + this.coOwner.id + '/documents/' + this.document.id + '/preview'
    },
    showDocument () {
      let url = '/api/batch_letters/' + this.batchLetterId + '/co_owners/' + this.coOwner.id + '/documents/' + this.document.id + '/download'
      this.$modal.show(PreviewPopup, {
        name: this.document.name,
        url: url
      }, {
        width: '55%',
        height: 'auto',
        scrollable: true,
        adaptive: true
      })
    }
  }
}
</script>

<style scoped>

  .doc-thumbnail {
    border: 2px solid #ffffffff;
    border-radius: 2px;
    height: 60%;
    min-height: 50px;
    margin-bottom: 10px;
    margin-top: 8px;
  }

  .doc-thumbnail:hover {
    border: 2px solid #f0ad4e;
    border-radius: 4px;
    cursor: pointer;
  }

  .fade-enter-active {
    transition: opacity 1s ease-in-out;
  }

  .fade-enter-to {
    opacity: 1;
  }

  .fade-enter {
    opacity: 0;
  }

</style>
