<template>
  <div>
    <b-form-group
      v-if="!disableForm || event.date"
      label="Date"
      label-for="input-vertical"
    >
      <datepicker @change="valueChanged('ag-date')" :disabled="disableForm" v-model="event.date" value-type="format" format="YYYY-MM-DD" :first-day-of-week="1" lang="fr" name="date"></datepicker>
    </b-form-group>

    <div v-if="!disableForm || event.address">
      <b-form-group label="Adresse de l'assemblée générale" v-if="!disableForm || event.address.address">
        <b-form-textarea
          v-model="event.address.address"
          rows="1"
          id="ag-address"
          name="ag-address"
          v-validate="'addressLimit'"
          :state="validateState('ag-address')"
          @change="valueChanged('ag-address')"
          aria-describedby="input-ag-address-feedback"
          :disabled="disableForm"
        ></b-form-textarea>
        <b-form-invalid-feedback id="input-ag-address-feedback">{{ errors.first('ag-address') }}</b-form-invalid-feedback>
      </b-form-group>

      <b-row>
        <b-col md="3">
          <b-form-group label="Code postal" v-if="!disableForm || event.address.postcode">
            <b-form-input
              v-model="event.address.postcode"
              id="ag-postcode"
              name="ag-postcode"
              v-validate="'digits:5'"
              :state="validateState('ag-postcode')"
              @change="valueChanged('ag-address')"
              aria-describedby="input-ag-postcode-feedback"
              :disabled="disableForm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-ag-postcode-feedback">{{ errors.first('ag-postcode') }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="9">
          <b-form-group label="Ville" v-if="!disableForm || event.address.city">
            <b-form-input
                v-model="event.address.city"
                id="ag-city"
                name="ag-city"
                :state="validateState('ag-city')"
                @change="valueChanged('ag-address')"
                aria-describedby="input-ag-city-feedback"
                :disabled="disableForm"
              ></b-form-input>
              <b-form-invalid-feedback id="input-ag-city-feedback">{{ errors.first('ag-city') }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import datepicker from 'vue2-datepicker'
import addressService from '../service/addressService'

export default {
  components: {
    datepicker
  },
  data () {
    return {
      saveTimeout: null
    }
  },
  props: {
    event: Object,
    disableForm: Boolean
  },
  mounted () {
    if (!this.disableForm) {
      if (this.event.address.address) {
        this.$validator.validate('ag-address')
      }
      if (this.event.address.postcode) {
        this.$validator.validate('ag-postcode')
      }
      // if (this.event.address.city) {
      //   this.$validator.validate('ag-city')
      // }
    }
  },
  methods: {
    validateState (ref) {
      this.$emit('validity', this.isFormValid())
      if (
        this.formFields[ref] &&
        (this.formFields[ref].dirty || this.formFields[ref].validated)
      ) {
        return !this.errors.has(ref)
      }
      return null
    },
    valueChanged (ref) {
      clearTimeout(this.saveTimeout)
        this.saveTimeout = setTimeout(() => {
          if (this.isFormValid() && ref === 'ag-date') {
            this.$emit('update', this.isFormValid())
          }

          if (this.isFormValid() && ref === 'ag-address' && addressService.isComplete(this.event.address)) {
            this.$emit('update', this.isFormValid())
          }
        }, 1000)
    },
    isFormValid () {
      return Object.keys(this.formFields).every(key => this.formFields[key].valid)
    }
  }
}
</script>
