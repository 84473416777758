import axiosAuth  from './axios-auth'

export const postBatchLetter = (params) => axiosAuth.post(`/api/batch_letters`, params)
export const getBatchLetters = (params) => axiosAuth.get(`/api/batch_letters`, {params})
export const getBatchLetter = (letter) => axiosAuth.get(`/api/batch_letters/${letter}`)
export const getBatchLetterCoOwner = (letter, coOwner) => axiosAuth.get(`/api/batch_letters/${letter}/coowners/${coOwner}`)
export const putBatchLetterSend = (id, formData) => axiosAuth.put(`/api/batch_letters/${id}/send`, formData)
export const putBatchLetter = (id, formData) => axiosAuth.put(`/api/batch_letters/${id}`, formData)
export const getBatchLetterReason = (id) => axiosAuth.get(`/api/batch_letter_reasons/${id}`)
export const putBatchLetterCancel = (id, formData) => axiosAuth.put(`/api/batch_letters/${id}/cancel`, formData)
export const deleteBatchLetter = (id) => axiosAuth.delete(`/api/batch_letters/${id}`)
export const getBatchLetterPriceEstimation = (letter, params) => axiosAuth.get(`/api/batch_letters/${letter}/price_estimation`, {params})
export const getMinimumProgrammedDate = (letter, params) => axiosAuth.get(`/api/batch_letters/${letter}/minimim_programmed_date`, {params})
export const postSwitchSendLevelLetter = (id, params) => axiosAuth.post(`/api/batch_letters/${id}`, {params})

export const putCoOwner = (letter, coOwner, formData) => axiosAuth.put(`/api/batch_letters/${letter}/coowners/${coOwner}`, formData)
export const putCoOwnerSendingOptions = (letter, coOwner, formData) => axiosAuth.put(`/api/batch_letters/${letter}/coowners/${coOwner}/sending_options`, formData)
export const getCoOwnerList = (letter, params) => axiosAuth.get(`/api/batch_letters/${letter}/coowners`, {params})
export const postCoOwnerImport = (letter, formData) => axiosAuth.post(`/api/batch_letters/${letter}/coowners`, formData)
export const postCoOwner = (letter, formData) => axiosAuth.post(`/api/batch_letters/${letter}/coowner`, formData)
export const deleteCoOwners = (letter) => axiosAuth.delete(`/api/batch_letters/${letter}/coowners`)
export const postCoOwnerDocument = (letter, coOwner, formData) => axiosAuth.post('/api/batch_letters/' + letter + '/coowners/' + coOwner + '/documents', formData)
export const getCoOwnershipList = (params) => axiosAuth.get('/api/co_ownerships', {params})
export const postLinkCoOwnership = (letter, coOwnership) => axiosAuth.post( `/api/batch_letters/${letter}/link_co_ownership`, {coOwnership})
export const postUnlinkCoOwnership = (letter) => axiosAuth.post( `/api/batch_letters/${letter}/unlink_co_ownership`)

export const getDocumentList = (letter) => axiosAuth.get(`/api/batch_letters/${letter}/documents`)
export const postDocument = (letter, document) => axiosAuth.post(`/api/batch_letters/${letter}/documents`, document)
export const deleteDocument = (letter, document) => axiosAuth.delete(`/api/batch_letters/${letter}/documents/${document}`)

export const reorderDocuments = (letter, formData) => axiosAuth.put('/api/batch_letters/' + letter + '/reorder_documents', formData)

export const registration = (formData) => axiosAuth.post(`/api/register`, formData)

export default {
  postBatchLetter,
  getBatchLetters,
  getBatchLetter,
  getBatchLetterCoOwner,
  putBatchLetterSend,
  getBatchLetterReason,
  deleteBatchLetter,
  postSwitchSendLevelLetter,
  getCoOwnerList,
  postCoOwnerImport,
  deleteCoOwners,
  getDocumentList,
  postDocument,
  deleteDocument,
  reorderDocuments,
  registration,
  putCoOwner,
  putCoOwnerSendingOptions,
  postCoOwnerDocument,
  putBatchLetter,
  putBatchLetterCancel,
  postCoOwner,
  getBatchLetterPriceEstimation,
  getMinimumProgrammedDate,
  getCoOwnershipList,
  postLinkCoOwnership,
  postUnlinkCoOwnership
}
