import api from './paperletter'

export default class DocumentAdapter {
  constructor (letter) {
    this.letter = letter
  }

  prepareDocuments () {
    return this.letter.documents.map(document => document.id)
  }

  postLetterDocument (file, position = null, onUploadProgress = () => {}) {
    let data = new FormData()
    data.append('padmaxpaperletter_inputdocument[file]', file)
    if (position !== null) {
      data.append('padmaxpaperletter_inputdocument[position]', position)
    }
    return api.postLetterDocument(this.letter.id, data, {onUploadProgress})
  }
}
