<template>
    <div class="modal-contact">
        <div class="modal-content">
            <div class="modal-header">
                <div class="text-center">Récapitulatif de votre lettre</div>
                <span class="close-button" @click="closePopup()">
                    <i class="fa fa-times"></i>
                </span>
            </div>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="preview col-sm-12 col-md-7">
                  <preview :letter="letter"></preview>
                </div>
                <div class="col-sm-12 col-md-5">
                    <div class="no-gutter" v-if="showSender || letter.isAddressPageNeeded">
                        <div>
                            <div class="card">
                                <div class="card-header bg-info text-white h5">
                                    <i class="fa fa-user"></i> <span>&nbsp;Destinataire </span>
                                </div>
                                <div class="card-body card-body-very-condensed">
                                    <contact :contact="letter.recipient"></contact>
                                </div>
                            </div>
                        </div>
                        <div v-show="showSender">
                            <div class="card" >
                                <div class="card-header bg-info text-white h5">
                                    <i class="fa fa-user"></i> <span>Expéditeur </span>
                                </div>
                                <div class="card-body card-body-very-condensed">
                                    <contact :contact="letter.sender"></contact>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card" v-else>
                        <div class="card-header h5" :class="[boxColor]">
                            <i class="fas fa-address-card"></i><span> Vérification de l'adresse du destinataire </span>
                        </div>
                        <div class="card-body card-body-very-condensed">
                          <contact-region :highlightCheckbox.sync="highlightCheckbox" :sendConfirmed.sync="sendConfirmed"></contact-region>
                        </div>
                    </div>
                    <div class="card">
                        <div v-if="needsScheduling">
                            <div class="card-header h5">
                                <i class="fa fa-calendar"></i>
                                <span>Date de programmation de la lettre :
                                <b v-if="programmedDate && !priceLoading">{{ humanReadableDate(postingDate) }}</b>
                                </span>
                            </div>
                            <div class="card-body card-body-very-condensed">
                                <p>Sélectionnez la date à laquelle vous souhaitez envoyer votre lettre : </p>
                                <datepicker v-model="programmedDate" lang="fr" :editable="false" :first-day-of-week="1" :disabled-date="notBeforeTomorrow"></datepicker>
                                <span v-if="priceLoading || postingDateLoading"><span class="fas fa-circle-notch fa-spin"></span>&nbsp;Vérification de la date</span>
                                <div class="text-red" v-else-if="programmedDate && !isPostingDateIdentic()"> <i class="fa fa-info-circle text-red"></i> La lettre sera postée le <b class="text-red">{{ humanReadableDate(postingDate) }}</b>. Cette date correspond au prochain jour ouvré à partir de la date que vous avez choisie.</div>
                            </div>
                        </div>

                        <div v-else>
                            <div class="card-header h5" :class="[dateBoxColor]">
                                <i class="fa fa-calendar"></i> <span>Date de l'envoi : <b>{{ humanReadableDate(postingDate) }}</b></span>
                            </div>
                            <div class="card-body card-body-very-condensed">
                                <span v-if="isPostingDateIdentic()"> <i class="fa fa-paper-plane"></i> La lettre sera postée aujourd'hui si envoyée avant 10h30.</span>
                                <span v-else-if="postingDateLoading"> <i class="fas fa-circle-notch fa-spin"></i> Calcul de la date à laquelle la lettre sera postée...</span>
                                <span v-else>
                                    <div><i class="fas fa-exclamation-triangle text-danger"></i> La lettre sera programmée pour un envoi le <b class="text-red">{{ humanReadableDate(postingDate) }}</b> (prochain jour ouvré à partir d'aujourd'hui), vous laissant ainsi la possibilité de la reprogrammer ou de l'annuler avant son envoi.</div>
                                    <br/>
                                    <div><i class="fa fa-info-circle"></i> <i>Pour rappel, seules les lettres envoyées avant 10h30 pendant des jours ouvrés sont postées le jour même.</i></div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- modal-body ending -->

        <div class="modal-footer">
          <b-row class="pull-right">
            <b-col sm class="p-md-0 mb-2 mb-md-0">
              <button type="button" class="btn btn-danger letter-btn" @click="closePopup()">
                <i class="fa fa-arrow-left"></i>&nbsp;Corriger
              </button>
            </b-col>
            <b-col sm md="auto">
              <span v-tooltip="{content: getSendContent(), placement: 'top'}">
                <button type="button" class="btn btn-primary letter-btn" :disabled=!canSendLetter() @click="send()">
                    <i v-if="letterSending" class="fas fa-circle-notch fa-spin"></i>
                    <i v-else-if="letterSendingError" class="fa fa-exclamation-triangle"></i>
                    <i v-else class="fa" :class="needsScheduling ? 'fa-calendar' : 'fa-paper-plane'"></i>
                    <span v-if="needsScheduling">Programmer l'envoi</span>
                    <span v-else-if="isPostingDateIdentic()">Envoyer maintenant</span>
                    <span v-else>Envoyer le {{ humanReadableDate(postingDate) }}</span>

                    <strong v-if="this.letterProgrammedPreview">{{getDisplayProgrammedDatePriceDifference()}}</strong>
                    <strong v-else>({{ this.realPrice | formatFloat }} €) </strong>
                </button>
                </span>
            </b-col>
          </b-row>
        </div> <!-- footer ending -->
    </div> <!-- modal-contact ending -->
</template>

<script>
import DatePicker from 'vue2-datepicker'
import {mapActions, mapState, mapGetters} from 'vuex'
import api from '../../../../api/paperletter'
import {LetterStep} from '../../../../constants/Letter'
import './images/stamp.png'
import moment from 'moment'
import authService from '../../../../service/auth'
import Preview from "./Preview"
import Contact from "./Contact"
import ContactRegion from "./ContactRegion";
import creditService from '../../../../service/creditService'

export default {
  components: {
    ContactRegion,
    Preview,
    Contact,
    'datepicker': DatePicker,
  },
  data () {
    return {
      programmedDate: null,
      priceConfirmed: false,
      updatedPrice: null,
      priceLoading: false,
      sendConfirmed: false,
      highlightCheckbox: false,
      postingDateLoading: true,
      postingDate: null,
      programmedDatePriceDiff: null
    }
  },
  props: {
    closePopup: {
      type: Function
    },
    closePopupAfterSending: {
      type: Function
    },
    needsScheduling: Boolean,
    reScheduling: Boolean
  },
  created () {
    if (this.reScheduling) {
      this.programmedDate = this.letter.programmedDate
    }

    api.getLetterPostingDate(this.letter.id, this.formatProgrammedDate()).then(
      response => {
        this.postingDateLoading = false
        this.postingDate = response.data.postingDate
      },
      error => {
        if (!authService.redirectToLoginIfNotLogged(error.response)) {
          this.postingDateLoading = false
        }
      }
    )
  },
  computed: {
    ...mapState('letter', {
      letter: state => state,
      letterStep: state => state.step,
      letterSending (state) {
        return state.step === LetterStep.SENDING
      },
      letterSendingError (state) {
        return state.step === LetterStep.SENDING_ERROR
      },
      showSender (state) {
        return state.sendMode && state.sendMode.senderAvailable
      },
      letterSentToday () {
        let now = moment()
        let dateToCheck = now.hour(10).minute(30)
        let weekend = now.day() === 0 || now.day() === 6

        return moment().isBefore(dateToCheck) && !weekend
      }
    }),
    creditEnough() {
       return creditService.hasEnoughCreditToBuy(this.realPrice, this.letter.user)
    },
    realPrice () {
      if (this.updatedPrice !== null) {
        return this.updatedPrice
      } else {
        return this.letter.price
      }
    },
    ...mapGetters('letter', [
      'isAddressPageNeeded',
      'registerRecipientDetected',
      'recipientDetected',
      'letterProgrammedPreview'
    ]),
    boxColor () {
      if (this.sendConfirmed || this.letterProgrammedPreview) {
        return 'bg-info text-white'
      } else if (this.recipientDetected) {
        return 'bg-warning'
      } else {
        return 'bg-danger'
      }
    },
    dateBoxColor () {
      if ((this.needsScheduling && this.programmedDate == null) || !this.priceConfirmed) {
        return 'bg-warning'
      } else if (!this.needsScheduling) {
        return 'bg-default'
      }

      if ((this.needsScheduling && this.programmedDate != null) || (!this.needsScheduling && this.letterSentToday)) {
        return 'box-info'
      }

      return null
    }
  },
  watch: {
    programmedDate () {
      if (this.programmedDate != null) {
        this.priceConfirmed = false
        this.priceLoading = true
        this.postingDateLoading = true
        api.getLetterPriceWithDate(this.letter.id, this.formatProgrammedDate()).then(
          response => {
            this.priceLoading = false
            this.programmedDatePriceDiff = response.data.differences.programmedDate

            if (this.letter.price === response.data.price) {
              this.priceConfirmed = true
              this.updatedPrice = response.data.price
            } else {
              this.updatedPrice = response.data.price
              this.priceConfirmed = true
              this.$swal('Tarif différent', "En raison d'une augmentation des tarifs d'affranchissement, le tarif est différent pour cette date", 'warning')
            }
          },
          error => {
            if (!authService.redirectToLoginIfNotLogged(error.response)) {
              this.priceLoading = false
              this.$swal('Aucun tarif', 'Il est encore trop tôt pour programmer à cette date', 'error')
              this.priceConfirmed = false
            }
          }
        )

        api.getLetterPostingDate(this.letter.id, this.formatProgrammedDate()).then(
          response => {
            this.postingDateLoading = false
            this.postingDate = response.data.postingDate
          },
          error => {
            this.postingDateLoading = false
          }
        )
      }

      // priceConfirmed is set to false, if user clears the selected programmedDate
      if (this.programmedDate === null) {
        this.priceConfirmed = false
        this.updatedPrice = null
      }
    },
    sendConfirmed () {
      if (this.sendConfirmed) {
        this.highlightCheckbox = false
      } else {
        this.highlightCheckbox = true
      }
    }
  },
  methods: {
    ...mapActions('letter', [
      'sendLetter'
    ]),
    notBeforeTomorrow(date) {
      const tomorrow = moment()
      return tomorrow.isAfter(date)
    },
    send () {
      if (!this.priceConfirmed && !this.canSendLetter()) {
        if (!this.sendConfirmed) {
          this.highlightCheckbox = true
        }
        return
      }

      let date = null
      if (this.needsScheduling) {
        date = (this.programmedDate !== null && this.programmedDate !== undefined) ? this.formatProgrammedDate() : null
      }

      if (this.canSendLetter()) {
        this.$router.push({name: 'shelled_letters_payment_success', params: {id: this.letter.id, programmedDate: date}})
      }
    },
    canSendLetter () {
      return ((this.programmedDate && this.priceConfirmed) || !this.needsScheduling) &&
        (this.registerRecipientDetected || (!this.registerRecipientDetected && this.sendConfirmed) || this.isAddressPageNeeded || this.letterProgrammedPreview)
    },
    formatProgrammedDate () {
      if (this.programmedDate == null) {
        return moment().format('YYYY-MM-DD')
      }
      return moment(this.programmedDate).format('YYYY-MM-DD')
    },
    humanReadableDate (date) {
      if (date === null) {
        return ''
      }
      return moment(date).format('DD/MM/YYYY')
    },
    getSendContent () {
      let msg

      if (!this.canSendLetter() && this.letterProgrammedPreview) {
        msg = 'Veuillez choisir la nouvelle date de programmation avant d’envoyer le courrier.'
      } else if (!this.canSendLetter() && this.needsScheduling && !this.isAddressPageNeeded) {
        msg = 'Veuillez vérifier et confirmer ci-dessus l’adresse du destinataire et choisir la date de programmation avant d’envoyer le courrier.'
      } else if (!this.canSendLetter() && this.needsScheduling && this.isAddressPageNeeded) {
        msg = 'Veuillez choisir la date de programmation avant d’envoyer le courrier.'
      } else if (!this.canSendLetter()) {
        msg = 'Veuillez vérifier et confirmer ci-dessus l’adresse du destinataire avant d’envoyer le courrier.'
      } else {
        msg = ''
      }

      return msg
    },
    isPostingDateIdentic () {
      if (this.postingDate === null) {
        return false
      }

      let date = moment(this.postingDate)

      let dateToCheck = null
      if (this.needsScheduling) {
        dateToCheck = moment(this.programmedDate)
      } else {
        dateToCheck = moment()
      }

      return date.isSame(dateToCheck, 'day')
    },
    getDisplayProgrammedDatePriceDifference () {
      if (this.programmedDatePriceDiff === null) {
        return null
      } else if (this.programmedDatePriceDiff > 0) {
        return '( +' + this.$options.filters.formatFloat(this.programmedDatePriceDiff)+ '€ ) '
      } else {
        return '( ' + this.$options.filters.formatFloat(this.programmedDatePriceDiff) + '€ ) '
      }
    }
  }
}
</script>

<style scoped>
    .modal-contact {
        margin: 0;
    }

    .modal-header {
        background-color: #3c8dbc;
        color: #fff;
        padding: 10px;
        font-size: 22px;
    }

    .close-button {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 0;
        font-size: 1.5em;
    }

    .preview, .preview > object {
        height: 460px;
    }

    @media (max-width: 576px) {
      .modal-header {
        font-size: 1.2rem;
      }

      .preview, .preview > object {
        height: 360px !important;
      }
    }
</style>
