
export default class ContactAdapter {
  constructor (contact) {
    this.contact = contact
  }

  prepareContact () {
    return {
      fullname: this.contact.fullname,
      address: this.contact.address,
      postcode: this.contact.postcode,
      city: this.contact.city
    }
  }

  prepareSender () {
    return this.prepareContact()
  }

  prepareRecipient () {
    let base = this.prepareContact()
    base.customerReference = this.contact.customerReference
    return base
  }
}
