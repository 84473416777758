<template>
    <b-navbar toggleable="lg" type="dark" variant="info">
      <div class="container">
        <b-navbar-brand :to="isAuthenticated ? '/default' : '/home'">
          <img :src="require('@/assets/images/onesyndic_sans_texte.png')" height="35px" alt="Avatar" v-if="isAuthenticated">
          <img :src="require('@/assets/images/onesyndic_gauche_text_white_right.png')" height="35px" fluid v-else>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav v-if="isAuthenticated">
              <b-nav-item-dropdown>
                <template slot="button-content"><font-awesome-icon icon="home"></font-awesome-icon>&nbsp;Envois syndic</template>
                <b-dropdown-item to="/batchletters/create/1"><font-awesome-icon icon="plus"></font-awesome-icon>&nbsp;Nouvelle Assemblée Générale</b-dropdown-item>
                <b-dropdown-item to="/batchletters/create/2"><font-awesome-icon icon="plus"></font-awesome-icon>&nbsp;Nouveau Procès verbal</b-dropdown-item>
                <b-dropdown-item to="/batchletters/create/3"><font-awesome-icon icon="plus"></font-awesome-icon>&nbsp;Nouvel Appel de fonds</b-dropdown-item>
                <b-dropdown-item to="/batchletters/create/4"><font-awesome-icon icon="plus"></font-awesome-icon>&nbsp;Autre type d'envoi</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item to="/batchletters"><font-awesome-icon icon="list"></font-awesome-icon>&nbsp;Liste des envois</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item-dropdown v-if="isShelledLetterAllowed">
                <template slot="button-content"><font-awesome-icon icon="paper-plane"></font-awesome-icon>&nbsp;Autres envois</template>
                <b-dropdown-item to="/shelled_letters_new"><font-awesome-icon icon="plus"></font-awesome-icon>&nbsp;Nouvel envoi papier</b-dropdown-item>
                <b-dropdown-item to="/shelled_letters"><font-awesome-icon icon="list"></font-awesome-icon>&nbsp;Liste des envois papier</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item-dropdown v-if="isAdminView">
                <template slot="button-content"><font-awesome-icon icon="user-cog"></font-awesome-icon>&nbsp;Admin</template>
                <b-dropdown-item to="/user/list"><font-awesome-icon icon="users"></font-awesome-icon>&nbsp;Utilisateurs</b-dropdown-item>
                <b-dropdown-item to="/user/billing/list"><font-awesome-icon icon="euro-sign"></font-awesome-icon>&nbsp;Facturation</b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto" v-if="isAuthenticated">
                <b-nav-item  right to="/user/needs_help"><i class="fas fa-question-circle"></i>&nbsp;Besoin d'aide ?</b-nav-item>
                <b-nav-item-dropdown right>
                    <!-- Using 'button-content' slot -->
                    <template slot="button-content"><i class="fas fa-user"></i>&nbsp;{{ getName }}</template>
                    <b-dropdown-item to="/user/profile"><font-awesome-icon icon="user"></font-awesome-icon>&nbsp;Gestion du compte</b-dropdown-item>
                    <b-dropdown-item to="/user/change_password"><font-awesome-icon icon="lock"></font-awesome-icon>&nbsp;Changer mon mot de passe</b-dropdown-item>
                    <b-dropdown-item to="/user/billing" v-if="!isAdminView"><font-awesome-icon icon="euro-sign"></font-awesome-icon>&nbsp;Facturation</b-dropdown-item>
                    <b-dropdown-item @click="logout()" href="#"><font-awesome-icon icon="sign-out-alt"></font-awesome-icon>&nbsp;Déconnexion</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>

          <b-navbar-nav class="ml-auto" v-else>
            <b-nav-item right>
              <router-link :to="{name: 'register'}" ><b-button variant="light"><font-awesome-icon icon="user-plus" ></font-awesome-icon>&nbsp;Inscription</b-button></router-link>
            </b-nav-item>
            <b-nav-item right>
              <router-link :to="{name: 'login'}" ><b-button variant="warning"><font-awesome-icon icon="key" ></font-awesome-icon>&nbsp;Connexion</b-button></router-link>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
</template>
<script>

import '@/api/user'
import {mapGetters} from 'vuex'
export default {
  computed: {
    ...mapGetters('user', [
      'isShelledLetterAllowed',
      'isAdminView',
      'isAuthenticated',
      'isAuthenticating',
      'getName',
    ])
  },
  methods: {
    logout () {
      this.$store.dispatch('user/logout').then(
        () => {
          this.$router.push({
            name: 'home'
          })
        }
      )
    }
  }
}
</script>
