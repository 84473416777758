<template>
  <div>
    <h1 class="text-center">Besoin d'aide ?</h1>
    <hr/>

    <b-card
      border-variant="info"
      header="Primary"
      header-bg-variant="info"
      header-text-variant="white"
      header-class="text-center card-body-condensed"
      header-tag="header"
    >
      <template v-slot:header>
        <h3>Saisissez ici votre demande, nous y répondrons dans les plus brefs délais</h3>
      </template>

      <b-form-textarea rows="4" size="sm" v-model="helpMessage"></b-form-textarea>
      <br/>
      <div class="text-center">
      <b-button variant="warning" size="lg" :disabled="!helpMessage" @click="sendMessage">
        <i class="fas fa-paper-plane"></i>  Envoyer le message
      </b-button>
      </div>

    </b-card>
  </div>
</template>
<script>

import UserAPI from '@/api/user'

export default {
  data () {
    return {
      helpMessage: null
    }
  },
  methods: {
    sendMessage () {
      UserAPI.help(this.helpMessage).then(
        response => {
          this.$swal('Merci pour votre message. Nous vous recontacterons dans les plus brefs délais.', '', 'success')
          this.$router.push({name: 'home'})
        },
        error => {
          this.$swal('Une erreur est survenue lors de l\'envoi du message.', '', 'error')
          this.$router.push({name: 'home'})
        }
      )
    }
  },
  props: {
  }
}
</script>
