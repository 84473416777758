<template>
  <div>
    <b-row class="my-1">
      <b-col sm="6">
        <label> Impression / mise sous pli / agrafage : </label>
      </b-col>
      <b-col sm="6">
        <b-form-radio-group
          id="radio-group-1"
          v-model="billableItemPayer[BillItemType.BILL_ITEM_TYPE_PAPER_LETTER_SERVICE]"
          :options="options"
        ></b-form-radio-group>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col sm="6">
        <label> Affranchissement : </label>
      </b-col>
      <b-col sm="6">
        <b-form-radio-group
          id="radio-group-2"
          v-model="billableItemPayer[BillItemType.BILL_ITEM_TYPE_PAPER_LETTER_STAMP]"
          :options="options"
        ></b-form-radio-group>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col sm="6">
        <label> Courrier électronique : </label>
      </b-col>
      <b-col sm="6">
        <b-form-radio-group
          id="radio-group-4"
          v-model="billableItemPayer[BillItemType.BILL_ITEM_TYPE_LRE]"
          :options="options"
        ></b-form-radio-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BillItemType, BillItemTypePayer} from '../constants/BillItemTypePayer'

export default{
  props: {
    billableItemPayer: Object
  },
  data () {
    return {
      BillItemType: BillItemType,
      options: [
        { text: 'Syndic', value: BillItemTypePayer.PAYER_SYNDIC },
        { text: 'Copropriété', value: BillItemTypePayer.PAYER_COPRO }
      ]
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
