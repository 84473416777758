import store from '../../store'

class LoadingService {
  constructor () {
    this.promises = []
  }

  register (promise) {
    this.promises.push(promise)
    this.stateUpdate()

    promise.then(
      () => {
        this.remove(promise)
        this.stateUpdate()
      }
    )
  }

  remove (promise) {
    let index = this.promises.indexOf(promise)
    if (index !== -1) {
      this.promises.splice(index, 1)
      return true
    }
    return false
  }

  stateUpdate () {
    if (this.isLoading() !== this.isStateLoading()) {
      // store.dispatch('setPageLoading', this.isLoading())
    }
  }

  isLoading () {
    return this.promises.length > 0
  }

  isStateLoading () {
    return store.state.loading
  }
}

export default new LoadingService()
