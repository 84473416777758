<template>
  <div class="vld-parent">
    <loading :isLoading="isLoading"></loading>
    <v-server-table ref="usersTable" name="usersTable" :columns="columns" :options="options" @loaded="onTableLoaded" @loading="onTableLoading">
      <template  slot="id" slot-scope="props">
        <router-link :to="{ name: 'profile', params: {id: props.row.id}}">{{ props.row.id }}</router-link>
      </template>

      <span slot="fullName" slot-scope="props">
          {{props.row.firstname}} {{props.row.lastname}}
           <br>
            {{props.row.email}}
      </span>
    </v-server-table>
  </div>
</template>

<script>
import user from '../../api/user'
import moment from 'moment'
import authService from '@/service/auth'
import Loading from "../Loading";
import {PaymentStatus} from '../../service/payment'

export default {
  components: {
    Loading
  },
  data () {
    return {
      isLoading: false,
      columns:  ['id', 'fullName', 'phone', 'registrationDate', 'enabled'],
      options: {
        requestFunction: (data) => {
          return user.getUsers({...data})
            .catch(function (e) {
              if (!authService.redirectToLoginIfNotLogged(e.response)) {
                this.dispatch('error', e)
              }
            }.bind(this))
        },
        requestAdapter (data) {
          return {
            limit: data.limit,
            page: data.page,
            sort: data.orderBy ? data.orderBy : 'id',
            orderBy: data.ascending ? 'asc' : 'desc',
            filters: {
              id: data.id,
              name: data.fullName,
              phone: data.phone,
              enabled: data.enabled
            }
          }
        },
        responseAdapter: function (resp) {
          const data = []
          for (let row of resp.data.data) {
            data.push({
              'id': row['id'],
              'email': row['email'],
              'firstname':  row['firstname'],
              'lastname':  row['lastname'],
              'phone':  row['phone'],
              'postCode':  row['postCode'],
              'city':  row['city'],
              'registrationDate': (row['registrationDate']) ? moment(row['registrationDate']).format('YYYY-MM-DD') : '-',
              'enabled':  row['status']
            })
          }

          return {
            data: data,
            count: resp.data.count
          }
        },
        listColumns: this.listColumns(),
        headings: {
          id: 'N°',
          fullName: 'Utilisateur',
          email: 'Email',
          phone: 'Téléphone',
          registrationDate: 'Date d\'inscription',
          enabled: 'Statut'
        },
        orderBy: { ascending: false },
        filterByColumn: true,
        filterable: ['id', 'fullName', 'phone', 'enabled'],
        customFilters: ['id', 'fullName', 'phone', 'enabled'],
        sortable: ['id', 'registrationDate', 'enabled'],
        saveState: true,
        storage: 'session'
      }
    }
  },
  methods: {
    listColumns () {
      return {
        enabled: [
          { id: 1, text:  'Activé' },
          { id: 0, text:  'Désactivé' }
        ]
      }
    },
    onTableLoaded () {
      this.isLoading = false
    },
    onTableLoading(){
      this.isLoading = true
    }
  }
}
</script>
