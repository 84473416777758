<template>
<div>
  <!-- Letter sending part -->
  <div>
    <div class="big-icon text-center">
      <b-spinner v-if="letterSending || loading" variant="primary" label="Spinning"></b-spinner>
      <font-awesome-icon v-else-if="letterSent" style="color: green" icon="paper-plane"></font-awesome-icon>
      <font-awesome-icon v-else style="color: orange" icon="exclamation-triangle"></font-awesome-icon>
    </div>

    <div class="main-subtitle">
      <div v-if="letterSending || loading">Préparation de la lettre en cours...</div>
      <div v-else-if="letterSent">
        <template v-if="programmedDate">Envoi prévu pour le {{ programmedDate }}</template>
        <template v-else>Lettre envoyée avec succès !</template>
      </div>
      <div v-else-if="alreadyPaid">Lettre déjà payée</div>
      <div v-else-if="!isCreditEnough">Crédit insuffisant pour l'envoi</div>
      <div v-else>Impossible d'envoyer votre lettre</div>
    </div>

    <div v-if="!letterSending && !loading" class="main-description">
      <div v-if="letterSent">
        <template v-if="programmedDate">Votre lettre sera postée le {{ programmedDate }}.</template>
        <template v-else>Votre lettre sera postée aujourd'hui.</template>
        <br/>
        Vous aurez ensuite accès à son suivi et pourrez suivre toutes les étapes de son acheminement.
      </div>
      <div v-else-if="alreadyPaid">Vous avez déjà réglé la somme de {{ letterPrice | formatFloat }} € TTC pour cette lettre.</div>
      <div v-else-if="!isCreditEnough">Votre crédit de {{ getUserCreditValue() | formatFloat }} € n'est pas suffisant pour régler cette lettre ({{ letterPrice | formatFloat }} € TTC).</div>
      <div v-else>
        Nous vous conseillons de vérifier le montant de votre crédit puis de réessayer.
      </div>
    </div>

    <div v-if="!letterSending && !loading" class="text-center">
      <router-link class="left-button" :to="{name: 'shelled_letters_edit', params: {id: letterId}}" >
        <b-button size="lg" variant="warning"><font-awesome-icon icon="paper-plane" ></font-awesome-icon>&nbsp;Aller à la lettre</b-button>
      </router-link>
      <router-link v-if="!letterSent && !isCreditEnough && !alreadyPaid" :to="{name: 'buy_credit'}" >
        <b-button size="lg" variant="warning"><font-awesome-icon icon="redo" ></font-awesome-icon>&nbsp;Acheter des crédits</b-button>
      </router-link>
      <router-link v-else class="right-button" :to="{name: 'shelled_letters_new'}" >
        <b-button size="lg" variant="warning"><font-awesome-icon icon="plus" ></font-awesome-icon>&nbsp;Nouvelle lettre</b-button>
      </router-link>
    </div>
  </div>
</div>
</template>

<script>
import UserApi from "../../api/user";
import LetterApi from "../../api/paperletter";
import BillApi from "../../api/bills";
import {LetterState} from "../../constants/Letter";
import authService from "../../service/auth";
import moment from "moment";
import LetterAdapter from "../../api/LetterAdapter";
import PreviewPopup from "../../components/PreviewPopup";
import creditService from '../../service/creditService'

export default {
  data () {
    return {
      letterId: null,
      letter: null,
      loading: true,
      letterSending: false,
      letterSent: false,
      letterPrice: null,
      programmedDate: null,
      user: null
    }
  },
  created () {
    this.letterId = this.$route.params.id
    this.programmedDate = this.$route.params.programmedDate

    this.initLetterAndPrice().then(
      () => {
        this.priceLoading = false
        return this.checkPayment()
      }
    ).then(
      () => {
        this.loading = false
        if (
          !this.alreadyPaid && this.isCreditEnough
        ) {
          this.payLetter()
        }
      }
    )
  },
  computed: {
    isCreditEnough () {
      return creditService.hasEnoughCreditToBuy(this.letterPrice, this.user)
    },
    alreadyPaid () {
      return this.letter.status !== undefined && this.letter.status !== LetterState.CREATION && !this.letterSent
    },
    getUserCreditValue() {
      return creditService.getUserCreditValue(this.user)
    }
  },
  methods: {
    // Return a promise after retrieving letter state and price
    initLetterAndPrice () {
      return new Promise((callback) => {
        LetterApi.getLetter(this.letterId).then(
          response => {
            this.letter = response.data

            if (response.data.user) {
              this.user = response.data.user
            }

            if (!this.alreadyPaid) {
              return LetterApi.getLetterPriceWithDate(this.letterId, this.formatProgrammedDate()).then(
                response => {
                  this.letterPrice = response.data.price
                  this.amount = this.missingValue
                  callback()
                },
                error => {
                  if (!authService.redirectToLoginIfNotLogged(error.response)) {
                    callback()
                  }
                })
            } else {
              this.letterPrice = response.data.totalPrice
              callback()
            }
          }
        ).catch(
          () => {
            this.$swal('La lettre est introuvable', 'Lettre introuvable', 'error')
            this.$router.push({name: 'shelled_letters_list'})
          }
        )
      })
    },
    // Return a promise after validating the payment, if available
    checkPayment () {
      return new Promise((callback) => {
        callback()
      })
    },
    payLetter() {
      this.letterSending = true
      let letterAdapter = new LetterAdapter({ id: this.letterId, programmedDate: this.programmedDate })
      letterAdapter.postSend().then(
        (response) => {
          this.$store.dispatch('user/checkLogin')
          this.letterSending = false
          this.letterSent = true

          // We update the letter to refresh the programmed date and other fields
          this.letter = response.data
        }
      ).catch(
        () => this.letterSending = false
      )
    },
    formatProgrammedDate () {
      if (this.programmedDate === null) {
        return moment().format('YYYY-MM-DD')
      }
      return moment(this.programmedDate).format('YYYY-MM-DD')
    }
  }
}
</script>

<style scoped>
  svg.big-icon {
    width: 7rem;
    height: 7rem;
  }
  .big-icon > * {
    width: 7rem;
    height: 7rem;
  }

  .left-button {
    margin-right: 0.25rem;
  }

  .right-button {
    margin-left: 0.25rem;
  }

  .main-subtitle {
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 2rem;
    text-align: center;
  }

  .main-description {
    margin-bottom: 1rem;
    text-align: center;
  }
</style>
