<template>
  <div>
    <b-card
      border-variant="info"
      header="Primary"
      header-bg-variant="info"
      header-text-variant="white"
      header-class="text-left"
      header-tag="header"
    >
      <template v-slot:header>
        <div class="card-header-big">
          Documents de l'envoi
          <button @click="showDocument" class="btn btn-warning float-right" v-if="coOwner !== null && coOwner.documents.length !== 0">
            <i class="fas fa-eye"></i> Voir le courrier envoyé
          </button>
        </div>
      </template>

        <div  v-if="coOwner !== null">
          <b-row>
          <b-col md="3" v-for="doc in coOwner.documents" :key="doc.id" >
            <co-owner-document :document="doc" :co-owner="coOwner" :batch-letter-id="batchLetterId">
            </co-owner-document>
          </b-col>
          </b-row>
          <div v-if="coOwner.documents.length === 0">
            Pas de documents.
          </div>
        </div>

    </b-card>
  </div>
</template>

<script>
import CoOwnerDocument from '@/components/coowner/CoOwnerDocument'
import PreviewPopup from '@/components/PreviewPopup'

export default {
  props: {
    coOwner: Object,
    batchLetterId: Number
  },
  components: {
    CoOwnerDocument
  },
  computed: {
  },
  data () {
    return {
    }
  },
  methods: {
    showDocument () {
      let url = '/api/batch_letters/' + this.batchLetterId + '/coowners/' + this.coOwner.id + '/preview'
      this.$modal.show(PreviewPopup, {
        name: `Courrier_complet_${this.coOwner.reference}.pdf`,
        url: url
      }, {
        width: '55%',
        height: 'auto',
        scrollable: true,
        adaptive: true
      })
    }
  }
}
</script>

<style scoped>
  .file-list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .file-list-enter-active, .file-list-leave-active {
    transition: all 0.5s;
  }
  .file-list-enter, .file-list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
  }
</style>
