<template>
  <div class="modal-preview">
    <div class="modal-header">
      <div class="text-center w-100">Mode d'envoi pour {{coOwner.lastName}} {{coOwner.firstName}}</div>
      <span class="close-button" @click="$emit('close')">
            <i class="fa fa-times"></i>
        </span>
    </div>
    <div class="modal-body">
      <b-form>
        <co-owner-sending-options-form :coOwner="coOwner"></co-owner-sending-options-form>
      </b-form>
    </div>
    <div class="modal-footer">
      <b-button variant="danger" @click="$emit('close')"><i class="fa fa-times"/>&nbsp;&nbsp;Annuler</b-button>
      <b-button variant="primary" @click="updateCoOwnerSendMode"><i class="fas fa-pencil-alt"/>&nbsp;&nbsp;Modifier</b-button>
    </div>
  </div>
</template>

<script>
import api, {putCoOwnerSendingOptions} from '../api'
import CoOwnerSendingOptionsForm from '@/components/form/CoOwnerSendingOptionsForm'
import authService from '@/service/auth'

export default{
  props: {
    batchLetterId: Number,
    coOwner: Object
  },
  components: {
    CoOwnerSendingOptionsForm
  },
  methods: {
    updateCoOwnerSendMode () {
      let formData = {
        'sendType': this.coOwner.sendType,
        'sendLevel': this.coOwner.sendLevel
      }
      api.putCoOwnerSendingOptions(this.batchLetterId, this.coOwner.id, formData).then(response => {
        this.$emit('close')
      }).catch(
        error => {
          if (!authService.redirectToLoginIfNotLogged(error.response)) {
            let msg = ''
            if (error.response.data.errors.errors) {
              msg = error.response.data.errors.errors[0]
            } else {
              msg = 'Erreur lors de l\'exécution de votre demande'
            }

            this.$swal('Erreur lors de l\'exécution de votre demande', msg, 'error')
          }
        }
      )
    }
  }
}
</script>
