<template>
  <div>
    <div class="card-header-big">Les avis ou accusés</div>
    <b-table :fields="fields" :items="this.advices" responsive="sm" small striped>
      <template v-slot:cell(date) = "{item}">
       {{ item.date | formatDate }}
      </template>

      <template v-slot:cell(status) = "{item}">
        {{ deliveryService.getTextForAdviceStatus(item.status) }}
      </template>

      <template v-slot:cell(view) = "{item}">
        <span @click="downloadAdviceFile(item.status)"><i class="fas fa-file-alt"></i></span>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from 'moment'
import deliveryService from '@/service/delivery'
import PreviewPopup from '@/components/PreviewPopup'

export default {
  props: {
    advices: Array,
    batchLetterId: Number,
    coOwnerId: Number,
    deliveryId: Number
  },
  data () {
    return {
      deliveryService: deliveryService,
      fields: [
        {key: 'date', label: 'Date'},
        {key: 'status', label: 'Statut'},
        {key: 'view', label: 'Télécharger'}
      ]
    }
  },
  filters: {
    formatDate: function (date) {
      return moment(date).format('DD/MM/YYYY')
    }
  },
  methods: {
    downloadAdviceFile (adviceStatus) {
      let url = '/api/batch_letters/' + this.batchLetterId + '/co_owners/' + this.coOwnerId + '/delivery/' + this.deliveryId + '/advice/' + adviceStatus + '/download'
      this.$modal.show(PreviewPopup, {
        name: this.deliveryService.getTextForAdviceFilename(adviceStatus),
        url: url
      }, {
        width: '55%',
        height: 'auto',
        scrollable: true,
        adaptive: true
      })
    }
  }
}
</script>
