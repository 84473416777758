<template>
  <div class="letter-options">
    <b-card-group class="mb-3">
      <b-card
        header-bg-variant="info"
        header-text-variant="white"
        class="col-md-8 p-0 border-0"
      >
        <template v-slot:header>
          <h4>
            <template v-if="letterSent">Informations de suivi</template>
            <template v-else>Mode d'envoi</template>
          </h4>
        </template>
        <b-card-text>
          <template v-if="!letterSent">
            <send-mode></send-mode>
          </template>
          <template v-else>
            <tracking-informations></tracking-informations>
          </template>
        </b-card-text>
      </b-card>

      <b-card
        header-bg-variant="info"
        header-text-variant="white"
        class="col-md-4 p-0 border-0"
      >
        <template v-slot:header>
          <h4>Options d'impression</h4>
        </template>

        <b-card-text>
          <printing-details></printing-details>
        </b-card-text>
      </b-card>
    </b-card-group>

    <div v-if="!loading && !letterSent" class="pl-3 pr-3 pb-3">
      <hr/>
        <i class="fa fa-exclamation-triangle"></i><i> Pour un envoi postal le jour même, les lettres doivent être
        envoyées <b>avant 10h30</b> du lundi au vendredi (hors jours fériés).
        Passé le vendredi 10h30, les lettres seront postées le lundi.
        </i>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapState} from 'vuex'
  import PrintingDetails from './PrintingDetails.vue'
  import SendMode from "./LetterOptions/SendMode";
  import TrackingInformations from "./LetterOptions/TrackingInformations";

  export default {
    components: {
      PrintingDetails,
      SendMode,
      TrackingInformations
    },
    created () {
    },
    methods: {
    },
    computed: {
      ...mapState([
        'loading'
      ]),
      ...mapState('letter', {
        letter: state => state,
      }),
      ...mapGetters('letter', [
        'letterLocked',
        'letterSent',
        'letterPriceRefreshing'
      ])
    }
  }
</script>

<style scoped>
  .mode-container {
    min-height: 115px;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
  }

  .letter-options {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    margin-bottom: 15px;
  }
</style>
