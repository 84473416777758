<template>
  <div>
    <vue-loading :active.sync="isLoading"
             :is-full-page="false"
             :height="42"
             :width="42"
             :loader="'dots'"
    >
    </vue-loading>
  </div>
</template>

<script>

import VueLoading from 'vue-loading-overlay'
export default {
  props: {
    isLoading: Boolean
  },
  components: {
    VueLoading
  }
}
</script>
