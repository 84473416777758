<template>
  <div>
    <b-card
      border-variant="info"
      header="Primary"
      header-bg-variant="info"
      header-text-variant="white"
      header-class="text-center"
      header-tag="header"
    >
      <template v-slot:header>
        <div class="card-header-big">Suivi de l'envoi</div>
      </template>
      <div v-if="coOwner !== null">
        <template v-if="coOwner.delivery !== undefined">
          <div>Numéro de suivi : {{ coOwner.delivery.trackingNumber }}</div>
          <div>Statut : {{ deliveryService.getTextForStatus(coOwner.delivery.status, isAdminView) }}</div>
          <span>
            <img
              v-if="coOwnerService.iconForSendType(coOwner.delivery.type) !== undefined"
              height="20"
              :src="require('../../assets/images/' + coOwnerService.iconForSendType(coOwner.delivery.type))"
            />&nbsp;
            <a v-if="coOwner.delivery.trackingNumber" :href="deliveryService.urlForSendType(coOwner.delivery.type, coOwner.delivery.trackingNumber)">
              {{ coOwnerService.getTextForSendTypeAndLevel(coOwner.delivery.type, coOwner.delivery.level) }} (n°{{coOwner.delivery.trackingNumber}})
            </a>
            <span v-else>
              {{ coOwnerService.getTextForSendTypeAndLevel(coOwner.delivery.type, coOwner.delivery.level) }}
            </span>
          </span>
          <div v-if="coOwner.delivery.advices  && coOwner.delivery.advices.length > 0">
            <hr/>
            <co-owner-advice-details :batch-letter-id=this.batchLetterId :co-owner-id=coOwner.id :delivery-id= coOwner.delivery.id  :advices=coOwner.delivery.advices></co-owner-advice-details>
          </div>
        </template>
        <template v-else>
          <div>Statut : {{ deliveryService.getTextForStatus(coOwner.status, isAdminView) }}</div>
          <span>
            <img
              v-if="coOwnerService.iconForSendType(coOwner.sendType)"
              height="20"
              :src="require('../../assets/images/' + coOwnerService.iconForSendType(coOwner.sendType))"
            />&nbsp;
              {{ coOwnerService.getTextForSendTypeAndLevel(coOwner.sendType, coOwner.sendLevel) }}
              <br/>
              <b-link v-if="!coOwner.locked" @click="showSendModeOptionsPopup(coOwner)">
                <i class="fas fa-exchange-alt"></i>&nbsp;Modifier le mode d'envoi
              </b-link>
          </span>
        </template>
      </div>
    </b-card>
  </div>
</template>

<script>

import coOwnerService from '@/service/coowner'
import deliveryService from '@/service/delivery'
import CoOwnerAdviceDetails from './CoOwnerAdviceDetails'
import SendModeOptionsPopup from '@/components/SendModeOptionsPopup'
import { mapGetters } from 'vuex'
import {EventBus} from '../../EventBus'

export default {
  props: {
    coOwner: Object,
    batchLetterId: Number
  },
  components: {
    CoOwnerAdviceDetails
  },
  computed: {
    ...mapGetters('user', [
      'isAdminView'
    ])
  },
  data () {
    return {
      coOwnerService: coOwnerService,
      deliveryService: deliveryService
    }
  },
  methods: {
    showSendModeOptionsPopup (coOwner) {
      this.$modal.show(SendModeOptionsPopup, {
        batchLetterId: this.batchLetterId,
        coOwner: coOwner
      }, {
        width: '45%',
        height: 'auto',
        adaptive: true
      }, {
        closed (event) {
          EventBus.$emit('document_uploaded_event')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
