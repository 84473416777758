<template>
  <div>
    <cookie-law theme="blood-orange--rounded" buttonText="Je comprends">
      <template slot="message">
        En poursuivant la navigation, vous acceptez l'utilisation de cookies qui assurent le bon fonctionnement des services de ce site. Si vous ne souhaitez pas utiliser des cookies avec ce site, vous pouvez les désactiver dans les préférences de votre navigateur.
      </template>
    </cookie-law>
    <footer id="footer">
      <div class="container">
        <div class="row">
          <div class="col-md-2">
            © Clic'issimo {{ getYear() }}
          </div>
          <div class="col-md-10 text-right">
            <b-link>Conditions générales de vente</b-link>
            <b-link class="pl-3" :to="{name: 'legal_notice'}">Mentions légales</b-link>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>

import moment from "moment";
import CookieLaw from 'vue-cookie-law'
export default {
  methods: {
    getYear() {
      return moment().year()
    }
  },
  components: {
    CookieLaw
  }
}
</script>

<style scoped>
  #footer {
    color: white;
    position: absolute;
    bottom: 0;
    padding: 0;
    width: 100%;
    line-height: 50px;
    background-color: #405159;
  }

  a {
    color: white;
  }

  a:hover {
    color: #ffaa00;
    text-decoration: none;
  }
</style>

