import {RecipientState} from '../../../constants/Recipient'

export const state = {
  fullname: null,
  address: null,
  postcode: null,
  city: null,
  status: RecipientState.EMPTY,
  errors: [],
  customerReference: null
}
