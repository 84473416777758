<template>
  <b-container>
    <div class="vld-parent">
      <vue-loading :active.sync="isAuthenticating"
                   :is-full-page="true"
      ></vue-loading>

      <b-row class="justify-content-md-center">
        <b-col lg="6">
          <b-card
            border-variant="info"
            header="Primary"
            header-bg-variant="info"
            header-text-variant="white"
            header-class="text-center"
            header-tag="header"
          >
            <template v-slot:header>
              <div class="card-header-big">Connexion</div>
            </template>

            <b-form v-on:submit.prevent="doLogin">
              <b-form-group
                id="fieldset-vertical"
                label="Identifiant / Adresse mail"
                label-for="input-vertical"
                label-class="font-weight-bold"
              >
                <b-form-input v-model="login" size="sm"></b-form-input>
              </b-form-group>

              <b-form-group
                id="fieldset-vertical"
                label="Mot de passe"
                label-for="input-vertical"
                label-class="font-weight-bold"
              >
                <b-form-input v-model="password" type="password" size="sm"></b-form-input>
              </b-form-group>
              <div>
                <b-link @click="resetRequest">[Mot de passe oublié ?]</b-link>
              </div>
              <b-row align-h="end">
                <b-col>
                  <b-button variant="warning" size="lg" @click="register">
                    <i class="fas fa-user-plus"></i>&nbsp;S'inscrire
                  </b-button>
                </b-col>
                <b-col cols="auto">
                  <b-button type="submit" variant="warning" size="lg">
                    <b-spinner v-if="isAuthenticating" small></b-spinner>
                    <i v-else class="fas fa-key"></i>&nbsp;Connexion
                  </b-button>
                </b-col>
              </b-row>
            </b-form>

          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>
<script>

import { mapGetters, mapMutations } from 'vuex'
import VueLoading from 'vue-loading-overlay'

export default {
  data () {
    return {
      login: null,
      password: null
    }
  },
  components: {
    VueLoading
  },
  computed: {
    ...mapGetters('user', [
      'isAuthenticated',
      'isAuthenticating'
      // ...
    ])
  },
  created () {
    let redirect = this.$route.query.redirect

    if (this.$store.getters['user/isAuthenticated']) {
      if (typeof redirect !== 'undefined') {
        this.$router.push({path: redirect})
      } else {
        this.$router.push({name: 'default'})
      }
    } else {
      this.$store.dispatch('user/checkLogin').then(
        () => {
          if (typeof redirect !== 'undefined') {
            this.$router.push({path: redirect})
          } else {
            this.$router.push({name: 'default'})
          }
        }
      )
    }
  },
  methods: {
    ...mapMutations('user', [
      'AUTHENTICATING_SUCCESS'
    ]),
    doLogin () {
      this.$store.dispatch('user/login', {login: this.login, password: this.password}).then(
        (response) => {
          if (this.isAuthenticated === true) {
            let redirect = this.$route.query.redirect
            this.AUTHENTICATING_SUCCESS(response.data)
            if (typeof redirect !== 'undefined') {
              this.$router.push({path: redirect})
            } else {
              this.$router.push({name: 'default'})
            }
          } else {
            this.$swal('Merci de vérifier votre identifiant ou mot de passe.', 'Identifiant ou mot de passe incorrect', 'error')
          }
        }
      )
    },
    register () {
      this.$router.push({
        name: 'register'
      })
    },
    resetRequest () {
      this.$router.push({
        name: 'reset_request'
      })
    }
  }
}
</script>
