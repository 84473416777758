<template>
  <div>
    <b-row class="my-1">
      <b-col sm="2">
        <label> Catégorie : </label>
      </b-col>
      <b-col sm="8">
        <b-form-radio-group
          v-model="coOwner.isCompany"
          :options="categoryOptions"
          id="category"
          name="category"
          v-validate="'required'"
        >
        <b-form-invalid-feedback :state="validateState('category')">{{ errors.first('category') }}</b-form-invalid-feedback>
        </b-form-radio-group>
      </b-col>
    </b-row>

    <b-row class="my-2" v-if="coOwner.isCompany">
      <b-col sm="12">
        <label> Entreprise : </label>
        <b-form-input
          v-model="coOwner.companyName"
          id="companyName"
          name="companyName"
          v-validate="'required|max:38'"
          :state="validateState('companyName')"
          aria-describedby="input-companyName-feedback"
        ></b-form-input>
        <b-form-invalid-feedback :state="validateState('companyName')">{{ errors.first('companyName') }}</b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="my-2">
      <b-col sm="12">
        <b-form-select
          v-model="coOwner.gender"
          :options="genderOptions"
          name="gender"
          v-validate="nameRules"
          :state="validateState('gender')"
          aria-describedby="input-gender-feedback"
        ></b-form-select>
        <b-form-invalid-feedback :state="validateState('gender')">{{ errors.first('gender') }}</b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="my-2">
      <b-col sm="6">
        <label>Nom :</label>
        <b-form-input
          v-model="coOwner.lastName"
          id="lastName"
          name="lastName"
          v-validate="nameRules"
          :state="validateState('lastName')"
          aria-describedby="input-lastName-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-lastName-feedback">{{ errors.first('lastName') }}</b-form-invalid-feedback>
      </b-col>
      <b-col sm="6">
        <label>Prénom :</label>
        <b-form-input
          v-model="coOwner.firstName"
          id="firstName"
          name="firstName"
          v-validate="nameRules"
          :state="validateState('firstName')"
          aria-describedby="input-firstName-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-firstName-feedback">{{ errors.first('firstName') }}</b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="my-2">
      <b-col sm="6">
        <label>Adresse e-mail :</label>
        <b-form-input
          v-model="coOwner.email"
          id="input-email"
          name="email"
          v-validate="emailRules"
          :state="validateState('email')"
          aria-describedby="input-email-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-email-feedback">{{ errors.first('email') }}</b-form-invalid-feedback>
      </b-col>
      <b-col sm="6">
        <label>Téléphone :</label>
        <b-form-input
          v-model="coOwner.phone"
          id="phone"
          name="phone"
          v-validate="phoneRules"
          :state="validateState('phone')"
          aria-describedby="input-phone-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-phone-feedback">{{ errors.first('phone') }}</b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="my-2">
      <b-col sm="12">
        <label>Adresse :</label>
        <b-form-textarea
          v-model="coOwner.address.address"
          rows="2"
          id="address"
          name="address"
          v-validate="'required|addressLimit'"
          :state="validateState('address')"
          aria-describedby="input-address-feedback"
        ></b-form-textarea>
        <b-form-invalid-feedback id="input-address-feedback">{{ errors.first('address') }}</b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="my-2">
      <b-col sm="4">
        <label>Code postal :</label>
        <b-form-input
          v-model="coOwner.address.postcode"
          id="postcode"
          name="postcode"
          v-validate="coOwner.address.country.toUpperCase() !== 'FRANCE' ? 'required' : 'required|digits:5'"
          :state="validateState('postcode')"
          aria-describedby="input-postcode-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-postcode-feedback">{{ errors.first('postcode') }}</b-form-invalid-feedback>
      </b-col>
      <b-col sm="8">
        <label>Ville :</label>
        <b-form-input
          v-model="coOwner.address.city"
          id="city"
          name="city"
          v-validate="'required'"
          :state="validateState('city')"
          aria-describedby="input-city-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-city-feedback">{{ errors.first('city') }}</b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="my-2">
      <b-col sm="12">
        <label>Pays :</label>
        <b-form-input
          v-model="coOwner.address.country"
          id="country"
          name="country"
          v-validate="'required'"
          :state="validateState('country')"
          aria-describedby="country-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="country-feedback">{{ errors.first('country') }}</b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="my-2">
      <b-col sm="12">
        <label>Référence :</label>
        <b-form-input
          v-model="coOwner.reference"
          id="input-reference"
          name="input-reference"
          v-validate="'required'"
          :state="validateState('input-reference')"
          aria-describedby="input-reference-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-reference-feedback">{{ errors.first('input-reference') }}</b-form-invalid-feedback>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { Gender, Category, SendType } from '@/service/coowner'

export default {
  props: {
    coOwner: Object
  },
  inject: ['$validator'],
  data () {
    return {
      genderOptions: [
        { value: null, text: 'Sélectionnez une civilité' },
        { text: 'M', value: Gender.Male },
        { text: 'Mme', value: Gender.Female },
        { text: 'M ou M', value: Gender.MaleAndMale },
        { text: 'Mme ou Mme', value: Gender.FemaleAndFemale },
        { text: 'M. ou Mme', value: Gender.MaleAndFemale }
      ],
      categoryOptions: [
        { text: 'Particulier', value: Category.Individual },
        { text: 'Professionnel', value: Category.Company }
      ]
    }
  },
  computed: {
    emailRules () {
      return this.coOwner.sendType === SendType.Electronic ? 'required|email' : 'email'
    },
    phoneRules () {
      return this.coOwner.sendType === SendType.Electronic ? 'required|digits:10' : 'digits:10'
    },
    nameRules () {
      return this.coOwner.isCompany ? '' : 'required'
    },
  },
  methods: {
    validateState (ref) {
      if (
        this.formFields[ref] &&
        (this.formFields[ref].dirty || this.formFields[ref].validated)
      ) {
        return !this.errors.has(ref)
      }
      return null
    }
  }
}
</script>
