<template>
  <div>
   <b-container class="container edit-page">
    <template v-if="this.letterSent">
      <h1>
        <span v-html="this.getStatusIcon()"></span> {{ this.getStatusText() }} {{ this.getAdditionalStatus() }}
      </h1>
    </template>
    <template v-else>
      <div class="row">
        <div class="col">
          <h2><font-awesome-icon icon="paper-plane"></font-awesome-icon>&nbsp;Nouveau courrier</h2>
        </div>
        <div class="col-md-auto">
          <!-- Will not display pricing details in sm screen  -->
          <div class="d-none d-sm-block h2 text-md-left">
            <price-detail v-bind:letterPriceRefreshing="letterPriceRefreshing" v-bind:letter="letter"></price-detail>
          </div>
        </div>
      </div>
    </template>

    <!-- To show pricing details in sm screen only -->
     <div class="d-block d-sm-none">
       <price-detail v-bind:letterPriceRefreshing="letterPriceRefreshing" v-bind:letter="letter"></price-detail>
     </div>
    <div v-if="letter.collab" id="collab-name">
      <i class="fa fa-user"></i><i> Commencé par {{ letter.collab.name }}</i>
    </div>

    <hr/>
    <files-container></files-container>
    <letter-options></letter-options>
    <recipient v-if="displayRecipientBlock"></recipient>
    <contact-region v-else></contact-region>
    <submit-bar></submit-bar>
  </b-container>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'

import FilesContainer from '../../components/shelled_letter/NewLetterPage/Files/FilesContainer.vue'
import SendMode from '../../components/shelled_letter/NewLetterPage/LetterOptions/SendMode.vue'
import Recipient from '../../components/shelled_letter/NewLetterPage/Recipient/Recipient.vue'
import SubmitBar from '../../components/shelled_letter/NewLetterPage/SubmitBar.vue'
import ContactRegion from '../../components/shelled_letter/NewLetterPage/ContactRegion.vue'

import api from '@/api/paperletter'
// import LoadingService from 'paperletter/services/LoadingService'
import LetterAdapter from '../../api/LetterAdapter'
import LetterOptions from "../../components/shelled_letter/NewLetterPage/LetterOptions";
import LetterStatusService from "../../service/LetterStatusService";
import auth from "../../service/auth";

export default {
  // It needs to be added for a redirection to /shelled_letter_new
  // For some weird reasons, beforeRouteUpdate is not called
  beforeRouteEnter (to, from, next) {
    next(vm => {
      let letterId = parseInt(to.params.id)
      vm.initLetter(letterId)
    })
  },
  // To handle redirection to other letters
  // For some other weird reasons, beforeRouteEnter is not called for this case !
  beforeRouteUpdate (to, from, next) {
    let letterId = parseInt(to.params.id)
    this.initLetter(letterId)
  },
  destroyed () {
    this.resetLetter()
  },
  methods: {
    ...mapActions('letter', [
      'editLetter',
      'resetLetter'
    ]),
    initLetter(letterId) {
      if (letterId && letterId === this.letter.id) {
        return
      }

      this.resetLetter()

      const promise = (response) => {
        this.editLetter(response.data)
        let uri = '/shelled_letters/' + response.data.id
        if (this.$router.currentRoute.path !== uri) {
          this.$router.replace('/shelled_letters/' + response.data.id)
        }
      }
      const errorPromise = (error) => {
        if (!auth.redirectToLoginIfNotLogged(error.response)) {
          this.$router.push({name: 'shelled_letters_list'})
        }
      }

      if (letterId) {
        api.getLetter(letterId).then(promise).catch(errorPromise)
      } else {
        let letterAdapter = new LetterAdapter(this.letter)
        letterAdapter.post().then(promise).catch(errorPromise)
      }
    },
    getStatusText() {
      return LetterStatusService.getLetterStatusText(this.letter, this.isAdminView);
    },
    getStatusIcon() {
      return LetterStatusService.getLetterStatusIconFromText(this.getStatusText());
    },
    getAdditionalStatus() {
      return LetterStatusService.getLetterStatusAdditionalText(this.letter);
    },
  },
  components: {
    LetterOptions,
    'files-container': FilesContainer,
    'contact-region': ContactRegion,
    'recipient': Recipient,
    'submit-bar': SubmitBar,
    'price-detail': {
      props: ['letter', 'letterPriceRefreshing'],
      template: `
        <div v-tooltip="{content: 'Ce tarif comprend l\\'impression et la mise sous pli ainsi que le timbre. Le tarif du timbre est estimé en fonction du poids. Si jamais nous obtenons un tarif plus intéressant, vous serez remboursé de la différence.' }">
          <span class="hidden-md"><i class="fa fa-info-circle"></i> Prix de la lettre:</span>
          <span v-if="letterPriceRefreshing"><b><i class="fas fa-circle-notch fa-spin"></i></b></span>
          <span v-else-if="letter.price > 0"><b>{{ letter.price | formatFloat }} € </b></span>
          <span v-else><b> -- € </b></span>
        </div>
      `}
  },
  computed: {
    ...mapState('letter', {
      letter (state) {
        return state
      }
    }),
    ...mapGetters('user', [
      'isAdminView'
    ]),
    ...mapGetters('letter', [
      'letterSent',
      'letterProgrammed',
      'letterPriceRefreshing',
      'registerRecipientDetected',
      'displayRecipientBlock'
    ])
  }

}
</script>

<style scoped>
  #collab-name {
    padding-left: 15px;
    margin-bottom: 5px;
  }
  .container.edit-page {
    padding-bottom: 70px;
  }

  #price-block {
    position: absolute; bottom: 0;
    right: 0;
  }
</style>
