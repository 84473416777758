import api from './paperletter'
import ContactAdapter from './ContactAdapter'
import DocumentAdapter from './DocumentAdapter'
import {RecipientState} from '../constants/Recipient'

export default class LetterAdapter {
  constructor (letter) {
    this.letter = letter
    this.recipientAdapter = new ContactAdapter(letter.recipient)
    this.senderAdapter = new ContactAdapter(letter.sender)
    this.documentAdapter = new DocumentAdapter(letter)
  }

  isPersisted () {
    return typeof this.letter.id === 'number'
  }

  hasRecipient () {
    return typeof this.letter.recipient === 'object'
  }

  hasSender () {
    return typeof this.letter.sender === 'object'
  }

  hasDocument () {
    return this.letter.documents.length > 0
  }

  prepareSendMode () {
    if (this.letter.sendMode !== null) {
      return {sendMode: this.letter.sendMode.id}
    } else {
      return {}
    }
  }

  prepareDoubleSidedPrinting () {
    return {doubleSidedPrinting: this.letter.doubleSidedPrinting}
  }

  prepareColor () {
    return {color: this.letter.color}
  }

  prepareProgrammedDate () {
    return {programmedDate: this.letter.programmedDate}
  }

  prepareRecipient () {
    if (
      this.hasRecipient() &&
            this.letter.recipient.status === RecipientState.VALID &&
            (this.letter.isAddressPageNeeded || (this.letter.sendMode !== null && this.letter.sendMode.senderAvailable))
    ) {
      return {recipient: this.recipientAdapter.prepareRecipient()}
    }
  }

  prepareSender () {
    if (
      this.hasSender() &&
            this.letter.sender.status === RecipientState.VALID &&
            this.letter.sendMode !== null && this.letter.sendMode.senderAvailable
    ) {
      return {sender: this.senderAdapter.prepareSender()}
    }
  }

  prepareDocuments () {
    if (this.hasDocument()) {
      return {documents: this.documentAdapter.prepareDocuments()}
    }
  }

  prepareAddressPageNeeded () {
    return {addressPageNeeded: this.letter.isAddressPageNeeded}
  }

  prepareLetter () {
    return {
      ...this.prepareSendMode(),
      ...this.prepareRecipient(),
      ...this.prepareSender(),
      ...this.prepareAddressPageNeeded(),
      ...this.prepareDoubleSidedPrinting(),
      ...this.prepareColor()
    }
  }

  postSend () {
    let data = this.prepareProgrammedDate()
    return api.postSendLetter(this.letter.id, data)
  }

  post () {
    return api.postLetter(this.prepareLetter())
  }

  put (member = null) {
    let data

    if (member === null) {
      data = this.prepareLetter()
    } else {
      switch (member) {
        case 'sendMode':
          data = this.prepareSendMode()
          break
        case 'contact':
          data = {
            ...this.prepareRecipient(),
            ...this.prepareSender()
          }
          break
        case 'documents':
          data = this.prepareDocuments()
          break
        case 'addressPageNeeded':
          data = this.prepareAddressPageNeeded()
          break
        case 'color':
          data = this.prepareColor()
      }
    }

    return api.putLetter(this.letter.id, data)
  }

  putCancel () {
    return api.putCancel(this.letter.id)
  }

  deleteLetter (id) {
    return api.deleteLetter(id)
  }
}
