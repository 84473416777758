<template>
  <div>
    <div class="h2 text-center"><font-awesome-icon icon="list"></font-awesome-icon> Courriers en cours ou envoyés</div>
    <hr/>
    <div class="vld-parent">
      <loading :isLoading="isLoading"></loading>
      <v-server-table ref="paperLetterTable" name="paperLetterTable" :columns="getColumns()" :options="tableOptions()" @loaded="onTableLoaded" @loading="onTableLoading">
        <router-link slot="id" slot-scope="props" :to="{ name: 'shelled_letters_edit', params: {id: props.row.id}}"><i class="fas fa-envelope"></i>&nbsp;{{ props.row.id }}</router-link>
        <span slot="user" slot-scope="props">
          {{props.row.user.firstname}} {{props.row.user.lastname}} ({{props.row.user.id}})
          <br>
          {{props.row.user.email}}
      </span>
        <span slot="recipient" slot-scope="props" v-if="props.row.recipient">
        {{props.row.recipient.fullname.toUpperCase()}}<br>
        <span style="white-space: pre">{{props.row.recipient.address.toUpperCase()}}</span><br/>
        {{ props.row.recipient.postcode }} {{ props.row.recipient.city }}
      </span>
        <span v-else>
        ---
      </span>
        <span slot="sender" slot-scope="props" v-if="props.row.sender">
        {{props.row.sender.fullname.toUpperCase()}}<br>
        <span style="white-space: pre">{{props.row.sender.address.toUpperCase()}}</span><br/>
        {{ props.row.sender.postcode }} {{ props.row.sender.city.toUpperCase() }}
      </span>
        <span v-else>
        ---
      </span>
        <template slot="status" slot-scope="props" >
          <span v-html="getStatusIcon(props.row.status)"></span>&nbsp;{{props.row.status}}
          <b-link
            v-if="props.row.allowDeleteLetter"
            v-tooltip="'Supprime ce brouillon ainsi que ses documents associés'"
            @click="confirmDeleteLetter(props.row)"
          >
            <br/>
            [Effacer]
          </b-link>
          <span v-else><br/> {{ props.row.statusAdditionalText }} </span>
        </template>
        <template slot="sendMode" slot-scope="props">
          <i class="fa fa-envelope" :style="{color: props.row.sendModeDisplayColor}"></i> {{  props.row.sendMode }}
        </template>
        <template slot="tracking" slot-scope="props">
             <span v-if="props.row.trackingAvailable">
          <a target="_blank" :href="props.row.trackingUrl">
            <img height="20" src="@/assets/images/laposte.png"/>&nbsp;
            {{ props.row.tracking }}
          </a>
        </span>
          <span v-else>
          {{ props.row.tracking }}
        </span>
        </template>
      </v-server-table>
    </div>

  </div>
</template>

<script>

import api from '@/api/paperletter'
import LetterStatusService, {LetterFilterState, LetterTracking} from '@/service/LetterStatusService'
import { mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import authService from '@/service/auth'

export default {
  data () {
    return {
      isLoading: false
    }
  },
  components: {
    Loading
  },
  computed: {
    ...mapGetters('user', [
      'isAdminView'
    ])
  },
  methods: {
    ...mapActions('letter', [
      'deleteLetter'
    ]),
    onTableLoaded () {
      this.isLoading = false
    },
    onTableLoading(){
      this.isLoading = true
    },
    getColumns () {
      if (this.isAdminView) {
        return ['id', 'user', 'recipient', 'sender', 'status', 'sendMode', 'tracking']
      } else {
        return ['id', 'recipient', 'sender', 'status', 'sendMode', 'tracking']
      }
    },
    tableOptions () {
      return {
        texts: {
          defaultOption: 'Tous'
        },
        requestFunction: function (data) {
          return api.getLetters(data)
            .catch(function (e) {
              if (!authService.redirectToLoginIfNotLogged(e.response)) {
                this.dispatch('error', e)
              }
            }.bind(this))
        },
        requestAdapter (data) {
          return {
            pageLength: data.limit,
            page: data.page,
            sortField: data.orderBy ? data.orderBy : 'id',
            sortDirection: data.ascending ? 'asc' : 'desc',
            filters: {
              id: data.id,
              user: data.user,
              recipient: data.recipient,
              sendMode: data.sendMode,
              status: data.status,
              sender: data.sender,
              tracking: data.tracking
            }
          }
        },
        responseAdapter: function (resp) {
          const data = []
          for (let row of resp.data.items) {
            data.push({
              'id': row.id,
              'recipient': row.recipient,
              'status': LetterStatusService.getLetterStatusText(row),
              'sendMode': row.sendMode.name,
              'user': row['user'],
              'allowDeleteLetter': LetterStatusService.allowDeleteLetter(row),
              'statusAdditionalText': LetterStatusService.getLetterStatusAdditionalText(row),
              'sendModeDisplayColor': row.sendMode.displayColor,
              'sender': row.sender,
              'tracking': LetterStatusService.getTrackingStatusText(row),
              'trackingAvailable':  LetterStatusService.isValidTrackingNumber(row),
              'trackingUrl': LetterStatusService.getTrackingUrl(row)
            })
          }

          return {
            data: data,
            count: resp.data.totalItems
          }
        },
        headings: {
          id: 'N°',
          user: 'Utilisateur',
          recipient: 'Destinataire',
          sender: 'Expéditeur',
          sendMode: 'Mode d\'envoi',
          status: 'Statut',
          tracking: 'Suivi'
        },
        orderBy: { ascending:false },
        sortable: ['id'],
        filterByColumn: true,
        filterable: this.getColumns(),
        columnsClasses: {
          id: 'id'
        },
        saveState: true,
        storage: 'session',
        customFilters: ['id', 'user', 'recipient', 'status', 'sendMode', 'sender', 'tracking'],
        listColumns: this.listColumns(),

      }
    },
    listColumns () {
      let statusArray = [
        { id: LetterFilterState.CREATION, text: 'En création' },
        { id: LetterFilterState.VIRTUAL_PROGRAMMED, text: 'Programmé' },
        { id: LetterFilterState.VIRTUAL_TRANSMITTED, text: 'Transmis' },
        { id: LetterFilterState.VIRTUAL_POSTED, text: 'Posté' },
        { id: LetterFilterState.CANCELED, text: 'Annulé' }
      ]

      if (this.isAdminView) {
        statusArray.push({ id: LetterFilterState.VIRTUAL_ERROR, text: 'Erreur expédition' })
      }

      let trackingArray = [
        { id: LetterTracking.SUBMITTED, text: 'En cours' },
        { id: LetterTracking.DELIVERED, text: 'Distribué' },
        { id: LetterTracking.RETURNED, text: 'Retourné' },
        { id: LetterTracking.CANCELED, text: 'Annulé' },
        { id: LetterTracking.VIRTUAL_ERROR, text: 'Incident' }
      ]

      return {
        status: statusArray,
        tracking: trackingArray
      }
    },
    confirmDeleteLetter(letter) {
      this.$swal({
        title: "Supprimer",
        text: "Ce brouillon et ses documents associés seront supprimés",
        icon: "warning",
        buttons: ["Annuler", "Continuer"],
        dangerMode: true,
      }).then( deleteLetter => {
        if (deleteLetter) {
          this.deleteLetter(letter)
          this.$refs.paperLetterTable.refresh()
        }
      })
    },
    getStatusIcon(text){
      return LetterStatusService.getLetterStatusIconFromText(text)
    }
  }
}
</script>

<style scoped>
  .id {
    max-width: 100px;
  }

  @media (max-width: 991.98px) {
    div.h2 {
      font-size: 1.5rem;
    }
  }

</style>
