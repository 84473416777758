<template>
  <div>
    <h1 class="text-center">Reçus</h1>
    <hr/>
    <b-card
      border-variant="info"
      header-bg-variant="info"
      header-text-variant="white"
      header-class="text-center card-body-condensed"
      header-tag="header"
    >
      <template v-slot:header>
        <h3>Liste des reçus</h3>
      </template>

      <div class="vld-parent">
        <loading :isLoading="isLoading"></loading>
        <v-server-table ref="receiptTable" name="receiptTable" :columns="getColumns()" :options="options" @loaded="onTableLoaded" @loading="onTableLoading">
          <template  slot="id" slot-scope="props">
            R{{props.row.id}}
          </template>
          <template  slot="action" slot-scope="props">
            <font-awesome-icon icon="eye" slot="action" style="margin-right: 17px" class="fa-action" @click="downloadReceipt(props.row.id)"></font-awesome-icon>
          </template>
        </v-server-table>
      </div>
    </b-card>
  </div>
</template>

<script>

import billApi from '@/api/bills'
import PreviewPopup from '@/components/PreviewPopup'
import moment from 'moment'
import {mapGetters} from 'vuex'
import authService from '@/service/auth'
import Loading from '../Loading'

export default {
  props: {
    user: Object
  },
  components: {
    Loading
  },
  data () {
    return {
      isLoading: false,
      options: {
        requestFunction: (data) => {
          return billApi.getUserReceipts(data)
            .catch(function (e) {
              if (!authService.redirectToLoginIfNotLogged(e.response)) {
                this.dispatch('error', e)
              }
            }.bind(this))
        },
        requestAdapter (data) {
          return {
            limit: data.limit,
            page: data.page,
            sort: data.orderBy ? data.orderBy : 'id',
            orderBy: data.ascending ? 'asc' : 'desc'
          }
        },
        responseAdapter: function (resp) {
          const data = []
          for (let row of resp.data.data) {
            data.push({
              'id': row['id'],
              'date': moment(row['date']).format('DD/MM/YYYY'),
              'valueHT': row['valueHT'] + ' €',
              'total': row['total'] + ' €',
            })
          }

          return {
            data: data,
            count: resp.data.count
          }
        },
        headings: {
          id: 'Numéro de reçu',
          date: 'Date',
          valueHT: 'Montant',
          total: 'Total',
          action: 'Télécharger',
        },
        orderBy: { ascending: false },
        filterable: false,
        sortable: ['id', 'date'],
        saveState: true,
        storage: 'session'
      }
    }
  },
  methods: {
    onTableLoaded () {
      this.isLoading = false
    },
    onTableLoading(){
      this.isLoading = true
    },
    getColumns () {
      let cols
      // if (this.isAdminView) {
      //   cols = ['id', 'user', 'customReferences', 'paidBy', 'date', 'status', 'valueHT', 'total', 'action']
      // } else {
      //   cols = ['id', 'customReferences', 'paidBy', 'date', 'status', 'valueHT', 'total', 'action']
      // }

      cols = ['id','date', 'valueHT', 'total', 'action']

      return cols
    },
    getCoOwnershipName (customReferences) {
      for (let ref of customReferences) {
        if (ref.label === 'Copropriété') {
          return ref.value
        }
      }
    },
    downloadReceipt (id) {
      let url = billApi.downloadReceiptLink(id)
      this.$modal.show(PreviewPopup, {
        url: url,
        name: `Recu_${id}.pdf`
      }, {
        width: '55%',
        height: 'auto',
        scrollable: true,
        adaptive: true
      })
    }
  }
}
</script>
