<template>
    <div class="auto-complete form-group" :class="{'has-error': errors.has('postcode')}">
        <label for="postCode" class="control-label">Code postal *</label>
        <input
            class="form-control"
            id="postCode"
            type="text"
            :name="name"
            v-model="postCodeModel"
            @keydown.down="onArrowDown"
            @keydown.up="onArrowUp"
            @keydown.enter="complete()"
            @keydown.tab.prevent=""
            @focus="focus($event)"
            @blur="blur($event)"
            @input="onChange"
        >
        <span v-if="postCodeLoading" class="fas fa-circle-notch fa-spin input-spinner"></span>
        <div class="text-danger" v-show="errors.has('postcode')">{{ errors.first('postcode') }}</div>
        <table v-if="isPopupDisplayable()" @focus="focus($event)" @blur="blur($event)" @keydown.down="onArrowDown" @keydown.up="onArrowUp" @keydown.enter="complete">
            <tbody ref="scrollContainer">
            <tr ref="suggestRow" v-for="(postCode, i) in postCodeData" v-bind:key="i" @mousemove="highlight(i)" @keydown.down="onArrowDown" @keydown.up="onArrowUp" @keydown.enter="complete">
                <td :class="{'selected': selected === i}" @keydown.down="onArrowDown" @mousedown="complete" @keydown.up="onArrowUp" @keydown.enter="complete">{{ getDisplayableText(postCode) }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import debounce from 'lodash/debounce'
import api from '../../../../api/paperletter'
import axios from 'axios'

export default {
  props: {
    name: String,
    postcode: String,
    fieldError: String,
    itemClicked: Function,
    inputChange: Function
  },
  data () {
    return {
      selected: 0,
      focused: false,
      postCodeLoading: false,
      cancelTokenSource: null,
      postCodeData: [],
      internalPostCode: null
    }
  },
  inject: ['$validator'],
  $_veeValidate: {
    value () {
      return this.internalPostCode
    },
    name () {
      return this.name
    }
  },
  computed: {
    postCodeModel: {
      get () {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.internalPostCode = this.postcode
        return this.postcode
      },
      set: function (value) {
        this.internalPostCode = value
      }
    }
  },
  methods: {
    isPopupDisplayable () {
      return this.focused && !this.postCodeLoading && this.postCodeData.length > 1
    },
    getDisplayableText (item) {
      if (this.internalPostCode.length < 5) {
        return item.code_postal + ' ' + item.nom_de_la_commune
      } else {
        return item.nom_de_la_commune
      }
    },
    highlight (i) {
      this.selected = i
    },
    onArrowDown () {
      if (this.selected < (this.postCodeData.length - 1)) {
        this.selected += 1
        this.fixScrolling()
      }
    },
    onArrowUp () {
      if (this.selected > 0) {
        this.selected -= 1
        this.fixScrolling()
      }
    },
    fixScrolling () {
      const liH = this.$refs.suggestRow[this.selected].clientHeight
      this.$refs.scrollContainer.scrollTop = liH * this.selected
    },
    complete () {
      this.select(this.postCodeData[this.selected])
      this.blur()
    },
    select (row) {
      this.itemClicked(row)
      this.selected = true
    },
    focus () {
      this.selected = 0
      this.focused = true
    },
    blur () {
      this.focused = false
    },
    onChange (e) {
      this.$emit('input', e.target.value)

      if (e.target.value !== this.postcode) {
        this.inputChange(e.target.value)
      }
      if (e.target.value) {
        this.update(e)
      } else {
        this.postCodeData = []
      }
    },
    update: debounce(function (e) {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel('aborted')
      }

      let CancelToken = axios.CancelToken
      this.cancelTokenSource = CancelToken.source()

      this.postCodeLoading = true

      api.getCitiesForPostCode(e.target.value, {cancelToken: this.cancelTokenSource.token})
        .then((response) => {
          this.postCodeLoading = false
          this.selected = 0
          this.postCodeData = response.data

          if (this.postCodeData.length === 1) {
            this.complete()
          }
          this.focused = true
        })
        .catch((error) => {
          this.postCodeLoading = false
          this.postCodeData = []
        })
    }, 500)
  }
}
</script>

<style scoped>
    .input-spinner {
        float: right;
        margin-right: 6px;
        margin-top: -23px;
        position: relative;
        z-index: 2;
    }

    .auto-complete input.has-error {
        border-color: #F44336;
    }

    .auto-complete table {
        position: absolute;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        border: 2px solid #888;
        border-top: 0px;
        cursor: pointer;
        z-index: 100;
        width: 300px;
    }

    .auto-complete tbody {
        display: block;
        overflow-y: auto;
        max-height: 200px;
    }

    .auto-complete table tr {
        background: white;
    }

    .auto-complete table tr td {
        padding: 10px;
        width: 300px;
    }

    .auto-complete table tr:nth-child(even) {
        background: #EEE;
    }

    .auto-complete table tr .selected {
        background: #2196F3;
        color: white;
    }
</style>
