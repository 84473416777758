<template>
  <div class="auto-complete">
    <b-form-input
      autocomplete="off"
      :id="id"
      :ref="'input-' + field"
      v-model="valueModel"
      @input="onChange"
      size="sm"
      :name="name"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="complete()"
      @keydown.tab.prevent=""
      @focus="focus($event); update()"
      @blur="blur($event)"
      v-validate="vValidate"
      :state="state"
      :disabled="disabledModel"
    ></b-form-input>
    <span v-if="listLoading" class="fas fa-circle-notch fa-spin input-spinner"></span>
    <div class="text-danger" v-show="errors.has('value')">{{ errors.first('value') }}</div>
    <table v-if="isPopupDisplayable()" @focus="focus($event)" @blur="blur($event)" @keydown.down="onArrowDown" @keydown.up="onArrowUp" @keydown.enter="complete">
      <tbody ref="scrollContainer">
      <tr
        :class="{'selected': selected === i}"
        ref="suggestRow" v-for="(val, i) in list"
        v-bind:key="i"
        @mousemove="highlight(i)"
        @mousedown="complete"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown.enter="complete"
      >
        <td v-html="getFormattedValue(coOwnerships.get(val)[field], value)"></td>
        <td class="secondary">
          {{ coOwnerships.get(val)[field === 'name' ? 'reference' : 'name'] }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import {getCoOwnershipList} from "@/api";
import {ValidationProvider} from 'vee-validate'
import axios from "axios";
import api from "@/api/paperletter";

export default {
  props: {
    name: String,
    value: String,
    field: String,
    inputChange: Function,
    disabled: Boolean,
    coOwnershipId: Number,
    state: Boolean,
    id: String,
    vValidate: String
  },
  data () {
    return {
      internalValue: null,
      list: [],
      listLoading: false,
      focused: false,
      coOwnerships: new Map(),
      listOpened: false,
      selected: 0,
    }
  },
  inject: ['$validator'],
  $_veeValidate: {
    value () {
      return this.internalValue;
    },
    name () {
      return this.name;
    }
  },
  computed: {
    valueModel: {
      get () {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.internalValue = this.value;
        return this.value;
      },
      set: function (value) {
        this.internalValue = value;
      }
    },
    disabledModel: {
      get () {
        return this.disabled;
      }
    }
  },
  methods: {
    isPopupDisplayable () {
      return this.focused && !this.listLoading && this.list.length >= 1
    },
    highlight (i) {
      this.selected = i
    },
    onArrowDown () {
      if (this.selected < (this.list.length - 1)) {
        this.selected += 1
        this.scrollToSelected(this.selected);
      }
    },
    onArrowUp () {
      if (this.selected > 0) {
        this.selected -= 1
        this.scrollToSelected(this.selected);
      }
    },
    complete () {
      this.select(this.list[this.selected])
      this.blur()
      //this.$refs.input.$el.
    },
    select (row) {
      this.selectCoOwnership(row)
      this.selected = true
    },
    focus () {
      this.selected = 0
      this.focused = true
    },
    blur () {
      this.focused = false
    },
    onChange (e) {
      this.$emit('input', e)

      if (e !== this.value) {
        this.inputChange(this.$props.field, e)
      }

      this.update(e)

    },
    getFormattedValue (str, substr) {
      const strRegExp = new RegExp(substr, 'gi');
      return substr === ""
        ? str
        : str.replace(strRegExp, (match) => `<strong>${match}</strong>`);
    },
    update: debounce(function (filt) {
      this.listLoading = true;

      // get 5 first results from the API
      getCoOwnershipList({
        field: this.$props.field,
        filter: filt ?? this.valueModel,
        limit: 30,
        page: 1,
        completed: true
      }).then(response => {
        this.list = [];

        response.data.forEach(coOwnership => {
          if (coOwnership.id !== this.coOwnershipId) {
            this.list.push(coOwnership.id);
            if (!this.coOwnerships.has(coOwnership.id)) {
              this.coOwnerships.set(coOwnership.id, coOwnership);
            }
          }
        });

        this.listLoading = false;
      });
    }, 500),
    selectCoOwnership (id) {
      //this.inputChange({ field: this.$props.field, value: this.coOwnerships.get(id)[this.$props.field]})
      //this.valueModel.set(this.coOwnerships.get(id)[this.$props.field]);
      this.selected = id;
      this.$emit("selectCoOwnership", this.coOwnerships.get(id));
    },
    scrollToSelected (n) {
      // you should not look at this 😬
      const row = this.$refs.suggestRow[n];
      const container = this.$refs.scrollContainer;

      const a = row.offsetTop;
      const b = row.clientHeight;

      const c = container.scrollTop;
      const d = container.clientHeight;

      if (a < c) {
        container.scrollTop = a;
      } else if (a + b > c + d) {
        container.scrollTop = a + b - d;
      }

    }
  }
}
</script>

<style scoped>
div {
  position: relative;
}

.input-spinner {
  float: right;
  margin-right: 30px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}

.auto-complete {
  position: relative;
  --border: 1px solid #ccc;
}

.auto-complete input.has-error {
  border-color: #F44336;
}

.auto-complete table {
  width: 100%;
  position: absolute;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border: var(--border);
  border-top: 0;
  cursor: pointer;
  z-index: 100;
}

.auto-complete tbody {
  position: relative;
  display: block;
  overflow-y: auto;
  max-height: 300px;
}

.auto-complete table tr {
  position: relative;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s;
  font-size: 16px;
  padding: 0 10px;
  gap: 10px;
}

.auto-complete table tr td {
  position: relative;
  padding: 5px 0;
}

.auto-complete table tr td.secondary {
  font-size: 0.9em;
  font-style: italic;
  opacity: 0.4;
  text-align: right;
}

.auto-complete table tr.selected {
  color: #2196F3;
  background-color: #f8f8f8;
  padding-left: calc(10px + 2ch);
}

.auto-complete table tr.selected::before  {
  position: absolute;
  content: '➔';
  font-family: 'monospace';
  margin-left: -2ch;
}

</style>
