<template>
  <div>
    <b-row>
        <template v-if="letterSent && letterProgrammedPreview && !letterCanceled">
          <b-col sm class="p-md-0 mb-2 mb-md-0">
            <button class="btn btn-lg btn-success letter-btn mb-2 mb-md-0" @click="rescheduleLetter()">
              <i v-if="letterSynchronizing" class="fa fa-circle-o-notch fa-spin "></i>
              <i v-else class="fa fa-calendar"></i>
                  Modifier la date d'envoi
            </button>
          </b-col>
          <b-col sm md="auto">
            <button class="btn btn-lg btn-success letter-btn" @click="cancel()">
              <i v-if="letterSynchronizing" class="fa fa-circle-o-notch fa-spin "></i>
              <i v-else class="fa fa-trash"></i>
                  Annuler
            </button>
          </b-col>
        </template>
        <template v-else>
          <b-col sm class="p-md-0 mb-2 mb-md-0">
            <button class="btn btn-lg btn-warning letter-btn" v-show="!letterSent" :class="{'disabled': !canSendLetter}" @click="sendLetter()">
              <i v-if="letterSynchronizing" class="fas fa-circle-notch fa-spin"></i>
              <i v-else class="fas fa-paper-plane"></i>
              &nbsp;&nbsp;Envoyer
              <span v-if="letter.price > 0 && !letterPriceRefreshing">
                  <strong>({{ letter.price | formatFloat }} €)</strong>
              </span>
            </button>
          </b-col>
          <b-col sm md="auto">
            <button class="btn btn-lg btn-warning letter-btn" v-show="!letterSent" :class="{'disabled': !canSendLetter}" @click="sendLetterLater()">
              <i v-if="letterSynchronizing" class="fas fa-circle-notch fa-spin"></i>
              <i v-else class="fa fa-calendar"></i>
              &nbsp;&nbsp;Programmer l'envoi
              <span v-if="letter.price > 0 && !letterPriceRefreshing">
                  <strong>({{ letter.price | formatFloat }} €)</strong>
              </span>
            </button>
          </b-col>
        </template>

        <modal name="send-modal"
               width="70%"
               height="auto"
               :adaptive="true"
               :resizable="false"
               :scrollable="true"
               :pivotY="pivotY"
               @closed="backgroundClosePopup">
            <div class="send-modal-content">
                <confirmation-popup :needsScheduling="needsScheduling" :closePopup="closePopup" :closePopupAfterSending="closePopupAfterSending" :reScheduling="reScheduling"></confirmation-popup>
            </div>
        </modal>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {LetterStep} from '../../../constants/Letter'
import ConfirmationPopup from './ConfirmationPopup/ConfirmationPopup.vue'

export default {
  components: {
    'confirmation-popup': ConfirmationPopup
  },
  data () {
    return {
      pivotY: 0.4,
      needsScheduling: false,
      reScheduling: false
    }
  },
  computed: {
    ...mapGetters('letter', [
      'letterSynchronizing',
      'letterPriceRefreshing',
      'canSendLetter',
      'letterSent',
      'letterProgrammedPreview',
      'letterCanceled'
    ]),
    ...mapState({
      letterStep: state => state.letter.step,
      loading: state => state.loading,
      letter: state => state.letter
    })
  },
  watch: {
    letterStep () {
      if (this.letterStep === LetterStep.PREVIEW_PAGE) {
        this.$modal.show('send-modal')
      }
    }
  },
  methods: {
    ...mapActions('letter', [
      'editLetterAfterPreview',
      'confirmContactRegionAndSynchronizeLetter',
      'reschedulingLetter',
      'cancelLetter'
    ]),
    cancel () {
      this.cancelLetter();
      this.$router.push({name: 'shelled_letters_list'});
    },
    sendLetter () {
      if (this.canSendLetter) {
        this.needsScheduling = false
        this.confirmContactRegionAndSynchronizeLetter()
      }
    },
    sendLetterLater () {
      if (this.canSendLetter) {
        this.needsScheduling = true
        this.confirmContactRegionAndSynchronizeLetter()
      }
    },
    rescheduleLetter () {
      this.reschedulingLetter()
      this.needsScheduling = true
    },
    backgroundClosePopup () {
      this.editLetterAfterPreview()
    },
    closePopup () {
      this.$modal.hide('send-modal')
      this.editLetterAfterPreview()
    },
    closePopupAfterSending () {
      this.$modal.hide('send-modal')
      this.$router.push('/list')
    }
  }
}
</script>

<style scoped>
    .send-modal-content {
        height: 100%;
        box-sizing: border-box;
        overflow: auto;
    }

    .v--modal-overlay {
        z-index: 10;
    }
</style>
