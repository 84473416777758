<template>
    <div>
      <h1 v-if="batchLetter !== null && batchLetter.reason !== null" class="text-center">{{ batchLetter.reason.name }}</h1>
      <div v-if="batchLetter !== null">
        <span class="divider" style="margin-bottom: 20px"></span>
        <div v-if="!readyToUploadCoOwners" class="alert alert-warning">
          <i class="fas fa-exclamation-triangle mr-2"></i>Vous devez saisir les informations de la copropriété avant de pouvoir fournir les documents de l'appel de fonds.
        </div>
        <b-form>
          <b-row>
            <b-col  md="6">
              <b-card
                v-if="batchLetter.coOwnership"
                border-variant="info"
                header="Primary"
                header-bg-variant="info"
                header-text-variant="white"
                header-class="text-center"
                header-tag="header"
              >
                <template v-slot:header>
                  <div class="card-header-big">Copropriété</div>
                </template>
                <co-ownership-form
                  ref="coOwnershipForm"
                  :co-ownership="batchLetter.coOwnership"
                  :co-ownership-id="coOwnershipId"
                  :disable-form="disableForm"
                  @validity="onCoOwnershipFormValidityChange"
                  @update="onCoOwnershipFormFormUpdated"
                  :loading="coOwnershipLoading"
                  :locked="coOwnershipLocked"
                  @lock="lock"
                  @unlock="unlock"></co-ownership-form>
              </b-card>
              </b-col>
            <b-col md="6">
              <b-card
                v-if="batchLetter.reason.withEvent && batchLetter.event"
                border-variant="info"
                header="Primary"
                header-bg-variant="info"
                header-text-variant="white"
                header-class="text-center"
                header-tag="header"
              >
                <template v-slot:header>
                  <div class="card-header-big">Assemblée générale</div>
                </template>
                <a-g-form :event="batchLetter.event" :disable-form="disableForm" @validity="onAgFormValidityChange" @update="onAgFormFormUpdated"></a-g-form>
              </b-card>
            </b-col>
          </b-row>
        </b-form>

        <batch-letter-document-table
          v-if="coOwnersUploaded && (batchLetter.status === BatchLetterStatus.Incomplete || batchLetter.documents.length > 0)"
          :locked="batchLetter.status !== BatchLetterStatus.Incomplete"
          :batchLetterId="batchLetterId">
        </batch-letter-document-table>

        <co-owners-table
          :locked="batchLetter.status !== BatchLetterStatus.Incomplete || !readyToUploadCoOwners"
          :batchLetter="batchLetter"
          :sendLevel="batchLetter.sendLevel"
          :updateCoownership="updateBatchLetter">
        </co-owners-table>

        <modal name="sendConfirmationModal" width="45%" height="auto" :adaptive="true">
          <confirmation-popup :batchLetter="batchLetter"></confirmation-popup>
        </modal>

        <b-card
          border-variant="info"
        >
          <router-link :to="{ name: backUrl()}" class="btn btn-lg btn-default btn-warning"><i class="fa fa-chevron-left"></i> Retour</router-link>
          <b-button class="float-right" variant="warning" size="lg" :disabled="!readyToBeSent" v-if="!letterSent" @click="sendLetter"><i class="fas fa-paper-plane"></i>  Tarif et envoi</b-button>
          <b-button class="float-right" variant="danger" size="lg" v-if="letterCancelable" @click="cancelLetter"><i class="fa fa-times"></i>  Annuler</b-button>
        </b-card>
        <br/><br/>
    </div>
    </div>
</template>

<script>
import ConfirmationPopup from '@/components/ConfirmationPopup'
import CoOwnersTable from '../../components/CoOwnersTable'
import BatchLetterDocumentTable from '../../components/BatchLetterDocumentTable'
import api from '../../api/index'
import {BatchLetterStatus} from '@/service/batchletter'
import {EventBus} from '../../EventBus'
import authService from '@/service/auth'
import AGForm from '@/components/AGForm'
import CoOwnershipForm from '@/components/form/CoOwnershipForm'
import moment from "moment";

export default {
  name: 'batch-letter-details',
  components: {
    CoOwnershipForm,
    CoOwnersTable,
    BatchLetterDocumentTable,
    ConfirmationPopup,
    AGForm
  },
  data () {
    return {
      BatchLetterStatus: BatchLetterStatus,
      batchLetterId: null,
      batchLetter: null,
      disableForm: false,
      agFormValidity: false,
      coOwnershipFormValidity: false,
      coOwnershipId: null,
      coOwnershipLoading: true,
      coOwnershipLocked: true
    }
  },
  watch: {
    '$route.params': {
      handler() {
        let reason = this.$route.params.reason
        let batchLetterId = parseInt(this.$route.params.id)

        EventBus.$emit('showLoading')

        if (batchLetterId && batchLetterId !== this.batchLetterId) {
          this.batchLetterId = batchLetterId
          this.updateBatchLetter()
        } else if (reason) {
          api.postBatchLetter({reason: reason}).then(
            response => {
              this.$router.replace({
                name: 'batchletters_detail',
                params: { id: response.data.id }
              })
              this.batchLetterId = response.data.id
              this.updateBatchLetter()
              EventBus.$emit('hideLoading')
            }).catch(
            error => {
              if (!authService.redirectToLoginIfNotLogged(error.response)) {
                EventBus.$emit('hideLoading')
                this.$swal('Impossible de créer une assemblée générale', 'Erreur', 'error')
                this.$router.push({name: 'home'})
              }
            }
          )
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    readyToBeSent () {
      let hasDocument = Object.keys(this.batchLetter.priceTtc).length > 0
      return !this.letterSent && hasDocument && this.coOwnersUploaded && this.batchLetter.coOwnersValid && this.agFormValidity && this.coOwnershipFormValidity
    },
    readyToUploadCoOwners() {
      return this.batchLetter.coOwnership.reference || this.batchLetter.reason.key !== 'APF'
    },
    letterSent () {
      if (this.batchLetter !== null) {
        return this.batchLetter.status !== BatchLetterStatus.Incomplete
      } else {
        return null
      }
    },
    letterCancelable () {
      if (this.batchLetter !== null) {
        return this.batchLetter.status === BatchLetterStatus.Sent && moment().isBefore(this.batchLetter.programmedDate)
      } else {
        return null
      }
    },
    coOwnersUploaded () {
      return this.batchLetter.coOwnerCount > 0
    },

    documentsUploaded () {
      return this.batchLetter.documents !== undefined && this.batchLetter.documents.length > 0
    }
  },
  created () {
  },
  methods: {
    onAgFormValidityChange (val) {
      this.agFormValidity = val
    },
    onCoOwnershipFormValidityChange (val) {
      this.coOwnershipFormValidity = val
    },
    onCoOwnershipFormFormUpdated () {
      let formData = {
        'coOwnership': this.batchLetter.coOwnership
      }
      api.putBatchLetter(this.batchLetter.id, formData)
    },
    onAgFormFormUpdated () {
      let formData = {
        'event': this.batchLetter.event
      }
      api.putBatchLetter(this.batchLetter.id, formData)
    },
    sendLetter () {
      this.$modal.show(ConfirmationPopup, {
        batchLetter: this.batchLetter
      }, {
        width: '45%',
        height: 'auto',
        adaptive: true,
        scrollable: true,
      }, {
        closed (event) {
          EventBus.$emit('document_uploaded_event')
        }
      })
    },
    backUrl () {
      return 'batchletters_all_sending_list'
    },
    cancelLetter () {
      api.putBatchLetterCancel(this.batchLetter.id)
        .then(response => {
          this.$router.push({
            name: this.backUrl()
          })
        })
        .catch(
          error => {
            if (!authService.redirectToLoginIfNotLogged(error.response)) {
              console.log(error.message)
            }
          }
        )
    },
    updateBatchLetter () {
      api.getBatchLetter(this.batchLetterId)
        .then(response => {
          this.coOwnershipLocked = response.data.coOwnershipReused ?? false;
          if (this.coOwnershipLocked) this.coOwnershipFormValidity = true;

          if (response.data.parentLetter) {
            this.disableForm = true
          } else {
            this.disableForm = response.data.status !== BatchLetterStatus.Incomplete
          }

          if (!response.data.reason.withEvent) {
            this.agFormValidity = true
          }

          if (!this.disableForm) {
            let coOwnershipDetails = {
              'name': null,
              'reference': null,
              'addresses': [{
                'address': null,
                'postcode': null,
                'city': null
              }]
            }
            if (response.data.coOwnership) {
              this.coOwnershipId = response.data.coOwnership.id ?? null
              coOwnershipDetails.name = (response.data.coOwnership.name) ? response.data.coOwnership.name : null
              coOwnershipDetails.reference = (response.data.coOwnership.reference) ? response.data.coOwnership.reference : null

              if (response.data.coOwnership.addresses[0]) {
                coOwnershipDetails.addresses[0].address =  (response.data.coOwnership.addresses[0].address) ? response.data.coOwnership.addresses[0].address : null
                coOwnershipDetails.addresses[0].postcode =  (response.data.coOwnership.addresses[0].postcode) ? response.data.coOwnership.addresses[0].postcode : null
                coOwnershipDetails.addresses[0].city =  (response.data.coOwnership.addresses[0].city) ? response.data.coOwnership.addresses[0].city : null
              }
            }
            response.data.coOwnership = coOwnershipDetails

            let eventDetails = {
              'date': null,
              'address': {
                'address': null,
                'postcode': null,
                'city': null
              }
            }
            if (response.data.event) {
              eventDetails.date = (response.data.event.date) ? response.data.event.date : null

              if (response.data.event.address) {
                eventDetails.address =  (response.data.event.address ) ? response.data.event.address : null
                eventDetails.postcode =  (response.data.event.postcode) ? response.data.event.postcode : null
                eventDetails.city =  (response.data.event.city) ? response.data.event.city : null
              }
            }
            response.data.event = eventDetails
          }

          this.coOwnershipLoading = false;
          this.batchLetter = response.data

          EventBus.$emit('hideLoading')

        })
        .catch(
          error => {
            EventBus.$emit('hideLoading')
            if (!authService.redirectToLoginIfNotLogged(error.response)) {
              EventBus.$emit('hideLoading')
              this.$swal('Impossible de charger cette assemblée générale', 'Erreur', 'error')
              this.$router.push({name: 'home'})
            }
          }
        )
    },
    lock (id) {
      this.coOwnershipLoading = true;
      api.postLinkCoOwnership(this.batchLetterId, id)
        .then(response => {
          this.updateBatchLetter()
        })
        .catch(
          error => {
            if (!authService.redirectToLoginIfNotLogged(error.response)) {
              console.log(error.message)
            }
          }
        )
    },
    unlock () {
      this.coOwnershipLoading = true;
      api.postUnlinkCoOwnership(this.batchLetterId)
        .then(response => {
          this.updateBatchLetter()
        })
        .catch(
          error => {
            if (!authService.redirectToLoginIfNotLogged(error.response)) {
              console.log(error.message)
            }
          }
        )
    }
  }
}
</script>
