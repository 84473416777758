
import store from '../../store'
import {types} from '../../store/module/letter/mutation-types'
import api from '../../api/paperletter'
import {RecipientState} from '../../constants/Recipient'

class RefreshInformationsService {
  refreshPrice (commit) {
    commit(types.SET_PRICE_REFRESHING)
    api.getLetterPrice(store.state.letter.id).then(
      response => {
        commit(types.REFRESH_PRICE, response.data)
      },
      error => {
        commit(types.REFRESH_PRICE, null)
      }
    )
  }

  refreshPriceIfNeeded (commit, state) {
    let hasDocuments = state.documents.some(document => document.normalized)
    if (hasDocuments) {
      this.refreshPrice(commit)
    }
  }

  refreshPages (commit, state) {
    commit(types.SET_PAGES_REFRESHING)
    api.getLetter(store.state.letter.id).then(
      response => {
        commit(types.REFRESH_PAGES, response.data)
        if (state.contactRegionRefreshing) {
          this.refreshRecipientIfNeeded(commit, state)
        }
      },
      error => {
        commit(types.REFRESH_PAGES, null)
      }
    )
  }

  refreshRecipientIfNeeded (commit, state) {
    if (state.sendMode && !state.sendMode.senderAvailable && !state.isAddressPageNeeded) {
      commit(types.PREFILL_RECIPIENT, {data: null, prefill: false})
      commit(types.REFRESH_CONTACT_REGION)
    } else {
      let preFiledState = state.sendMode && state.sendMode.senderAvailable &&
                ((state.recipient.status === RecipientState.EDITING && state.recipient.fullname == null && state.recipient.address == null) ||
                    (state.recipient.status === RecipientState.VALID && state.recipient.fullname === null && state.recipient.address === null))

      if (preFiledState && !state.isAddressPageNeeded) {
        this.refreshRecipient(commit, state)
      }
    }
  }

  refreshRecipient (commit, state) {
    if (!state.isAddressPageNeeded) {
      let contactDetected = state.contactRegion && state.contactRegion.contactDetected
      let prefillForm, postcode, city

      if (contactDetected) {
        prefillForm = true
        postcode = state.contactRegion.postcode
        city = state.contactRegion.city
      } else {
        prefillForm = false
        postcode = null
        city = null
      }

      let data = {
        fullname: null,
        address: null,
        postcode: postcode,
        city: city
      }

      commit(types.PREFILL_RECIPIENT, {data: data, prefill: prefillForm})
      commit(types.REFRESH_CONTACT_REGION)
    }
  }
}

export default new RefreshInformationsService()
