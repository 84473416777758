import UserAPI from '@/api/user'
import jwtDecode from 'jwt-decode'
import moment from "moment";

const AUTHENTICATING = 'AUTHENTICATING'
const AUTHENTICATING_SUCCESS = 'AUTHENTICATING_SUCCESS'
const AUTHENTICATING_FAILURE = 'AUTHENTICATING_FAILURE'
const LOGOUT = 'LOGOUT'

export default {
  namespaced: true,
  state: {
    inProgress: false,
    isAuthenticated: false,
    user: null,
    jwt: null
  },
  getters: {
    getUser (state) {
      return state.user
    },
    getUsername (state) {
      return state.user.email
    },
    getName (state) {
      if (state.user !== null) {
        return state.user.commercialName
      } else {
        return null
      }
    },
    getCreditValue (state) {
      if (state.user && state.user.credit) {
        return state.user.credit.value
      } else {
        return 0.0
      }
    },
    isAuthenticated (state) {
      return state.isAuthenticated
    },
    isAuthenticating (state) {
      return state.inProgress
    },
    isAdminView (state) {
      if (state.user !== null) {
        return state.user.roles.includes('ROLE_ADMIN')
      } else {
        return false
      }
    },
    isShelledLetterAllowed (state) {
      if (state.user !== null) {
        return state.user.roles.includes('ROLE_ADMIN') || state.user.roles.includes('ROLE_PAPER_LETTER')
      } else {
        return false
      }
    },
    expireDate (state) {
      let epoch = state.user.exp;
      let expireDate = moment.unix(epoch)
      return expireDate
    },
    needsRefresh (state) {
      let epoch = state.user.exp;
      let expireDate = moment.unix(epoch)

      let futureDate = moment().add(15, 'minutes')
      // return expireDate.isBefore(futureDate)
      return true
    }
  },
  mutations: {
    [AUTHENTICATING_SUCCESS] (state, data) {
      state.isAuthenticated = true
      state.user = jwtDecode(data.token)
      state.jwt = data.token
      state.inProgress = false
    },
    [AUTHENTICATING] (state) {
      state.inProgress = true
    },
    [AUTHENTICATING_FAILURE] (state) {
      state.inProgress = false
      state.isAuthenticated = false
      state.user = null
      state.jwt = null
    },
    [LOGOUT] (state) {
      state.isAuthenticated = false
      state.user = null
      state.jwt = null
    }
  },
  actions: {
    async login ({ commit }, {login, password}) {
      sessionStorage.clear()
      commit(AUTHENTICATING)
      try {
        let response = await UserAPI.login(login, password)
        commit(AUTHENTICATING_SUCCESS, response.data)
        return response
      } catch (error) {
        commit(AUTHENTICATING_FAILURE, error)
      }
    },

    async logout ({ commit }) {
      try {
        sessionStorage.clear()
        let response = await UserAPI.logout()
        commit(LOGOUT)
        return response
      } catch (error) {
        console.log(error)
      }
    },
    checkLogin ({ commit }) {
      commit(AUTHENTICATING)
      let promise = UserAPI.refreshJwtToken()

      promise.then(
        (response) => {
          commit(AUTHENTICATING_SUCCESS, response.data)
        }
      ). catch(
        () => {
          commit(AUTHENTICATING_FAILURE)
        }
      )
      return promise
    },
  }
}
