<template>
    <div class="card send-bar" :class="[boxColor]">
        <div class="card-body">
          <b-row>
            <b-col class="col-12 col-md-4  mb-2 mb-md-0">
              <router-link class="btn btn-lg btn-danger letter-btn" to="/shelled_letters"><i class="fa fa-chevron-left"></i>&nbsp;Retour à la liste</router-link>
            </b-col>
            <b-col class="text-md-right">
              <send-button></send-button>
            </b-col>
          </b-row>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import SendButton from './SendButton.vue'

export default {
  components: {
    'send-button': SendButton
  },
  computed: {
    ...mapState('letter', {
      letter: state => state
    }),
    ...mapGetters('letter', [
      'letterPriceRefreshing',
      'canSendLetter'
    ]),
    boxColor () {
      if (this.canSendLetter) {
        return 'card-success'
      } else {
        return 'card-warning'
      }
    }
  }
}
</script>

<style scoped>
    input[type=radio] {
        display: none;
    }

    .send-bar {
        /*position: fixed;*/
        /*z-index: 100;*/
        bottom: 0;
        left: 0%;
        width: 100%;
        margin-bottom: 0px !important;
    }

    .price {
        font-size: 22px;
        margin-top: 10px;
        margin-left: 5px;
    }
</style>
