<template>
  <div v-if="user !== null">
    <b-container>
      <b-row>
        <b-col sm="9" offset="2">
          <b-card
            border-variant="info"
            header="Primary"
            header-bg-variant="info"
            header-text-variant="white"
            header-class="text-center card-body-condensed"
            header-tag="header"
          >
            <template v-slot:header>
              <h3>Informations du compte : {{currentEmail}}</h3>
            </template>
            <b-row v-if="isAdminView">
              <b-col>
                 <template v-if="user.credit">
                   Crédit :{{ user.credit.value }} €
                 </template>
                <template v-else>
                  Crédit :0 €
                </template>
                <br>
                Date inscription : {{ user.registrationDate | formatDate }} <br>
                Status : {{ user.status }}
                <router-link :to="{ name: 'billing', params: {id: user.id}}">
                 <b-button variant="success" class="fa-pull-right">
                 <i class="fas fa-euro-sign"></i>&nbsp;Voir Facturation
                 </b-button>
               </router-link>
              </b-col>
              <hr/>
            </b-row>

            <user-profile-form :user="user"></user-profile-form>
            <hr/>
            <h3>Gestion des paramètres de facturation</h3>
            <billable-item-payer-selection :billableItemPayer="user.billableItemPayers"></billable-item-payer-selection>
            <b-row class="my-1" @click="arManagementClicked">
              <b-col sm="6">
                <label> Gestion des AR par OneSyndic</label>
              </b-col>
              <b-col sm="6">
                <b-form-checkbox
                  :disabled="true"
                  v-model="user.arManagement"
                ></b-form-checkbox>
              </b-col>
            </b-row>
            <hr/>

            <template v-if="isAdminView">
              <b-button variant="danger" size="lg" to="/user/list" class="fa-pull-left">
                <i class="fas fa-chevron-left"></i>&nbsp;Retour
              </b-button>
            </template>
            <template v-else>
              <b-button variant="danger" size="lg" to="/batchletters/ag_list" class="fa-pull-left">
                <i class="fas fa-chevron-left"></i>&nbsp;Retour
              </b-button>
            </template>

            <b-button variant="warning" size="lg" @click="updateProfile" class="fa-pull-right">
              <i class="fa fa-check"></i>&nbsp;Valider
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import UserProfileForm from '@/components/user/UserProfileForm'
import BillableItemPayerSelection from '@/components/BillableItemPayerSelection'
import {BillItemType} from '../../constants/BillItemTypePayer'
import user from '@/api/user'
import authService from '@/service/auth'
import {mapGetters} from 'vuex'
import moment from "moment";

export default {
  watch: {
    '$route.params': {
      handler() {
        let userId = parseInt(this.$route.params.id)
        if (userId && userId !== this.userId) {
          this.userId = userId;
        } else {
          this.userId = this.getUser.id
        }
        this.getUserProfile()
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      userId: null,
      user: null,
      currentEmail: null
    }
  },
  filters: {
    formatDate: function (date) {
      return moment(date).format('DD/MM/YYYY')
    }
  },
  components: {
    UserProfileForm,
    BillableItemPayerSelection
  },
  computed : {
    ...mapGetters('user', [
      'isAdminView',
      'getUser'
    ])
  },
  methods: {
    arManagementClicked() {
      this.$swal('Gestion des AR papier', 'Contactez nous pour activer cette fonctionnalité de gestion des AR papier.', 'info')
    },
    getUserProfile () {
      user.getInfo(this.userId).then(
        response => {
          this.user = response.data
          this.currentEmail = this.user.email
        })
        .catch(error => {
          if(!authService.redirectToLoginIfNotLogged(error.response)){
            this.$swal('Erreur de traitement', 'Vérifiez le utilisateur', 'error')
            if (this.isAdminView) {
              this.$router.push({name: 'user_list'})
            } else {
              this.$router.push({name: 'default'})
            }
          }
        })
    },
    updateProfile () {
      this.$validator.validateAll()

      let billableItemPayers = [
        {'payer': this.user.billableItemPayers[BillItemType.BILL_ITEM_TYPE_PAPER_LETTER_SERVICE], 'item_type': BillItemType.BILL_ITEM_TYPE_PAPER_LETTER_SERVICE},
        {'payer': this.user.billableItemPayers[BillItemType.BILL_ITEM_TYPE_PAPER_LETTER_STAMP], 'item_type': BillItemType.BILL_ITEM_TYPE_PAPER_LETTER_STAMP},
        {'payer': this.user.billableItemPayers[BillItemType.BILL_ITEM_TYPE_SELF_DELIVERY], 'item_type': BillItemType.BILL_ITEM_TYPE_SELF_DELIVERY},
        {'payer': this.user.billableItemPayers[BillItemType.BILL_ITEM_TYPE_LRE], 'item_type': BillItemType.BILL_ITEM_TYPE_LRE}
      ]

      let formData = {
        commercialName: this.user.commercialName,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        email: this.user.email,
        phone: this.user.phone,
        address: this.user.address,
        postCode: this.user.postCode,
        city: this.user.city,
        billableItemPayers: billableItemPayers,
        arManagement: this.user.arManagement
      }

      user.updateProfile(this.user.id, formData).then(
        response => {
          this.$router.push({
            name: 'default'
          })
        }
      ).catch(
        error => {
          if (!authService.redirectToLoginIfNotLogged(error.response)) {
            this.$swal('Erreur de traitement', '', 'error')
          }
        }
      )
    }
  }
}
</script>
