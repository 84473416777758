<template>
  <div>
    <b-row class="my-1">
      <b-col sm="2">
        <label> Type d'envoi : </label>
      </b-col>
      <b-col sm="10">
        <b-form-radio-group
          id="send-type"
          v-model="coOwner.sendType"
          name="send-type"
          v-validate="'required'"
        >
          <b-form-radio :value="sendTypeOptions.Paper"><img height="25" :src="require('../../assets/images/' + coOwnerService.iconForSendType(sendTypeOptions.Paper))" />&nbsp;Papier</b-form-radio>
          <b-form-radio :value="sendTypeOptions.Electronic"><img height="25" :src="require('../../assets/images/' + coOwnerService.iconForSendType(sendTypeOptions.Electronic))" />&nbsp;Électronique</b-form-radio>
          <b-form-radio :value="sendTypeOptions.DontSend"><i class="fas fa-times-circle dont-send-icon"></i>&nbsp;Ne pas envoyer</b-form-radio>
        </b-form-radio-group>
        <div class="text-danger" v-show="errors.has('send-type')">{{ errors.first('send-type') }}</div>
      </b-col>
    </b-row>

    <hr/>

    <b-row class="my-1">
      <b-col sm="2">
        <label> Niveau d'envoi : </label>
      </b-col>
      <b-col sm="8">
        <b-form-radio-group
          v-model="coOwner.sendLevel"
          :options="sendLevelOptions"
        ></b-form-radio-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import coOwnerService, {SendLevel, SendType} from '@/service/coowner'

export default {
  props: {
    coOwner: Object
  },
  inject: ['$validator'],
  data () {
    return {
      coOwnerService: coOwnerService,
      sendLevelOptions: [
        { text: 'Courrier simple', value: SendLevel.Simple },
        { text: 'Courrier confirmé / recommandé', value: SendLevel.Registered }
      ],
      sendTypeOptions: SendType
    }
  }
}
</script>
