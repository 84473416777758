
export const types = {
  SET_SEND_MODE: 'SET_SEND_MODE',
  SET_COLOR: 'SET_COLOR',
  SET_DOUBLE_SIDED_PRINTING: 'SET_DOUBLE_SIDED_PRINTING',
  SET_PROGRAMMED_DATE: 'SET_PROGRAMMED_DATE',
  SET_LETTER: 'SET_LETTER',
  RESET_LETTER: 'RESET_LETTER',
  SET_LETTER_ID: 'SET_LETTER_ID',
  REFRESH_PRICE: 'REFRESH_PRICE',
  SET_PRICE_REFRESHING: 'SET_PRICE_REFRESHING',
  REFRESH_PAGES: 'REFRESH_PAGES',
  SET_PAGES_REFRESHING: 'SET_PAGES_REFRESHING',
  SET_LETTER_STEP: 'SET_LETTER_STEP',
  SET_ADDRESSPAGE_NEEDED_MODE: 'SET_ADDRESSPAGE_NEEDED_MODE',

  DOCUMENT_UPLOADING: 'DOCUMENT_UPLOADING',
  DOCUMENT_UPLOADING_UPDATE_PROGRESS: 'DOCUMENT_UPLOADING_UPDATE_PROGRESS',
  DOCUMENT_UPLOADED: 'DOCUMENT_UPLOADED',
  DOCUMENT_ERROR: 'DOCUMENT_ERROR',
  REMOVE_DOCUMENT: 'REMOVE_DOCUMENT',
  DOCUMENT_REMOVING: 'DOCUMENT_REMOVING',
  DOCUMENT_REMOVING_ERROR: 'DOCUMENT_REMOVING_ERROR',
  ADD_DOCUMENT: 'ADD_DOCUMENT',
  STAPLING_CHANGING: 'STAPLING_CHANGING',
  STAPLING_CHANGE_FINISHED: 'STAPLING_CHANGE_FINISHED',
  STAPLING_CHANGE_ERROR: 'STAPLING_CHANGE_ERROR',
  REORDERING_CHANGING: 'REORDERING_CHANGING',
  REORDERING_ERROR: 'REORDERING_ERROR',
  REORDERING_FINISHED: 'REORDERING_FINISHED',

  UPDATE_CONTACT_FIELD: 'UPDATE_CONTACT_FIELD',
  SET_CONTACT_STATUS: 'SET_CONTACT_STATUS',
  RESET_CONTACT: 'RESET_CONTACT',
  REUSE_ACCOUNT: 'REUSE_ACCOUNT',

  SET_CONTACT_REGION_REFRESHING: 'SET_CONTACT_REGION_REFRESHING',
  REFRESH_CONTACT_REGION: 'REFRESH_CONTACT_REGION',
  UPDATE_CONTACT_REGION: 'UPDATE_CONTACT_REGION',
  PREFILL_RECIPIENT: 'PREFILL_RECIPIENT'
}
