<template>
  <div id="page-container">
    <div id="content-wrap">
      <content-header></content-header>
      <dashboard-content></dashboard-content>
    </div>
    <content-footer></content-footer>
  </div>
</template>
<script>
import ContentHeader from '../shared/ContentHeader.vue'
import ContentFooter from '../shared/ContentFooter.vue'
import DashboardContent from '../shared/Content.vue'

export default {
  components: {
    ContentHeader,
    ContentFooter,
    DashboardContent
  }
}
</script>
