import Router from '../router'
import Vuex from '../store'
import {swal} from 'vue-swal'
import user from '../store/module/user'

export default {
  redirectToLoginIfNotLogged (response) {
    if (response) {
      if (!response.status || response.status === 401 || response.status === 403) {
        Vuex.dispatch('user/logout')
        Router.push({
          path: '/login',
          query: { redirect: Router.currentRoute.path }
        })
        swal('Déconnexion', 'Suite à une trop longue inactivité, vous avez été déconnecté. Vous retrouverez la dernière page consultée après connexion.', 'error')

        return true
      }
    }

    return false
  },
  getBearerHeader () {
    let token = user.state.jwt

    if (token) {
      return `Bearer ${ token }`
    } else {
      return null
    }
  }
}
