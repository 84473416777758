<template>
  <b-card
    header-tag="header"
    border-variant="info"
    header="Primary"
    header-bg-variant="info"
    header-text-variant="white"
    header-class="text-center"
    body-class="card-body-condensed"
  >
    <template v-slot:header>
      <div class="card-header-big float-left">Destinataires</div>
      <div v-if="!locked">
        <select v-if="isAdminView" v-model="format">
          <label>Format des fichiers</label>
          <option id="default" :value="null" selected disabled hidden>Choisir un format</option>
          <option id="format-netsyndic" value="NETSYNDIC" selected="selected">AtHome / Netsyndic</option>
          <option id="format-mipalpes" value="MIPALPES">MipAlpes (ZIP)</option>
          <option id="format-manual-paraz" value="MANUAL_PARAZ">Paraz manuel</option>
        </select>

        <label class="input-label btn btn-warning float-right btn-import file-select" v-bind:class="{ disabled: isUploading || locked || (isAdminView && !format) }" v-if="!hasCoOwners" >
          <template v-if="!isUploading">
            <i class="fas fa-user-friends"></i>
            Importer des destinataires
            <input type="file" id="recipient-csv-input" @change="uploadCoOwnerCsv" :disabled="isUploading || locked || (isAdminView && !format)">
          </template>
          <template v-else>
            <b-spinner small></b-spinner>
            <span>Traitement...</span>
          </template>
        </label>
        <template v-else>
          <label class="input-label btn btn-danger btn-import float-right" @click="deleteCoOwners">
            <i class="fas fa-trash"></i>
            Supprimer la liste
          </label>
        </template>
        <label class="input-label btn btn-warning float-right btn-import file-select" @click="addCoOwner">
          <i class="fas fa-user-plus"></i>&nbsp;Ajouter manuellement
        </label>

      </div>
    </template>
    <div>
      <div class="vld-parent">
        <loading :isLoading="isLoading"></loading>
        <label v-if="globalSendModeSwitchAllowed" class="input-label btn btn-success float-left" @click="switchSendLevelAllCoowners">
          <i class="fas fa-exchange-alt"></i>
          Passer tous les destinataires en
          <span class="font-weight-bold" v-if="batchLetter.sendLevel === 'SIMPLE'">recommandé</span>
          <span class="font-weight-bold" v-else>envoi simple</span>
        </label>
        <v-server-table :columns="columns" :options="tableOptions()" ref="coOwnerTable" :name="'coOwnerTable_' + batchLetter.id" @loaded="onTableLoaded" @loading="onTableLoading" >
          <template slot="fullName" slot-scope="props">
            <i class="fas fa-exclamation-triangle float-right dont-send-icon" v-if="!props.row.isCoherent"></i>

            <div class="font-weight-bold">
              <template v-if="props.row.isCompany">
                {{props.row.companyName}}<br/>
              </template>
              <template v-else>
                {{ props.row.gender }} {{props.row.lastName}} {{props.row.firstName}}<br/>
              </template>
            </div>
            <div v-if="props.row.sendType !== SendType.Electronic">
              <span v-for="address in props.row.address.address.split('\n')" v-bind:key="address">{{ address }}<br/></span>
              {{props.row.address.postcode}} {{props.row.address.city}}
              <span v-if="props.row.address.country !== 'FRANCE'"><br/>{{ props.row.address.country }}</span>
            </div>

            <div v-else>
              <span v-if="props.row.email">{{props.row.email}}</span><span v-else>Email : --- </span><br/>
              <span v-if="props.row.phone">{{props.row.phone}}</span><span v-else>Téléphone : --- </span><br/>
            </div>
          </template>
          <template  slot="reference" slot-scope="props">
            <router-link :to="{ name: 'batchletters_coowners_detail', params: {batchLetterId: batchLetter.id, coOwnerId: props.row.id}}"><i class="fas fa-envelope"></i>&nbsp;{{ props.row.reference }}</router-link>
            <span v-if="props.row.totalPageCount > 0">
              <br/>
              {{ props.row.totalPageCount }} Page(s) / {{ props.row.printablePageCount }} Feuille(s)
            </span>
          </template>
          <template slot="sendType" slot-scope="props">
            <span>
              <template  v-if="coOwnerService.iconForSendType(props.row.sendType) !== undefined">
                  <img
                    height="35"
                    :src="require('../assets/images/' + coOwnerService.iconForSendType(props.row.sendType))"
                  />&nbsp;
              </template>
              <template v-else>
                  <i class="fas fa-times-circle dont-send-icon"></i>
              </template>

              <a v-if="props.row.trackingNumber" :href="deliveryService.urlForSendType(props.row.sendType, props.row.trackingNumber)">
                {{ coOwnerService.getTextForSendTypeAndLevel(props.row.sendType,  props.row.sendLevel, batchLetter) }} n°{{props.row.trackingNumber}}
              </a>
              <span v-else >
                {{ coOwnerService.getTextForSendTypeAndLevel(props.row.sendType, props.row.sendLevel, batchLetter) }}
                <br/>
                <b-link v-if="props.row.delivery === undefined && !locked" @click="showSendModeOptionsPopup(props.row)">
                  <i class="fas fa-exchange-alt"></i>&nbsp;Modifier le mode d'envoi
                </b-link>
              </span>
            </span>
          </template>
          <template slot="status" slot-scope="props">
            <span  v-if="props.row.isCoherent || locked">
              {{ deliveryService.getTextForStatus(props.row.status, isAdminView) }}
            </span>
            <span v-else>
                Informations incohérentes :
                <ul>
                  <li v-for="reason in props.row.invalidReasons" v-bind:key="reason">
                    {{coOwnerService.getTextInvalidationReason(reason) }}
                  </li>
                </ul>
              <b-button class="btn-warning" block @click="showCoOwnerEditPopup(props.row)">
                <i class="fas fa-edit"></i>&nbsp;Corriger
              </b-button>
            </span>
          </template>
          <template slot="actions" slot-scope="props">
            <button class="btn btn-sm btn-warning mb-1 btn-block" @click="showCoOwnerEditPopup(props.row)" v-if="!locked"> <i class="fas fa-edit"></i>&nbsp;Modifier</button>
            <div class="file-select" v-if="!locked">
              <button class="btn btn-warning btn-sm" @click="chooseFile(props.row.id)">
                <i class="fas fa-file-download"></i><span>&nbsp;Document supplémentaire</span>
              </button>
              <input type="file"
                     name="input_document[file]"
                     :ref="'fileUploadInput_' + props.row.id"
                     @change="addCoOwnerDocument($event, props.row.id)"
              />
            </div>
          </template>
        </v-server-table>
      </div>
    </div>
  </b-card>
</template>

<script>
import api from '../api'
import {EventBus} from '../EventBus'
import { mapGetters } from 'vuex'
import coOwnerService, {SendLevel, SendType} from '@/service/coowner'
import deliveryService, {DeliveryStatus, Status} from '@/service/delivery'
import SendModeOptionsPopup from '@/components/SendModeOptionsPopup'
import CoOwnerEditPopup from '@/components/coowner/CoOwnerEditPopup'
import CoOwnerAddPopup from '@/components/coowner/CoOwnerAddPopup'
import authService from '@/service/auth'
import Loading from '@/components/Loading'
import sendMode from "@/components/shelled_letter/NewLetterPage/LetterOptions/SendMode";
import {BatchLetterReason} from '../service/batchLetterReason'

export default {
  props: {
    batchLetter: Object,
    locked: Boolean,
    updateCoownership: {
      type: Function
    },
  },
  components: {
    Loading
  },
  mounted () {
    EventBus.$on('document_uploaded_event', (documentUploaded) => {
      if (this.$refs.coOwnerTable !== undefined) {
        this.$refs.coOwnerTable.getData()
      }
    })
  },
  computed: {
    ...mapGetters('user', [
      'isAdminView'
    ]),
    hasCoOwners () {
      return this.batchLetter.coOwnerCount > 0
    },
    globalSendModeSwitchAllowed() {
      return this.hasCoOwners
        && (this.batchLetter.reason.key === BatchLetterReason.KEY_PV || this.batchLetter.reason.key === BatchLetterReason.KEY_OTHER)
        && !(this.isUploading || this.locked)
    }
  },
  data () {
    return {
      SendType: SendType,
      SendLevel: SendLevel,
      showContactPageOption: false,
      isUploading: false,
      columns: ['reference', 'fullName', 'sendType', 'status', 'actions'],
      coOwnerService: coOwnerService,
      deliveryService: deliveryService,
      isLoading: false,
      format: null,
    }
  },
  methods: {
    chooseFile (id) {
      let refName = 'fileUploadInput_' + id
      this.$refs[refName].click()
    },
    tableOptions () {
      return {
        texts: {
          defaultOption: 'Tous'
        },
        requestFunction: function (data) {
          let letterId = this.$route.params.id
          return api.getCoOwnerList(letterId, data)
            .catch(function (e) {
              if (!authService.redirectToLoginIfNotLogged(e.response)) {
                this.dispatch('error', e)
              }
            }.bind(this))
        },
        requestAdapter (data) {
          return {
            limit: data.limit,
            page: data.page,
            sort: data.orderBy ? data.orderBy : 'lastName',
            orderBy: data.ascending ? 'asc' : 'desc',
            filters: {
              name: data.fullName,
              reference: data.reference,
              status: data.status,
              sendType: [SendType.Electronic, SendType.Paper, SendType.Manual, SendType.DontSend].indexOf(data.sendType) >= 0 ? data.sendType : '',
              sendLevel: [SendLevel.Simple, SendLevel.Registered].indexOf(data.sendType) >= 0 ? data.sendType : ''
            }
          }
        },
        filterByColumn: true,
        customFilters: ['reference', 'fullName', 'sendType', 'status'],
        responseAdapter (resp) {
          const data = []
          for (let row of resp.data.result) {
            data.push({
              'id': row['id'],
              'gender': row['contact'] !== undefined ? row['contact']['gender'] : undefined,
              'lastName': row['contact'] !== undefined ? row['contact']['lastName'] : undefined,
              'firstName': row['contact'] !== undefined ? row['contact']['firstName'] : undefined,
              'email': row['contact'] !== undefined ? row['contact']['email'] : undefined,
              'phone': row['contact'] !== undefined ? row['contact']['phone'] : undefined,
              'address': row['contact'] !== undefined ? row['contact']['address'] : undefined,
              'sendType': row['sendType'],
              'trackingNumber': row['delivery'] !== undefined ? row['delivery']['trackingNumber'] : undefined,
              'status': row['delivery'] !== undefined ? row['delivery']['status'] : undefined,
              'reference': row['reference'],
              'sendLevel': row['sendLevel'],
              'delivery': row['delivery'],
              'isCompany': row['isCompany'],
              'companyName': row['contact'] !== undefined ? row['contact']['companyName'] : undefined,
              'isCoherent': row['coherent'],
              'invalidReasons': row['invalidReasons'],
              'totalPageCount': row['totalPageCount'],
              'printablePageCount': row['printablePageCount']
            })
          }

          return {
            data: data,
            count: resp.data.count
          }
        },
        listColumns: this.listColumns(),
        headings: {
          reference: 'Référence',
          fullName: 'Copropriétaire',
          sendType: 'Mode d\'envoi',
          status: 'Statut',
          actions: 'Actions'
        },
        filterable: ['reference', 'fullName', 'sendType', 'status'],
        sortable: ['id', 'firstname', 'lastname'],
        saveState: true,
        storage: 'session',
        rowClassCallback (row) {
          return (!row.isCoherent) ? 'table-danger' : ''
        }
      }
    },
    listColumns () {
      let statusArray = [
        { id: DeliveryStatus.NotSent, text: deliveryService.getTextForStatus(DeliveryStatus.NotSent) },
        { id: DeliveryStatus.Submitted, text: deliveryService.getTextForStatus(DeliveryStatus.Submitted) },
        { id: DeliveryStatus.Deposit, text: deliveryService.getTextForStatus(DeliveryStatus.Deposit) },
        { id: DeliveryStatus.Expired, text: deliveryService.getTextForStatus(DeliveryStatus.Expired) },
        { id: DeliveryStatus.Delivered, text: deliveryService.getTextForStatus(DeliveryStatus.Delivered) },
        { id: DeliveryStatus.Returned, text: deliveryService.getTextForStatus(DeliveryStatus.Returned) },
        { id: DeliveryStatus.Canceled, text: deliveryService.getTextForStatus(DeliveryStatus.Canceled) }
      ]

      if (!this.batchLetter.coOwnersValid) {
        statusArray.unshift({ id: Status.Incoherent, text: deliveryService.getTextForStatus(Status.Incoherent) })
      }

      if (this.isAdminView) {
        statusArray.push({ id: DeliveryStatus.Error, text: deliveryService.getTextForStatus(DeliveryStatus.Error, this.isAdminView) })
      }
      return {
        status: statusArray,
        sendType: [
          { id: SendType.Paper, text: 'Papier' },
          { id: SendType.Electronic, text: 'Électronique' },
          { id: SendType.Manual, text: 'Remise en main propre' },
          { id: SendType.DontSend, text: 'Ne pas envoyer' },
          { id: SendLevel.Registered, text: 'Confirmé / Recommandé' },
          { id: SendLevel.Simple, text: 'Envoi simple' }
        ]
      }
    },
    deleteCoOwners () {
      api.deleteCoOwners(this.batchLetter.id).then(response => {
        this.$swal('Suppression terminée', '', 'success')
        this.$refs.coOwnerTable.refresh()
        this.isUploading = false
      }).catch(
        error => {
          if (!authService.redirectToLoginIfNotLogged(error.response)) {
            console.log(error.message)
            this.$swal('Erreur de lors de la suppression', 'Erreur lors de la suppression', 'error')
            this.isUploading = false
          }
        }
      )
    },
    onTableLoaded (data) {
      this.showContactPageOption = this.hasCoOwners && data.data.batchLetterDocumentCount > 0
      this.updateCoownership()
      this.isLoading = false
    },
    onTableLoading(){
      this.isLoading = true
    },
    addCoOwnerDocument (event, coOwnerId) {
      let file = event.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = () => {
        let json = {
          file: reader.result,
          name: file.name
        }

        event.target.value = ''

        api.postCoOwnerDocument(this.batchLetter.id, coOwnerId, json)
          .then(response => {
            EventBus.$emit('document_uploaded_event')
            this.$swal('Document importé avec succès', '', 'success')
          }).catch(error => {
            this.$swal('Impossible d\'importer le document', '', 'error')
        })
      }
    },
    uploadCoOwnerCsv (event) {
      this.isUploading = true
      let file = event.target.value
      if (event.target.files !== undefined) {
        file = event.target.files[0]
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        if (e.target) {
          let formData = new FormData()

          if (reader.result) {
            formData.append('file', reader.result.toString())
            formData.append('name', file.name)
            if (this.isAdminView && this.format !== null) {
              formData.append('format', this.format)
            }

            api.postCoOwnerImport(this.batchLetter.id, formData).then(
              response => {
                this.isUploading = false
                let data = response.data;

                let msg = ''

                if (data.warnings) {
                  msg = 'Avertissements :<br/>';
                  msg += '<ul>';

                  for (const warning of data.warnings) {
                    msg += '<li><b>' + warning + '</b></li>';
                  }

                  msg += '</ul>';
                }

                if (data.invalidCoOwners) {
                  msg += '<br/>Les informations de certains copropriétaires sont nécessaires avant de pouvoir procéder à l\'envoi.<br/>' +
                    'Vous pouvez effectuer les corrections directement depuis la liste (lignes en rouges) ou alors supprimer la liste et importer de nouveau un fichier corrigé.<br/><br/>' +
                    'Voici le détail des erreurs détectées :<br/>'
                  for (let i = 0; i < data.invalidCoOwners.length; i++) {
                    let invalidReasons = data.invalidCoOwners[i].invalidReasons
                    let reasonsList = ''
                    for (let j = 0; j < invalidReasons.length; j++) {
                      reasonsList += '<li><b><i class="fas fa-exclamation-triangle"></i> ' + coOwnerService.getTextInvalidationReason(invalidReasons[j]) + '</b></li>'
                    }

                    msg +=
                      '<li>' +
                      ' Nom : ' + data.invalidCoOwners[i].fullName + ' (' + data.invalidCoOwners[i].reference + ')<br/>' +
                      ' <ul>' + reasonsList + '</ul></li>'
                  }

                  this.$swal({
                    title: 'Correction nécessaire sur certains co-propriétaires',
                    icon: 'warning',
                    className: 'modal-list-msg',
                    content: {
                      element: 'ul',
                      attributes: {
                        innerHTML: `${msg}`
                      }
                    }
                  })
                } else {
                  this.$swal('Traitement terminé avec succès', '', 'success')
                }

                this.$refs.coOwnerTable.refresh()
                this.isUploading = false
              }).catch(
              error => {
                this.isUploading = false
                if (!error.response) {
                  this.$swal('Erreur de traitement', 'Problème de réseau', 'error')
                  return
                }

                if (!authService.redirectToLoginIfNotLogged(error.response)) {
                  let data = error.response.data
                  let errorMsg = ''

                  if (data.errors) {
                    errorMsg = data.errors.children.file.errors[0]
                  } else {
                    errorMsg = 'Vérifiez le fichier'
                  }
                  this.$swal('Erreur de traitement', `${errorMsg}`, 'error')

                  this.isUploading = false
                }
              }
            )
          }
        }
      }

      reader.readAsDataURL(file)
      event.target.value = ''
    },
    view (id) {
      return '/api/batch_letters/' + this.batchLetter.id + '/coowners/' + id + '/preview'
    },
    showSendModeOptionsPopup (coOwner) {
      this.$modal.show(SendModeOptionsPopup, {
        batchLetterId: this.batchLetter.id,
        coOwner: coOwner
      }, {
        width: '45%',
        height: 'auto',
        scrollable: true,
        adaptive: true
      }, {
        closed (event) {
          EventBus.$emit('document_uploaded_event')
        }
      })
    },
    showCoOwnerEditPopup (coOwner) {
      this.$modal.show(CoOwnerEditPopup, {
        batchLetterId: this.batchLetter.id,
        coOwner: coOwner
      }, {
        width: '45%',
        height: 'auto',
        adaptive: true,
        scrollable: true,
        clickToClose: false
      }, {
        closed (event) {
          EventBus.$emit('document_uploaded_event')
        }
      })
    },
    addCoOwner () {
      this.$modal.show(CoOwnerAddPopup, {
        batchLetter: this.batchLetter
      }, {
        width: '45%',
        height: 'auto',
        scrollable: true,
        adaptive: true
      }, {
        closed (event) {
          EventBus.$emit('document_uploaded_event')
        }
      })
    },
    switchSendLevelAllCoowners () {
      this.isUploading = true
      api.postSwitchSendLevelLetter(this.batchLetter.id).then(
        response => {
          this.$swal(
            'Changement effectué',
            'Le mode d\'envoi a été changé pour tous les copropriétaires. Vous avez toujours la possibilité d\'effectuer des changements individuels pour certains copropriétaires.',
            'success'
          )
          this.$refs.coOwnerTable.refresh();
          this.isUploading = false
        },
        error => {
          this.isUploading = false

          if (!authService.redirectToLoginIfNotLogged(error.response)) {
            this.$swal('Erreur de traitement', 'Problème de réseau', 'error')
          }
        }
      )
    }
  }
}
</script>

<style scoped>
  .action-link {
    font-size: 90%;
  }
  .btn-import{
    float: right;
    margin-bottom: -5px;
    margin-left: 10px;
  }
  .file-select > input[type="file"] {
    display: none;
  }

  .input-label {
    cursor: pointer;
    position: relative;
    display: inline-block;
  }

  .icon-pointer {
    cursor: pointer;
  }
</style>
