<template>
    <div class="document-panel">
        <div class="card text-white" :class="{ 'bg-success': isUploadedWithCorrectFormat(), 'bg-warning': isUploading() || isProcessing(), 'bg-danger': isRemoving() || isError() }">
            <div v-if="isUploadedWithCorrectFormat() && !isLetterCleared" class="info-box-icon card-body" >
                <thumnbnail :document="doc" :letter="letter"></thumnbnail>
            </div>
            <div v-else class="info-box-icon info-box-icon-loading">
                <i v-if="isUploading() || isProcessing() || isRemoving()" class="fas fa-circle-notch fa-spin fa-2x card-body"></i>
                <i v-else-if="isLetterCleared" class="fa fa-trash fa-2x"></i>
                <i v-else-if="isError()" class="fa fa-exclamation-triangle fa-2x"></i>

                <span v-if="isUploading()" class="info-box-title progress-text">Envoi...</span>
                <span v-else-if="isProcessing()" class="info-box-title progress-text">Traitement...</span>
                <span v-else-if="isRemoving()" class="info-box-title progress-text">Suppression...</span>
            </div>

            <div class="info-box-content card-footer">

                <div v-if="isUploadedWithCorrectFormat()">
                    <div v-if="!letterSent && !letterCanceled">
                        <span class="remove-document" @click="removeDocument(doc.internalId)" data-toggle="tooltip" data-placement="right" title="Retirer ce document" v-if="!letterLocked">
                        <i class="fa fa-trash-alt action-icon"></i>
                        </span>
                    </div>

                    <span class="staple-document" v-if="isStaplingAvailableForDocument(doc.internalId)">
                        <span class="staple-document-active" v-if="letter.stapled" data-toggle="tooltip" data-placement="right" title="Ce document sera agrafé">
                            <i class="fa fa-paperclip action-icon"></i>
                        </span>
                    </span>
                    <span class="drag-indicator" v-if="canDragDocuments" v-tooltip="{ content: 'Glissez pour changer l\'ordre des documents', placement: 'right'}">
                          <i class="fas fa-arrows-alt fa-lg"></i>
                          <i class="fas fa-hand-pointer fa-2x"></i>
                    </span>
                    <span v-if="doc.pageCount" class="page-count">
                        <span v-if="doc.pageCount > 1">{{ doc.pageCount }} pages</span>
                        <span v-else>{{ doc.pageCount }} page</span>
                    </span>
                    <span class="stappling-text" v-if="isStaplingAvailableForDocument(doc.internalId)">
                        <div class="staple-document-active" v-if="letter.stapled" data-toggle="tooltip" data-placement="right" title="Ce document sera agrafé">
                            Agrafé
                        </div>
                    </span>

                </div>

              <span class="info-box-title" data-toggle="tooltip" data-placement="bottom" :title="doc.name">{{ doc.name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {DocumentState} from '../../../../constants/Document'
import Thumbnail from './Thumbnail.vue'

export default {
  components: {
    'thumnbnail': Thumbnail
  },
  props: {
    internalId: String,
    position: Number
  },
  methods: {
    ...mapActions('letter', [
      'removeDocument'
    ]),
    isUploading: function () {
      return (this.doc.status === DocumentState.UPLOADING && this.doc.percent !== 100)
    },
    isProcessing: function () {
      return (this.doc.status === DocumentState.UPLOADING && this.doc.percent === 100)
    },
    isRemoving: function () {
      return this.doc.status === DocumentState.REMOVING
    },
    isUploadedWithCorrectFormat: function () {
      return this.doc.status === DocumentState.UPLOADED && this.doc.normalized
    },
    isError: function () {
      return this.doc.status === DocumentState.ERROR
    }
  },
  computed: mapState({
    // arrow functions can make the code very succinct!
    doc (state) {
      return state.letter.documents.find(doc => this.internalId === doc.internalId)
    },
    letter (state) {
      return state.letter
    },
    ...mapGetters('letter', [
      'letterLocked',
      'letterSent',
      'isLetterCleared',
      'isStaplingAvailableForDocument',
      'canDragDocuments',
      'letterCanceled'
    ])
  })
}
</script>

<style scoped>
    .action-icon {
        font-size: 1.8em;
    }

    /* Don't forget to hide the original file input! */
    .file-select > input[type="file"] {
        display: none;
    }

    .progress-text {
        text-transform: uppercase;
    }

    .remove-document {
        position: absolute;
        top: 6px;
        right: 10px;
        font-weight: bold;
        cursor: pointer;
        font-size: 16px;
    }

    .remove-document .fa-trash-alt:hover {
        color: red;
    }

    .staple-document {
        position: absolute;
        top: -1px;
        font-weight: bold;
        font-size: 24px;
    }

    .staple-document-active .fa-paperclip {
        color: #444444;
    }

    .drag-indicator {
        position: absolute;
        top: 55px;
        right: 10px;
        font-weight: bold;
        cursor: move;
        font-size: 13px;
    }

    .drag-indicator .fa-hand-pointer-o {
        position: absolute;
        top: 7px;
        right: -1px;
    }

    .drag-indicator .fa-arrows-alt {
        position: absolute;
        top: -2px;
        right: 17px;
    }

    .info-box {
        border-radius: 3px;
    }

    .info-box-icon {
        float: none;
        width: auto;
        height: 200px;
    }

    .info-box-icon.card-body {
      padding-left: 1rem;
      padding-top: 0.2rem;
      padding-right: 70px;
      padding-bottom: 1.25rem;
    }

    .info-box-icon-loading {
        padding-top: 35px;
        padding-right: 8px;
    }

    .info-box-content {
        margin-left: 0px;
    }

    .page-count {
        position: absolute;
        bottom: 50px;
        right: 10px;
    }

    .stappling-text {
        position: absolute;
        bottom: 75px;
        right: 10px;
        font-size: 13px;
     }

    .info-box-title.progress-text {
      font-size: 18px;
      line-height: 35px;
    }

    .info-box-title {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
      display: block;
      text-align: center;
    }


</style>
