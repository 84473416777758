<template>
    <div>
        <div class="mode-container">
            <div>

              <span>
                <div v-if="letterSent">
                  <i class="fa fa-tint"></i> <strong>Colorisation : </strong>
                  <span v-if="color"> Couleur</span>
                  <span v-else> Noir et blanc</span>
                </div>
                <b-form-group v-else>
                  <strong>Colorisation : </strong>
                  <b-form-radio-group id="radio-group-2" v-model="color" name="radio-sub-component" :disabled="letterLocked">
                    <b-form-radio :value="true"><i style="color: blue" class="fa fa-tint"></i> Couleur <span v-if="canDisplayPriceDifference(true)">({{ getDisplayablePriceDifference(true) | formatFloat }} €)</span></b-form-radio>
                    <b-form-radio :value="false"><i class="fa fa-tint"></i> Noir et blanc <span v-if="canDisplayPriceDifference(false)">({{ getDisplayablePriceDifference(false) | formatFloat }} €)</span></b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
                </span>
            </div>
            <div>
                <i class="fa fa-print"></i>&nbsp;<strong> Impression :</strong>
                <span v-if="doubleSidedPrinting"> Recto / Verso </span>
                <span v-else> Recto seulement </span>
            </div>
            <div v-if="isStaplingAvailable">
                <i class="fa fa-paperclip"></i>&nbsp;<strong> Agrafage :</strong>
                <span v-if="stapled"> Oui </span>
                <span v-else> Non </span>
            </div>
            <i class="fa fa-copy"></i>&nbsp;

            <template v-if="isPublipostage">
                <strong> Feuilles :</strong>
                <span> {{ pageCount }}</span>
            </template>
            <template v-else>
                <template v-if="printedPageCount > 1">
                    <strong> Feuilles :</strong>
                    <span> {{ printedPageCount }}</span>
                </template>
                <template v-else>
                    <strong> Feuille :</strong>
                    <span> {{ printedPageCount }}</span>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'

export default {
  computed: {
    ...mapState('letter', [
      'doubleSidedPrinting',
      'printedPageCount',
      'pageCount',
      'stapled',
      'priceDifferences'
    ]),
    ...mapGetters('letter', [
      'letterLocked',
      'letterSent',
      'letterPriceRefreshing',
      'isPublipostage',
      'isStaplingAvailable'
    ]),
    color: {
      get () {
        return this.$store.state.letter.color
      },
      set (value) {
        this.chooseColor(value)
      },
    }
  },
  methods: {
    ...mapActions('letter', [
      'chooseColor'
    ]),
    canDisplayPriceDifference (value) {
      return this.getPriceDifference(value) !== 0 && !this.letterPriceRefreshing
    },
    getDisplayablePriceDifference (value) {
      let price = this.getPriceDifference(value)
      if (price > 0) {
        return '+' + this.getPriceDifference(value)
      } else {
        return this.getPriceDifference(value)
      }
    },
    getPriceDifference (value) {
      if (!this.priceDifferences || !this.priceDifferences.color) {
        return 0
      }
      if (value === true || value === false) {
        return this.priceDifferences.color[value === true ? "1" : "0"]
      } else {
        return 0
      }
    },
  }
}
</script>

<style>
    .mode-container {
        min-height: 115px;
    }
</style>
