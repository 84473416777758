<template>
  <div>
    <b-row>
      <b-col cols="8">
        <h1>Tous les Utilisateurs</h1>
      </b-col>
    </b-row>
    <span class="divider"></span>
    <users-table></users-table>
  </div>
</template>

<script>

import UsersTable from '../../components/user/UsersTable'

export default {
  components: {
    UsersTable
  }
}
</script>
