<template>
  <div v-if="user">
    <bill-table :user="user" :userSpecific="true"></bill-table>
    <receipt-table :user="user"></receipt-table>
  </div>
</template>
<script>

import BillTable from '../../components/billing/BillTable'
import {mapGetters} from "vuex";
import user from '@/api/user'
import authService from '@/service/auth'
import ReceiptTable from '../../components/billing/ReceiptTable'

export default {
  watch: {
    '$route.params': {
      handler() {
        let userId = parseInt(this.$route.params.id)
        if (userId && userId !== this.userId) {
          this.userId = userId;
        } else {
          this.userId = this.getUser.id
        }
        this.getUserProfile()
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      userId: null,
      user: null
    }
  },
  components: {
    BillTable,
    ReceiptTable
  },
  computed: {
    ...mapGetters('user', [
      'isAdminView',
      'getUser'
    ])
  },
  methods: {
    getUserProfile () {
      user.getInfo(this.userId).then(
        response => {
          this.user = response.data
        })
        .catch(error => {
          if(!authService.redirectToLoginIfNotLogged(error.response)){
            this.$swal('Erreur de traitement', 'Vérifiez le utilisateur', 'error')
            if (this.isAdminView) {
              this.$router.push({name: 'user_list'})
            } else {
              this.$router.push({name: 'default'})
            }
          }
        })
    }
  }
}
</script>
