import {SendType} from '@/service/coowner'

export const Status = Object.freeze({
  Incoherent: 'INCOHERENT'
})

export const DeliveryStatus = Object.freeze({
  NotSent: 'NOT_SENT',
  Submitted: 'SUBMITTED',
  Deposit: 'DEPOSIT',
  Expired: 'EXPIRED',
  Delivered: 'DELIVERED',
  Returned: 'RETURNED',
  Canceled: 'CANCELED',
  Error: 'ERROR'
})

export default {
  urlForSendType (type, trackingNumber) {
    if (type === SendType.Electronic) {
      return null
    } else if (type === SendType.Manual) {
      return 'http://www.csuivi.courrier.laposte.fr/suivi?id=' + trackingNumber
    } else if (type === SendType.Paper) {
      return 'http://www.csuivi.courrier.laposte.fr/suivi?id=' + trackingNumber
    } else {
      return 'Non défini'
    }
  },
  getTextForStatus (status, isAdminView) {
    switch (status) {
      case DeliveryStatus.NotSent:
        return 'Non envoyé'
      case DeliveryStatus.Submitted:
        return 'Soumis au prestataire'
      case DeliveryStatus.Deposit:
        return 'Déposé'
      case DeliveryStatus.Delivered:
        return 'Distribué'
      case DeliveryStatus.Returned:
        return 'Retourné'
      case DeliveryStatus.Expired:
        return 'Expiré'
      case DeliveryStatus.Canceled:
        return 'Annulé'
      case DeliveryStatus.Error:
        if (isAdminView) {
          return 'Erreur d\'expédition'
        } else {
          return 'Soumis au prestataire'
        }
      case Status.Incoherent:
        return 'Incohérent'
      default:
        return 'Non envoyé'
    }
  },
  getTextForAdviceStatus (status) {
    switch (status) {
      case 'DEPOT':
      case 'DEPOSIT':
        return 'Dépôt'
      case 'RETRIEVAL':
      case 'RETRAIT':
        return 'Retrait'
      case 'REFUS':
        return 'Refus'
      case 'EXPIRATION':
        return 'Expiration'
      case 'RETRAIT_PAPIER':
        return 'Retrait (papier)'
    }
  },
  getTextForAdviceFilename (status) {
    switch (status) {
      case 'DEPOT':
        return 'AVIS DE DEPOT'
      case 'EXPIRATION':
        return 'AVIS DE NON RELEVE'
      case 'RETRAIT':
      case 'RETRAIT_PAPIER':
        return 'AVIS DE RECEPTION'
    }
  }
}
