export const BillItemTypePayer = {
  PAYER_SYNDIC: 'SYNDIC',
  PAYER_COPRO: 'COPRO'
}

export const BillItemType = {
  BILL_ITEM_TYPE_PAPER_LETTER_SERVICE: 'DELIVERY_PAPER_LETTER_SERVICE',
  BILL_ITEM_TYPE_PAPER_LETTER_STAMP: 'DELIVERY_PAPER_LETTER_STAMP',
  BILL_ITEM_TYPE_SELF_DELIVERY: 'DELIVERY_SELF_DELIVERY',
  BILL_ITEM_TYPE_LRE: 'DELIVERY_LRE'
}
