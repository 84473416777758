<template>
  <div>
    <template v-if="hasPdfPlugin">
      <object v-auth-resource="getPreviewUrl()" type="application/pdf" width="95%" height="550px" id="confirm_letter_preview" class="preview-area">
        <p>Il semble que vous n'ayez pas installé le plugin PDF plugin pour votre navigateur..
          En attendant… <a :href="getPreviewUrl()">cliquez ici pour télécharger la lettre.</a>
        </p>
      </object>
    </template>
    <template v-else>
      <div class="preview-area">
        <pdf
          :src="pdfData"
          :filename="'Apercu.pdf'"
        ></pdf>
      </div>
    </template>
  </div>
</template>

<script>
  import authService from '../../../../service/auth'
  import AcrobatInfo from "../../../../assets/js/AcrobatInfo";
  import axiosAuth from "../../../../api/axios-auth";
  import Pdf from "../../../Pdf";

  export default {
    components: {
      Pdf
    },
    data () {
      return {
        pdfData: null
      }
    },
    props: {
      letter: {
        type: Object
      }
    },
    mounted() {
      if (!this.hasPdfPlugin) {
        axiosAuth.get(this.getPreviewUrl(), {responseType: 'blob',}).then(
          (response) => {
            var blob = new Blob([response.data], {type: 'application/pdf'});
            var blobURL = window.URL.createObjectURL(blob);
            this.pdfData = blobURL
          }
        ).catch(
          (error) => {
            if (!authService.redirectToLoginIfNotLogged(error.response)) {
              this.$swal('Chargement impossible', "Impossible de charger le document, vous pouvez tenter de recharger la page.", 'warning')
            }
          }
        )
      }
    },
    computed: {
      hasPdfPlugin () {
        return !AcrobatInfo.needsPdfJsFallback()
      },
    },
    methods: {
      getPreviewUrl() {
        return '/api/paperletter/letters/' + this.letter.id + '/preview'
      },
    }
  }
</script>

<style scoped>
  @media (max-width: 576px) {
    .preview-area {
      height: 350px;
    }
  }
</style>
