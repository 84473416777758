import axiosAuth from "../api/axios-auth";


let vueAuthImage = {}

if (!axiosAuth) {
  throw new Error('[vue-auth-resource] cannot locate Axios')
}

function setImgSrc(el, binding) {
  if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
    let imageUrl = binding.value
    axiosAuth({
      method: 'get',
      url: imageUrl,
      responseType: 'arraybuffer'
    })
    .then(function(resp) {
      let mimeType = resp.headers['content-type'].toLowerCase();
      let imgBase64 = new Buffer(resp.data, 'binary').toString('base64');
      if (el.nodeName.toLowerCase() === 'object') {
        el.data = 'data:' + mimeType + ';base64,' + imgBase64;
      } else {
        el.src = 'data:' + mimeType + ';base64,' + imgBase64;
      }
    }).catch((function() {
      el.src = imageUrl;
    }))
  }
}

vueAuthImage.install = function(Vue) {
  Vue.directive('auth-resource', {
    bind: function(el, binding) {
      setImgSrc(el, binding);
    },
    componentUpdated: function(el, binding) {
      setImgSrc(el, binding);
    }
  })
}

export default vueAuthImage

