<template>
    <div class="content">
        <div class="container">
            <transition name="fade" mode="out-in">
                <router-view></router-view>
            </transition>
        </div>
    </div>
</template>
<script>
export default {}
</script>

<style scoped>
  .content {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
</style>
