<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {EventBus} from "./EventBus";

export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    customSlot: {
      template: `<h3 class="icon-slot" slot="after">Chargement...</h3>`
    }
  },
  mounted() {
    let loader = null
    EventBus.$on('showLoading', () => {
      loader = this.$loading.show({
        active: false,
        height: 70,
        width: 70,
      },{
        after: this.$createElement('custom-slot')
      })
    })

    EventBus.$on('hideLoading', () => {
      if (loader !== null) {
        loader.hide()
      }
    })
  },
  computed: {
    ...mapState({
      queue: state => state.notifications.queue
    })
  },
  watch: {
    queue: {
      handler: function (queue) {
        for (let notif of queue) {
          this.$swal(notif.title, notif.text, notif.type)
          this.shiftNotification()
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...mapActions([
      'shiftNotification'
    ])
  }
}
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
</style>
