<template>
    <div>
        <b-row>
            <b-col cols="8">
              <h1>Liste de vos envois syndic</h1>
            </b-col>
            <b-col cols="4" class="text-right">
              <b-dropdown class="m-2" variant="success" size="lg">
                <template slot="button-content"><font-awesome-icon icon="paper-plane"></font-awesome-icon>&nbsp;Nouvel envoi</template>
                <b-dropdown-item to="/batchletters/create/1"><font-awesome-icon icon="plus"></font-awesome-icon>&nbsp;Nouvelle Assemblée Générale</b-dropdown-item>
                <b-dropdown-item to="/batchletters/create/2"><font-awesome-icon icon="plus"></font-awesome-icon>&nbsp;Nouveau Procès verbal</b-dropdown-item>
                <b-dropdown-item to="/batchletters/create/3"><font-awesome-icon icon="plus"></font-awesome-icon>&nbsp;Nouvel Appel de fonds</b-dropdown-item>
                <b-dropdown-item to="/batchletters/create/4"><font-awesome-icon icon="plus"></font-awesome-icon>&nbsp;Autre type d'envoi</b-dropdown-item>
              </b-dropdown>
            </b-col>
        </b-row>

      <span class="divider"></span>

      <batch-letters-table></batch-letters-table>
    </div>
</template>

<script>
import BatchLettersTable from '../../components/BatchLettersTable'
import api from '../../api'
import authService from '@/service/auth'
import {EventBus} from "../../EventBus";

export default {
  components: {
    'batch-letters-table': BatchLettersTable
  },
  methods: {
    postBatchLetter (reason) {
      this.$router.push({
        name: 'batchletters_create',
        params: { reason: reason }
      })
    }
  }
}
</script>
