
import moment from 'moment'

export const LetterStep = {
  CREATION: 'CREATION',
  LOADING: 'LOADING',
  SYNCHRONIZING: 'SYNCHRONIZING',
  SYNCHRONIZING_ERROR: 'SYNCHRONIZING_ERROR',
  PREVIEW_PAGE: 'PREVIEW_PAGE',
  SENDING: 'SENDING',
  SENDING_ERROR: 'SENDING_ERROR',
  SENT: 'SENT',
  DEPOSIT: 'DEPOSIT',
  CANCELED: 'CANCELED'
}

export const LetterState = {
  CREATION: 'CREATION',
  DEPOSIT_FAILED: 'DEPOSIT_FAILED',
  SENT: 'SENT',
  DEPOSIT: 'DEPOSIT',
  CANCELED: 'CANCELED',
  LARGE_FILE_SIZE: 'LARGE_FILE_SIZE',
  MERGED_FILE_ERROR: 'MERGED_FILE_ERROR',
  TAGG_SENDING_ERROR: 'TAGG_SENDING_ERROR'
}

export const LetterTracking = {
  SUBMITTED: 'SUBMITTED',
  DELIVERED: 'DELIVERED',
  RETURNED: 'RETURNED',
  CANCELED: 'CANCELED'
}

export const LetterRegisteredTracking = {
  ADDRESS_FAIL: 'PB ADRESSE',
  REFUSED: 'REFUSÉ',
  NOT_CLAIMED: 'NON RECLAMÉ',
  OTHER: 'AUTRE'
}

export const LetterType = {
  PUBLIPOSTAGE: 'PUBLIPOSTAGE',
  SIMPLE: 'SIMPLE'
}

export const LetterFilterState = {
  CREATION: 'CREATION',
  VIRTUAL_PROGRAMMED: 'VIRTUAL_PROGRAMMED',
  VIRTUAL_TRANSMITTED: 'VIRTUAL_TRANSMITTED',
  VIRTUAL_POSTED: 'VIRTUAL_POSTED',
  CANCELED: 'CANCELED',
  VIRTUAL_ERROR: 'Erreur VIRTUAL_ERROR'
}

export default {
  isValidTrackingNumber (letter) {
    return letter.trackingNumber !== undefined && letter.trackingNumber !== ''
  },

  getTrackingUrl (letter) {
    return 'http://www.csuivi.courrier.laposte.fr/suivi?id=' + letter.trackingNumber
  },

  getTrackingStatusText (letter) {
    let tracking = letter.latestTracking
    if (tracking) {
      let text = ''
      if (tracking.status === LetterTracking.DELIVERED) {
        text = 'Distribué'
      } else if (tracking.status === LetterTracking.CANCELED) {
        text = 'Annulé'
      } else if (tracking.status === LetterTracking.RETURNED) {
        text = 'Retourné'
      } else {
        if (tracking.registeredStatus && tracking.registeredStatus !== '') {
          switch (tracking.registeredStatus) {
            case LetterRegisteredTracking.ADDRESS_FAIL:
              text = 'Problème d\'adresse'; break
            case LetterRegisteredTracking.REFUSED:
              text = 'Refusé'; break
            case LetterRegisteredTracking.NOT_CLAIMED:
              text = 'Non réclamé'; break
            case LetterRegisteredTracking.OTHER:
              text = 'Problème inconnu'; break
            default:
              text = 'En cours d\'acheminement'; break
          }
        } else if (this.isValidTrackingNumber(letter)) {
          text = 'En cours d\'acheminement'
        }
      }
      return text
    }
  },

  getLetterStatusAdditionalText (letter) {
    let tracking = letter.latestTracking

    let text = ' '
    if (tracking && tracking.status !== LetterTracking.REFUSED && tracking.submittedDate) {
      text = moment(tracking.submittedDate).format('DD/MM/YYYY')
    } else if (letter.status === LetterState.DEPOSIT || letter.status === LetterState.DEPOSIT_FAILED || letter.status === LetterState.SENT) {
      if (letter.programmedDate) {
        text = moment(letter.programmedDate).format('DD/MM/YYYY')
      } else {
        text = moment(letter.sendDate).format('DD/MM/YYYY')
      }
    } else if (letter.status === LetterState.CREATION) {
      return 'Supprimer'
    } else {
      return text
    }

    return 'le ' + text
  },

  getLetterStatusText (letter, isAdmin) {
    let tracking = letter.latestTracking
    if (tracking && tracking.status !== LetterTracking.REFUSED) {
      return 'Posté'
    } else if (letter.status === LetterState.CREATION) {
      return 'Création'
    } else if (letter.status === LetterState.DEPOSIT) {
      return 'Transmis'
    } else if (letter.status === LetterState.CANCELED) {
      return 'Annulé'
    } else if (letter.status === LetterState.DEPOSIT_FAILED || letter.status === LetterState.LARGE_FILE_SIZE || letter.status === LetterState.MERGED_FILE_ERROR || letter.status === LetterState.TAGG_SENDING_ERROR) {
      if (isAdmin) {
        return 'Erreur expédition'
      } else {
        return 'Transmis'
      }
    } else if (letter.status === LetterState.SENT) {
      if (letter.programmedDate) {
        return 'Programmé'
      } else {
        return 'Transmis'
      }
    }
  },

  getLetterStatusIconFromText (text) {
    switch (text) {
      case 'Création':
        return '<i class="fa fa-edit"></i>'
      case 'Programmé':
        return '<i class="fa fa-calendar"></i>'
      case 'Transmis':
        return '<i class="fa fa-exchange-alt"></i>'
      case 'Posté':
        return '<i class="fa fa-check"></i>'
      case 'Annulé':
        return '<i class="fa fa-times"></i>'
      case 'Erreur expédition':
        return '<i class="fa fa-exclamation-triangle"></i>'
    }
    return ''
  },

  allowDeleteLetter (letter) {
    return letter.status === LetterState.CREATION
  }
}
