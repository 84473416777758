<template>
  <div class="modal-preview">
    <div class="modal-content">
      <div class="modal-header">
        <div class="text-center">Aperçu du document {{ name }}</div>
        <span class="close-button" @click="$emit('close')">
          <i class="fa fa-times"></i>
        </span>
      </div>

    </div>
    <div class="modal-body">
      <div style="height: 650px">
        <pdf
          v-if="pdfData"
          :src="pdfData"
          :filename="name"
        ></pdf>
      </div>
    </div>
  </div>
</template>

<script>
  import AcrobatInfo from '@/assets/js/AcrobatInfo'
  import axiosAuth from "@/api/axios-auth"
  import Pdf from '@/components/Pdf'
  import authService from "@/service/auth"

  export default {
    props: {
      'url': String,
      'name': String
    },
    mounted() {
      axiosAuth.get(this.url, {responseType: 'blob'}).then(
        (response) => {
          this.pdfData = window.URL.createObjectURL(new Blob([response.data]));
        }
      ).catch(
        (error) => {
          if (!authService.redirectToLoginIfNotLogged(error.response)) {
            this.$swal('Chargement impossible', "Impossible de charger le document, vous pouvez tenter de recharger la page.", 'warning')
          }
          console.log(error)
        }
      )
    },
    data () {
      return {
        pdfData: null
      }
    },
    components: {
      Pdf
    },
    methods: {
      getPreviewUrl () {
        return this.url
      },
    }
  }
</script>

<style scoped>
  .modal-header {
    background-color: #3c8dbc;
    color: #fff;
    padding: 10px;
    font-size: 22px;
  }

  .close-button {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 1.5em;
  }

  @media (max-width: 576px) {
    .modal-header {
      font-size: 1.2rem;
    }
  }
</style>
