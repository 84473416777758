export const SendLevel = Object.freeze({
  Simple: 'SIMPLE',
  Registered: 'REGISTERED'
})

export const SendType = Object.freeze({
  Paper: 'PAPER',
  Electronic: 'LRE',
  Manual: 'MANUAL',
  DontSend: 'DONT_SEND'
})

export const Gender = Object.freeze({
  Male: 'M',
  Female: 'Mme',
  MaleAndMale: 'M ou M',
  FemaleAndFemale: 'Mme ou Mme',
  MaleAndFemale: 'M. ou Mme'
})

export const Category = Object.freeze({
  Individual: false,
  Company: true
})

export default {
  getTextForSendTypeAndLevel (type, level, batchLetter) {
    let text

    if (type === SendType.Electronic) {
      text = 'Électronique'
    } else if (type === SendType.Manual) {
      return 'Remise en main propre'
    } else if (type === SendType.Paper) {
      text = 'Papier'
    } else if (type === SendType.DontSend) {
      return 'Ne pas envoyer'
    } else {
      return 'Non défini'
    }

    if (!level) {
      level = batchLetter.sendLevel
    }
    if (level === SendLevel.Simple) {
      return text + ' : Simple'
    } else {
      if (type === SendType.Electronic) {
        return text + ' : Confirmé'
      } else {
        return text + ' : Recommandé'
      }
    }
  },
  iconForSendType (type) {
    if (type === SendType.Electronic) {
      return 'clearbus.png'
    } else if (type === SendType.Manual) {
      return 'chronopost.png'
    } else if (type === SendType.Paper) {
      return 'laposte.png'
    } else {
      return undefined
    }
  },
  getTextInvalidationReason (reason) {
    switch (reason) {
      case 'REASON_FULLNAME_LIMIT':
        return 'La civilité, le nom et le prénom ne doivent pas dépasser 38 caractères '
      case 'REASON_ADDRESS_INCOMPLETE':
        return 'L\'adresse est manquante'
      case 'REASON_ADDRESS_LINE_LIMIT':
        return 'L\'adresse ne doit pas dépasser 4 lignes'
      case 'REASON_ADDRESS_LIMIT':
        return 'Chaque ligne de l\'adresse ne doivent pas dépasser 38 caractères'
      case 'REASON_ADDRESS_INVALID_POSTCODE':
        return 'Code postal invalide'
      case 'REASON_MISSING_COMPANY_NAME':
        return 'Nom de la société manquant'
      case 'REASON_MISSING_EMAIL':
        return 'Email manquant'
      case 'REASON_MISSING_PHONE':
        return 'Téléphone manquant'
      case 'REASON_INVALID_COUNTRY':
        return 'Envoi impossible vers ce pays'
    }
  }
}
