import {types} from './mutation-types'
import * as getters from './getters'
import errorResponseService from '../../../service/shelled_letter/ErrorResponseService'
import refreshService from '../../../service/shelled_letter/RefreshInformationsService'
import uuid from 'uuid-js'
import api from '../../../api/paperletter'
import LetterAdapter from '../../../api/LetterAdapter'
import DocumentAdapter from '../../../api/DocumentAdapter'
import router from '../../../router'
import {LetterStep} from '../../../constants/Letter'
import {RecipientState} from '../../../constants/Recipient'
import authService from '../../../service/auth'

export const chooseSendMode = ({commit, state}, sendMode) => {
  const prevSendMode = state.sendMode
  commit(types.SET_SEND_MODE, sendMode)

  let letterAdapter = new LetterAdapter(state)
  if (letterAdapter.isPersisted()) {
    let hasDocuments = state.documents.some(document => document.normalized)
    if (hasDocuments) {
      commit(types.SET_PRICE_REFRESHING)
    }
    letterAdapter.put('sendMode').then(
      response => {
        refreshService.refreshPriceIfNeeded(commit, state)
        refreshService.refreshRecipientIfNeeded(commit, state)
      },
      error => {
        if (!authService.redirectToLoginIfNotLogged(error.response)) {
          let notifications = errorResponseService.handleSendModeError(error)
          commit('push', notifications, { root: true })
          commit(types.SET_SEND_MODE, prevSendMode)
        }
      }
    )
  }
}

export const chooseColor = ({commit, state}, color) => {
  const prevSendMode = state.sendMode
  commit(types.SET_COLOR, color)

  let letterAdapter = new LetterAdapter(state)
  if (letterAdapter.isPersisted()) {
    let hasDocuments = state.documents.some(document => document.normalized)
    if (hasDocuments) {
      commit(types.SET_PRICE_REFRESHING)
    }
    letterAdapter.put('color').then(
      response => {
        refreshService.refreshPriceIfNeeded(commit, state)
      },
      error => {
        if (!authService.redirectToLoginIfNotLogged(error.response)) {
          let notifications = errorResponseService.handleSendModeError(error)
          commit('push', notifications, {root: true})
          commit(types.SET_COLOR, prevSendMode)
        }
      }
    )
  }
}

export const editLetter = ({commit, state}, letter) => {
  commit(types.SET_PRICE_REFRESHING)
  commit(types.SET_LETTER, letter)

  // router.replace(`/edit/${letter.id}`)

  // Add each documents manually as they all needs custom computing (internalId and such)
  for (let document of letter.documents) {
    let internalId = uuid.create().hex
    commit(types.ADD_DOCUMENT, {internalId, document})
  }

  refreshService.refreshPrice(commit)
}

export const resetLetter = ({commit, state, dispatch}) => {
  commit(types.RESET_LETTER)
  dispatch('resetContact', state.recipient)
  dispatch('resetContact', state.sender)
}

export const confirmContactRegionAndSynchronizeLetter = ({commit, state}) => {
  if (!getters.letterReady(state)) {
    return
  }

  let letterAdapter = new LetterAdapter(state)
  commit(types.SET_LETTER_STEP, LetterStep.SYNCHRONIZING)

  if (letterAdapter.isPersisted()) {
    letterAdapter.put().then(
      response => {
        commit(types.SET_LETTER_STEP, LetterStep.PREVIEW_PAGE)
      },
      error => {
        if (!authService.redirectToLoginIfNotLogged(error.response)) {
          let notifications = errorResponseService.handleConfirmationError(error)
          commit('push', notifications, {root: true})
          commit(types.SET_LETTER_STEP, LetterStep.SYNCHRONIZING_ERROR)
        }
      }
    )
  } else {
    letterAdapter.post().then(
      response => {
        commit(types.SET_LETTER_ID, response.data.id)
        commit(types.SET_LETTER_STEP, LetterStep.PREVIEW_PAGE)

        router.replace(`/edit/${response.data.id}`)
      },
      error => {
        if (!authService.redirectToLoginIfNotLogged(error.response)) {
          commit(types.SET_LETTER_STEP, LetterStep.SYNCHRONIZING_ERROR)
        }
      }
    )
  }
}

export const editLetterAfterPreview = ({commit, state}) => {
  commit(types.SET_LETTER_STEP, LetterStep.CREATION)
}

export const sendLetter = ({commit, state, rootState, dispatch}, programmedDate) => {
  commit(types.SET_LETTER_STEP, LetterStep.SENDING)
  commit(types.SET_PROGRAMMED_DATE, programmedDate)

  let letterAdapter = new LetterAdapter(state)
  return letterAdapter.postSend().then(
    response => {
      commit(types.SET_LETTER_STEP, LetterStep.SENT)
    },
    error => {
      if (!authService.redirectToLoginIfNotLogged(error.response)) {
        let notifications = errorResponseService.handleCreditInsufficientError(error)
        commit('push', notifications, { root: true })
        commit(types.SET_LETTER_STEP, LetterStep.SENDING_ERROR)
      }
    }
  )
}

export const addDroppedDocument = ({commit, state}, e) => {
  let dt = e.dataTransfer

  if (dt.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (let i = 0; i < dt.items.length; i++) {
      if (dt.items[i].kind === 'file') {
        addDocument({commit, state}, dt.items[i].getAsFile(), i)
      }
    }
  } else if (dt.files) {
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < dt.files.length; i++) {
      addDocument({commit, state}, dt.files[i], i)
    }
  }
}

export const addPickedDocument = ({commit, state}, event) => {
  let file = event.target.value
  if (event.target.files !== undefined) {
    file = event.target.files[0]
  }
  addDocument({commit, state}, file)
  // Make sure to clear the file input to be able to import the same file again
  event.target.value = ''
}

export const addDocument = ({commit, state}, file, position = null) => {
  let internalId = uuid.create().hex

  let fileName = file
  if (file.name !== undefined) {
    fileName = file.name
  }

  fileName = fileName.match(/[^/\\]+$/)[0]

  commit(types.SET_PRICE_REFRESHING)
  commit(types.DOCUMENT_UPLOADING, {internalId, fileName: fileName})

  let isFirstDocument = state.documents.length === 1

  if (isFirstDocument && !state.isAddressPageNeeded) {
    commit(types.SET_CONTACT_REGION_REFRESHING)
  }

  // Uploading file
  let documentAdapter = new DocumentAdapter(state)

  let onUploadProgress = function (progressEvent) {
    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    commit(types.DOCUMENT_UPLOADING_UPDATE_PROGRESS, {internalId, percentCompleted})
  }

  documentAdapter.postLetterDocument(file, position, onUploadProgress).then(
    response => {
      commit(types.DOCUMENT_UPLOADED, {internalId, document: response.data})

      let notifications = errorResponseService.handleUploadedDocumentWarnings(response.data)
      commit('push', notifications, { root: true })
      refreshService.refreshPrice(commit)
      refreshService.refreshPages(commit, state)
    },
    error => {
      if (!authService.redirectToLoginIfNotLogged(error.response)) {
        commit(types.DOCUMENT_ERROR, internalId)
        let notifications = errorResponseService.handleDocumentError(error)
        commit('push', notifications, {root: true})
        refreshService.refreshPrice(commit)
      }
    }
  )
}

export const putToggleStapling = ({commit, state}) => {
  commit(types.STAPLING_CHANGING)
  let promise = null
  if (state.stapled) {
    promise = api.unstapleLetter(state.id)
  } else {
    promise = api.stapleLetter(state.id)
  }

  promise.then(
    response => {
      commit(types.STAPLING_CHANGE_FINISHED, {letterResponse: response.data})
      refreshService.refreshPrice(commit)
    },
    error => {
      if (!authService.redirectToLoginIfNotLogged(error.response)) {
        commit(types.STAPLING_CHANGE_ERROR)
        let notifications = errorResponseService.handleError(null, null, 'Impossible de changer l\'agrafage, merci de réessayer.')
        commit('push', notifications, {root: true})
      }
    }
  )
}

export const removeDocument = ({commit, state, rootState, dispatch}, internalId) => {
  let documentClone = {...state.documents.find(document => document.internalId === internalId)}
  let isFirstDocument = state.documents[0].internalId === internalId

  commit(types.SET_PRICE_REFRESHING)
  commit(types.DOCUMENT_REMOVING, internalId)

  if (isFirstDocument && !state.isAddressPageNeeded) {
    commit(types.SET_CONTACT_REGION_REFRESHING)
  }

  if (documentClone.id !== undefined) {
    api.deleteDocument(state.id, documentClone.id).then(
      response => {
        commit(types.REMOVE_DOCUMENT, internalId)
        refreshService.refreshPrice(commit)
        refreshService.refreshPages(commit, state)
      },
      error => {
        if (!authService.redirectToLoginIfNotLogged(error.response)) {
          commit(types.DOCUMENT_REMOVING_ERROR, internalId)
          refreshService.refreshPrice(commit)
          refreshService.refreshPages(commit, state)
          let notifications = errorResponseService.handleError(
            'Impossible de supprimer le document',
            null,
            'La suppression du document a échoué. Merci de réessayer ou de recharger la page.'
          )
          commit('push', notifications, {root: true})
        }
      }
    )
  } else {
    commit(types.REMOVE_DOCUMENT, internalId)
    refreshService.refreshPrice(commit)
    refreshService.refreshPages(commit, state)
  }
}

export const cancelLetter = ({commit, state, rootState, dispatch}) => {
  commit(types.SET_LETTER_STEP, LetterStep.SENDING)

  let letterAdapter = new LetterAdapter(state)
  letterAdapter.putCancel().then(
    response => {
      commit(types.SET_LETTER_STEP, LetterStep.CANCELED)
    },
    error => {
      if (!authService.redirectToLoginIfNotLogged(error.response)) {
        let notifications = errorResponseService.handleError(null, null, 'Impossible d\'annuler cette lettre.')
        commit('push', notifications, {root: true})
      }
    }
  )
}

export const deleteLetter = ({commit, state, rootState, dispatch}, letter) => {
  commit(types.SET_LETTER_STEP, LetterStep.SENDING)

  let letterAdapter = new LetterAdapter(state)
  letterAdapter.deleteLetter(letter.id).then(
    response => {
      commit(types.SET_LETTER_STEP, LetterStep.CANCELED)
    },
    error => {
      if (!authService.redirectToLoginIfNotLogged(error.response)) {
        let notifications = errorResponseService.handleError(null, null, 'Impossible de supprimer cette lettre.')
        commit('push', notifications, {root: true})
        console.log('delete error', error)
      }
    }
  )
}

export const reschedulingLetter = ({commit}) => {
  commit(types.SET_LETTER_STEP, LetterStep.PREVIEW_PAGE)
}

export const editProgrammedLetterAfterPreview = ({commit}) => {
  commit(types.SET_LETTER_STEP, LetterStep.SENT)
}

export const updateContact = ({commit}, {object, field, value}) => {
  commit(types.UPDATE_CONTACT_FIELD, {object, field, value})
}

export const updateContactStatus = ({commit}, {object, status}) => {
  commit(types.SET_CONTACT_STATUS, {object, status})
}

export const resetContact = ({commit}, object) => {
  commit(types.RESET_CONTACT, object)
}

export const reuseAccount = ({commit}) => {
  api.getUserInformations().then(
    response => {
      let data = response.data
      api.getCitiesForPostCode(data.postCode).then(
        response => {
          let postCodeData = response.data
          let validCity = null

          if (postCodeData.length === 1) {
            validCity = postCodeData[0].nom_de_la_commune
          } else {
            postCodeData.some(function (entry) {
              let compare = data.city.localeCompare(entry.nom_de_la_commune, 'en', {sensitivity: 'base'}) === 0
              if (compare) {
                validCity = entry.nom_de_la_commune
              }
              return compare
            })
          }

          if (!validCity) {
            let notifications = errorResponseService.handleError(
              'Erreur de code postal / ville',
              null,
              'L\'ortographe de la ville est invalide, merci de saisir le code postal manuellement.\n' +
                            'Vous pouvez le changer dans Mon compte => Modifier mes coordonnées'
            )
            commit('push', notifications, { root: true })
          }

          commit(types.REUSE_ACCOUNT, {data, validCity})
        }
      )
    },
    error => {
      if (!authService.redirectToLoginIfNotLogged(error.response)) {
        let notifications = errorResponseService.handleError(null, null, 'Impossible d\'effectuer cette action')
        commit('push', notifications, { root: true })
      }
    }
  )
}

export const reorderDocuments = ({commit, state}, object) => {
  const prevSendMode = [...state.documents]

  commit(types.REORDERING_CHANGING, object)
  commit(types.SET_CONTACT_REGION_REFRESHING)

  let documentArray = object.map(function (element, index) {
    return {document: element.id, position: index}
  })

  let fullArray = {
    ordering: documentArray
  }

  api.putReorderDocuments(state.id, fullArray).then(
    response => {
      commit(types.REORDERING_FINISHED, object)
      commit(types.REFRESH_CONTACT_REGION)
      commit(types.UPDATE_CONTACT_REGION, response.data.contactRegion)
      refreshService.refreshRecipientIfNeeded(commit, state)
    },
    error => {
      if (!authService.redirectToLoginIfNotLogged(error.response)) {
        commit(types.REORDERING_ERROR, prevSendMode)
        let notifications = errorResponseService.handleError(null, null, 'Impossible de changer l\'ordre des documents, merci de réessayer.')
        commit('push', notifications, {root: true})
      }
    }
  )
}

export const putCustomRecipient = ({commit, state, dispatch}) => {
  commit(types.SET_ADDRESSPAGE_NEEDED_MODE, true)
  commit(types.SET_CONTACT_REGION_REFRESHING)

  dispatch('updateContact', {object: state.recipient, field: 'status', value: RecipientState.EDITING})

  let letterAdapter = new LetterAdapter(state)
  letterAdapter.put('addressPageNeeded').then(
    response => {
      refreshService.refreshPriceIfNeeded(commit, state)
      commit(types.REFRESH_CONTACT_REGION)
    },
    error => {
      if (!authService.redirectToLoginIfNotLogged(error.response)) {
        commit(types.SET_ADDRESSPAGE_NEEDED_MODE, false)
        let notifications = errorResponseService.handleError(null, null, 'Impossible d\'effectuer cette action')
        commit('push', notifications, {root: true})
      }
    }
  )
}

export const putAutoRecipient = ({commit, state, dispatch}) => {
  commit(types.SET_ADDRESSPAGE_NEEDED_MODE, false)
  commit(types.SET_CONTACT_REGION_REFRESHING)

  let letterAdapter = new LetterAdapter(state)
  letterAdapter.put('addressPageNeeded').then(
    response => {
      if (response.data.contactRegion && response.data.contactRegion.contactDetected) {
        dispatch('updateContact', {object: state.recipient, field: 'status', value: RecipientState.VALID})
      } else {
        dispatch('updateContact', {object: state.recipient, field: 'status', value: RecipientState.EDITING})
      }

      commit(types.UPDATE_CONTACT_REGION, response.data.contactRegion)
      commit(types.REFRESH_CONTACT_REGION)
      refreshService.refreshPriceIfNeeded(commit, state)
      refreshService.refreshRecipientIfNeeded(commit, state)
    },
    error => {
      if (!authService.redirectToLoginIfNotLogged(error.response)) {
        commit(types.SET_ADDRESSPAGE_NEEDED_MODE, true)
        let notifications = errorResponseService.handleError(null, null, 'Impossible d\'effectuer cette action')
        commit('push', notifications, {root: true})
      }
    }
  )
}

export const viewLetter = ({commit, state}, letter) => {
  commit(types.SET_PRICE_REFRESHING)
  commit(types.SET_LETTER, letter)

  // Add each documents manually as they all needs custom computing (internalId and such)
  for (let document of letter.documents) {
    let internalId = uuid.create().hex
    commit(types.ADD_DOCUMENT, {internalId, document})
  }

  refreshService.refreshPrice(commit)
}
