<template>
    <div class="vld-parent">
      <loading :isLoading="isLoading"></loading>
      <v-server-table ref="batchlettersTable" name="batchlettersTable" :columns="getColumns()" :options="options" @loaded="onTableLoaded" @loading="onTableLoading">
          <span slot="user" slot-scope="props">
              {{props.row.user.firstname}} {{props.row.user.lastname}} ({{props.row.user.id}})
              <br>
              {{props.row.user.email}}
          </span>
          <span slot="coOwnership" slot-scope="props">
            <template v-if="props.row.coOwnership">
              <template v-if="props.row.coOwnership.reference">{{props.row.coOwnership.reference}}</template>
              <template v-if="props.row.coOwnership.name">
                <template v-if="props.row.coOwnership.reference">
                  /
                </template>
                {{props.row.coOwnership.name}}
              </template>
            </template>
            <template v-else>-</template>
          </span>
          <span slot="reason" slot-scope="props">
            {{props.row.reason.name}}
          </span>
        <router-link slot="id" slot-scope="props" :to="{ name: 'batchletters_detail', params: {id: props.row.id}}"><i class="fas fa-envelope"></i>&nbsp;{{ props.row.id }}</router-link>

        <span slot="status" slot-scope="props">
          <i class="fas" :class="statusIcon(props.row)"></i>&nbsp;{{ displayableStatus(props.row) }}
          <button v-if="props.row.status === BatchLetterStatus.Incomplete" class="btn btn-danger btn-sm" @click="deleteBatchLetter(props.row.id)">
            <i class="fas fa-trash"></i>
            Supprimer
          </button>
        </span>
      </v-server-table>
    </div>
</template>

<script>
import api from '../api'
import moment from 'moment'
import { mapGetters } from 'vuex'
import {BatchLetterStatus} from '@/service/batchletter'
import authService from '@/service/auth'
import Loading from './Loading'
import {BatchLetterReason} from "@/service/batchLetterReason";

export default {
  components: {
    Loading
  },
  watch: {
    reason: function () {
      this.$refs.batchlettersTable.refresh()
    }
  },
  computed: {
    ...mapGetters('user', [
      'isAdminView'
    ])
  },
  data () {
    return {
      isLoading: false,
      BatchLetterStatus: BatchLetterStatus,
      options: {
        requestFunction: (data) => {
          return api.getBatchLetters(data)
            .catch(function (e) {
              if (!authService.redirectToLoginIfNotLogged(e.response)) {
                this.dispatch('error', e)
              }
            }.bind(this))
        },
        requestAdapter (data) {
          return {
            filters: {
              id: data.id,
              coOwnership: data.coOwnership,
              user: data.user,
              reason : data.reason
            },
            limit: data.limit,
            page: data.page,
            sort: data.orderBy ? data.orderBy : 'id',
            orderBy: data.ascending ? 'asc' : 'desc'
          }
        },
        filterByColumn: true,
        responseAdapter: function (resp) {
          const data = []
          for (let row of resp.data.data) {
            data.push({
              'id': row['id'],
              'coOwnership': row['coOwnership'],
              'lastModificationDate': (row['sentDate']) ? (moment(row['sentDate']).format('YYYY-MM-DD')) : ((row['lastModificationDate']) ? moment(row['lastModificationDate']).format('YYYY-MM-DD') : '-'),
              'programmedDate': (row['programmedDate']) ? moment(row['programmedDate']).format('YYYY-MM-DD') : '-',
              'status': row['status'],
              'childLetter': row['childLetter'],
              'user': row['user'],
              'coOwnersFullySubmitted': row['coOwnersFullySubmitted'],
              'coOwnersPartiallySubmitted': row['coOwnersPartiallySubmitted'],
              'reason': row['reason'],
            })
          }

          return {
            data: data,
            count: resp.data.count
          }
        },
        headings: {
          id: 'N°',
          user: 'Utilisateur',
          reason: 'Types d\'envoi',
          coOwnership: 'Copropriété',
          lastModificationDate: ' Date de saisie',
          programmedDate: 'Date d\'envoi',
          status: 'Etat'
        },
        orderBy: { ascending:false },
        filterable: ['id', 'coOwnership', 'user', 'reason'],
        customFilters: ['id', 'coOwnership', 'user', 'reason'],
        sortable: ['id'],
        saveState: true,
        storage: 'session',
        listColumns: this.listColumns(),
      }
    }
  },
  methods: {
    listColumns () {
      return {
        reason: [
          {id:BatchLetterReason.KEY_AG, text: 'Assemblée générale'},
          {id:BatchLetterReason.KEY_PV, text: 'Procès-verbal'},
          {id:BatchLetterReason.KEY_APF, text: 'Appel de fond'},
          {id:BatchLetterReason.KEY_OTHER, text: 'Autre type d\'envoi'}
        ]
      }
    },
    onTableLoaded () {
      this.isLoading = false
    },
    onTableLoading(){
      this.isLoading = true
    },
    deleteBatchLetter (id) {
      api.deleteBatchLetter(id).then(
        response => {
          this.$refs.batchlettersTable.refresh()
        }).catch(
        error => {
          if (!authService.redirectToLoginIfNotLogged(error.response)) {
            console.log(error)
          }
        }
      )
    },
    getColumns () {
      let cols
      if (this.isAdminView) {
        cols = ['id', 'user', 'reason', 'coOwnership', 'lastModificationDate', 'programmedDate', 'status']
      } else {
        cols = ['id', 'reason', 'coOwnership', 'lastModificationDate', 'programmedDate', 'status']
      }

      return cols
    },
    postBatchLetter (row) {
      let data = {reason: row.reason.childReason.id}

      api.postBatchLetter(data).then(
        response => {
          this.$router.push({
            name: 'batchletters_detail',
            params: { id: response.data.id }
          })
        }).catch(
        error => {
          if (!authService.redirectToLoginIfNotLogged(error.response)) {
            console.log(error)
          }
        }
      )
    },
    displayableStatus (batchLetter) {
      let status = batchLetter.status

      if (batchLetter.coOwnersFullySubmitted) {
        return 'Envoyé au prestataire'
      } else if (batchLetter.coOwnersPartiallySubmitted) {
        return 'En cours d\'envoi'
      } else if (status === BatchLetterStatus.Incomplete) {
        return 'En cours de saisie'
      } else if (status === BatchLetterStatus.Sent) {
        return 'Envoi programmé'
      } else if (status === BatchLetterStatus.Canceled) {
        return 'Annulé'
      }
    },
    statusIcon (batchLetter) {
      let status = batchLetter.status

      if (batchLetter.coOwnersFullySubmitted) {
        return 'fa-paper-plane'
      } else if (batchLetter.coOwnersPartiallySubmitted) {
        return 'fa-circle-notch fa-spin'
      } else if (status === BatchLetterStatus.Incomplete) {
        return 'fa-edit'
      } else if (status === BatchLetterStatus.Sent) {
        return 'fa-calendar'
      } else if (status === BatchLetterStatus.Canceled) {
        return 'fa-times'
      }
    }
  }
}
</script>
