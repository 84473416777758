import { Validator } from 'vee-validate'

Validator.extend('addressLimit', {
  getMessage: '',
  validate: value => {
    var line = value.split('\n')
    if (line.length > 4) {
      return false
    }

    for (var i = 0; i < line.length; i++) {
      if (line[i].length > 38) {
        return false
      }
    }

    return true
  }
})

Validator.extend('referenceLimit', {
  getMessage: '',
  validate: value => {
    return /^[0-9]*$/.test(value) && value.length <= 50
  }
})

Validator.extend('passwordComplicity', (value, [minLength, minAlphaLength, minNumericLength]) => {
  if (minLength > 0 && value.length < minLength) {
    return false
  }

  let alphaCount = new RegExp('(.*[a-zA-Z]){' + minAlphaLength + ',}')
  if (!value.match(alphaCount)) {
    return false
  }

  let numericCount = new RegExp('(.*[\\d]){' + minNumericLength + ',}')
  if (!value.match(numericCount)) {
    return false
  }

  return true
})
