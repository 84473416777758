<template>
  <batch-letters-list></batch-letters-list>
</template>

<script>
import BatchLettersList from '@/pages/user/BatchLetterList'
import api from '@/api'
import authService from '@/service/auth'

export default {
  components: {
    'batch-letters-list': BatchLettersList
  }
}
</script>
