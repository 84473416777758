<template>
  <div>
    <template v-if="isAdminView">
      <b-form-group
        label="Email"
        label-for="input-vertical"
        label-class="font-weight-bold"
      >
        <b-form-input
          size="sm"
          v-model="user.email"
          name="email"
          v-validate="'required'"
        ></b-form-input>
        <div class="text-danger" v-show="errors.has('email')">{{ errors.first('email') }}</div>
      </b-form-group>
    </template>

    <b-form-group
      label="Entreprise"
      label-for="input-vertical"
      label-class="font-weight-bold"
    >
      <b-form-input
        size="sm"
        v-model="user.commercialName"
        name="commercialName"
        v-validate="'required'"
      ></b-form-input>
      <div class="text-danger" v-show="errors.has('commercialName')">{{ errors.first('commercialName') }}</div>
    </b-form-group>

    <b-row>
      <b-col md="6">
        <b-form-group
          label="Nom"
          label-for="input-vertical"
          label-class="font-weight-bold"
        >
          <b-form-input
            size="sm"
            v-model="user.lastname"
            name="lastname"
            v-validate="'required'"
          ></b-form-input>
          <div class="text-danger" v-show="errors.has('lastname')">{{ errors.first('lastname') }}</div>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Prénom"
          label-for="input-vertical"
          label-class="font-weight-bold"
        >
          <b-form-input
            size="sm"
            v-model="user.firstname"
            name="firstname"
            v-validate="'required'"
          ></b-form-input>
          <div class="text-danger" v-show="errors.has('firstname')">{{ errors.first('firstname') }}</div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group
      label="Téléphone"
      label-for="input-vertical"
      label-class="font-weight-bold"
    >
      <b-form-input
        size="sm"
        v-model="user.phone"
        name="phone"
        v-validate="'required|digits:10'"
      ></b-form-input>
      <div class="text-danger" v-show="errors.has('phone')">{{ errors.first('phone') }}</div>
    </b-form-group>

    <b-form-group
      label="Adresse"
      label-for="input-vertical"
      label-class="font-weight-bold"
    >
      <b-form-input
        size="sm"
        v-model="user.address"
        name="address"
        v-validate="'required'"
      ></b-form-input>
      <div class="text-danger" v-show="errors.has('address')">{{ errors.first('address') }}</div>
    </b-form-group>

    <b-row>
      <b-col md="4">
        <b-form-group
          label="Code postal"
          label-for="input-vertical"
          label-class="font-weight-bold"
        >
          <b-form-input
            size="sm"
            v-model="user.postCode"
            name="postcode"
            v-validate="'required|digits:5'"
          ></b-form-input>
          <div class="text-danger" v-show="errors.has('postcode')">{{ errors.first('postcode') }}</div>
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-form-group
          label="Ville"
          label-for="input-vertical"
          label-class="font-weight-bold"
        >
          <b-form-input
            size="sm"
            v-model="user.city"
            name="city"
            v-validate="'required'"
          ></b-form-input>
          <div class="text-danger" v-show="errors.has('city')">{{ errors.first('city') }}</div>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    user: Object
  },
  inject: ['$validator'],
  computed : {
    ...mapGetters('user', [
      'isAdminView'
    ])
  }
}
</script>
