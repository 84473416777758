<template>
  <div>
    <b-card
      border-variant="info"
      header="Primary"
      header-bg-variant="info"
      header-text-variant="white"
      header-class="text-center"
      header-tag="header"
    >
      <template v-slot:header>
        <div class="card-header-big">Informations sur le copropriétaire</div>
      </template>

      <div v-if="coOwner !== null">
        <div>Référence : {{ coOwner.reference }}</div>
        <div v-if="coOwner.isCompany">
          <div>Compagnie : {{ coOwner.contact.companyName }}</div>
        </div>
        <div v-else>
          <div>Nom : {{ coOwnerName }}</div>
        </div>
        <div>Adresse : {{ coOwner.contact.address.address }}</div>
        <div>Code postal : {{ coOwner.contact.address.postcode }}</div>
        <div>Ville : {{ coOwner.contact.address.city }}</div>
        <div v-if="coOwner.contact.address.country !== 'FRANCE'">Pays : {{ coOwner.contact.address.country }}</div>
        <div v-if="coOwner.contact.phone">Téléphone : {{ coOwner.contact.phone }}</div>
        <div v-if="coOwner.contact.email">Email : {{ coOwner.contact.email }}</div>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    coOwner: Object,
    batchLetterId: Number
  },
  computed: {
    coOwnerName () {
      if (this.coOwner !== null) {
        let finalText = '';
        if (this.coOwner.contact.gender) {
          finalText += this.coOwner.contact.gender
        }
        if (this.coOwner.contact.lastName) {
          if (finalText) {
            finalText += ' '
          }
          finalText += this.coOwner.contact.lastName
        }
        if (this.coOwner.contact.firstName) {
          if (finalText) {
            finalText += ' '
          }
          finalText += this.coOwner.contact.firstName
        }

        return finalText
      }

      return null
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped>

</style>
