<template>
  <div>
    <b-row>
      <b-col sm="6">
        <b-form-group label="Nom *">
          <co-ownership-input
            :id="'coownership-name'"
            v-if="!loading && !locked"
            v-model="coOwnership.name"
            :co-ownership-id="coOwnershipId"
            field="name"
            v-on:selectCoOwnership="changeCoOwnership($event)"
            :inputChange="valueChange"
            v-validate="'required'"
            name="coownership-name"
            :key="'name_field'"
            :disabled="disableForm"
            aria-describedby="input-coownership-name-feedback"
            :state="validateState('coownership-name')"></co-ownership-input>
          <p v-if="!loading && locked" class="font-weight-bold">{{coOwnership.name}}</p>
          <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
          <div v-show="errors.has('coownership-name')" class="invalid-coownership-input-feedback">{{ errors.first('coownership-name') }}</div>
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group label="Référence *">
          <co-ownership-input
            :id="'coownership-reference'"
            v-if="!loading && !locked"
            v-model="coOwnership.reference"
            :co-ownership-id="coOwnershipId"
            field="reference"
            v-on:selectCoOwnership="changeCoOwnership($event)"
            :inputChange="valueChange"
            v-validate="'required'"
            name="coownership-reference"
            :key="'reference_field'"
            :state="validateState('coownership-reference')"
            aria-describedby="input-coownership-reference-feedback"
            :disabled="disableForm"></co-ownership-input>
          <p v-if="!loading && locked" class="font-weight-bold">{{coOwnership.reference}}</p>
          <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
          <div v-show="errors.has('coownership-reference')" class="invalid-coownership-input-feedback">{{ errors.first('coownership-reference') }}</div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group label="Adresse *">
      <b-form-textarea
        v-if="!loading && !locked"
        v-model="coOwnership.addresses[0].address"
        rows="1"
        id="coownership-address"
        name="coownership-address"
        v-validate="'required|addressLimit'"
        :state="validateState('coownership-address')"
        @change="valueChanged('coownership-address')"
        aria-describedby="input-coownership-address-feedback"
        :disabled="disableForm"
      ></b-form-textarea>
      <!-- create a search input with a drop down menu -->
      <p v-if="!loading && locked" class="font-weight-bold">{{coOwnership.addresses[0].address}}</p>
      <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
      <b-form-invalid-feedback id="input-coownership-address-feedback">{{ errors.first('coownership-address') }}</b-form-invalid-feedback>
    </b-form-group>

    <b-row>
      <b-col md="3">
        <b-form-group label="Code postal *">
          <b-form-input
            v-if="!loading && !locked"
            v-model="coOwnership.addresses[0].postcode"
            id="coownership-postcode"
            name="coownership-postcode"
            v-validate="'required|digits:5'"
            :state="validateState('coownership-postcode')"
            @change="valueChanged('coownership-address')"
            aria-describedby="input-coownership-postcode-feedback"
            :disabled="disableForm"
          ></b-form-input>
          <p v-if="!loading && locked" class="font-weight-bold">{{coOwnership.addresses[0].postcode}}</p>
          <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
          <b-form-invalid-feedback id="input-coownership-postcode-feedback">{{ errors.first('coownership-postcode') }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="9">
        <b-form-group label="Ville *">
          <b-form-input
            v-if="!loading && !locked"
            v-model="coOwnership.addresses[0].city"
            id="coownership-city"
            name="coownership-city"
            v-validate="'required'"
            :state="validateState('coownership-city')"
            @change="valueChanged('coownership-address')"
            aria-describedby="input-coownership-city-feedback"
            :disabled="disableForm"
          ></b-form-input>
          <p v-if="!loading && locked" class="font-weight-bold">{{coOwnership.addresses[0].city}}</p>
          <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
          <b-form-invalid-feedback id="input-coownership-city-feedback">{{ errors.first('coownership-city') }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="!loading && locked">
      <b-col>
        <b-button
          v-if="!disableForm"
          class="float-right btn-warning btn-block"
          :disabled="false"
          @click="unlockForm">
            <i class="fas fa-pencil-alt"></i> Modifier
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import addressService from '../../service/addressService'
import CoOwnershipInput from "@/components/form/CoOwnershipInput";

export default {
  components: {CoOwnershipInput},
  props: {
    coOwnership: Object,
    readyToRegister: Boolean,
    disableForm: Boolean,
    coOwnershipId: Number,
    locked: Boolean,
    loading: Boolean
  },
  data () {
    return {
      saveTimeout: null
    }
  },
  mounted () {
    if (!this.disableForm) {
      if (this.coOwnership.name) {
        this.$validator.validate('coownership-name')
      }
      if (this.coOwnership.reference) {
        this.$validator.validate('coownership-reference')
      }
      if (this.coOwnership.addresses[0].address) {
        this.$validator.validate('coownership-address')
      }
      if (this.coOwnership.addresses[0].postcode) {
        this.$validator.validate('coownership-postcode')
      }
      if (this.coOwnership.addresses[0].city) {
        this.$validator.validate('coownership-city')
      }
    }
  },
  methods: {
    validateState (ref) {
      this.$emit('validity', this.isFormValid())
      if (
        this.formFields[ref] &&
        (this.formFields[ref].dirty || this.formFields[ref].validated)
      ) {
        return !this.errors.has(ref)
      }
      return null
    },
    isFormValid () {
      return Object.keys(this.formFields).every(key => this.formFields[key].valid)
    },
    valueChanged (ref) {
      clearTimeout(this.saveTimeout)
      this.saveTimeout = setTimeout(() => {
          this.$emit('update', this.isFormValid())
      }, 300)
    },
    changeCoOwnership (newValue) {
      this.coOwnership.name = newValue.name;
      this.coOwnership.reference = newValue.reference;

      this.coOwnership.addresses[0].address = newValue.addresses.length > 0 ? newValue.addresses[0].address : "";
      this.coOwnership.addresses[0].postcode = newValue.addresses.length > 0 ? newValue.addresses[0].postcode : "";
      this.coOwnership.addresses[0].city = newValue.addresses.length > 0 ? newValue.addresses[0].city : "";
      //this.$emit('update', this.isFormValid()) // ALREADY TRIGGERED IN lockForm()
      this.lockForm(newValue.id);
      this.$emit('validity', true)
    },
    valueChange (field, value) {
      this.coOwnership[field] = value;
      this.valueChanged("coownership-" + field);
    },
    lockForm (id) {
      this.$emit('lock', id);
    },
    unlockForm () {
      this.$emit('unlock');
    }
  }
}
</script>


<style scoped>
.invalid-coownership-input-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
