import Vue from 'vue'
import Vuex from 'vuex'
import UserModule from './module/user'
import LetterModule from './module/letter'
import {state, mutations} from './module/mutation'
import * as actions from './actions'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user: UserModule,
    letter: LetterModule
  },
  state,
  mutations,
  actions
})
