import Vue from 'vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import {
  BRow,
  BButton,
  BCard,
  BCardFooter,
  BCardGroup,
  BCardText,
  BCol,
  BCollapse,
  BContainer,
  BDropdownDivider,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BLink,
  BNavbar,
  BNavbarBrand,
  BNavbarNav,
  BNavbarToggle,
  BNavItemDropdown,
  BNavItem,
  BNav,
  BSpinner,
  BTable,
  BImg,
  BInputGroup, BDropdown
} from 'bootstrap-vue'

Vue.component('b-row', BRow)
Vue.component('b-button', BButton)
Vue.component('b-card', BCard)
Vue.component('b-card-footer', BCardFooter)
Vue.component('b-card-group', BCardGroup)
Vue.component('b-card-text', BCardText)
Vue.component('b-col', BCol)
Vue.component('b-collapse', BCollapse)
Vue.component('b-container', BContainer)
Vue.component('b-dropdown',BDropdown)
Vue.component('b-dropdown-divider', BDropdownDivider)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-form', BForm)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-input', BFormInput)
Vue.component('b-form-invalid-feedback', BFormInvalidFeedback)
Vue.component('b-form-radio', BFormRadio)
Vue.component('b-form-radio-group', BFormRadioGroup)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-form-textarea', BFormTextarea)
Vue.component('b-link', BLink)
Vue.component('b-navbar', BNavbar)
Vue.component('b-navbar-brand', BNavbarBrand)
Vue.component('b-navbar-nav', BNavbarNav)
Vue.component('b-navbar-toggle', BNavbarToggle)
Vue.component('b-nav-item-dropdown', BNavItemDropdown)
Vue.component('b-nav-item', BNavItem)
Vue.component('b-nav', BNav)
Vue.component('b-spinner', BSpinner)
Vue.component('b-table', BTable)
Vue.component('b-img', BImg)
Vue.component('b-input-group', BInputGroup)
