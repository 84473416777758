import {RecipientState} from '../../../constants/Recipient'
import {LetterState, LetterStep, LetterType} from '../../../constants/Letter'
import {DocumentState} from '../../../constants/Document'
import moment from 'moment'

export const documentOperationInProgress = state => {
  return state.documents.some(doc => (doc.status !== DocumentState.UPLOADED || !doc.normalized))
}

export const canAddDocuments = state => {
  return !documentOperationInProgress(state) && !letterLocked(state)
}

export const hasDocuments = state => {
  return state.documents.some(document => document.normalized)
}

export const hasDocumentsWithError = state => {
  return state.documents.some(document => document.status === DocumentState.ERROR)
}

export const letterReady = state => {
  let hasRecipient = state.recipient.status === RecipientState.VALID
  let hasDocuments = state.documents.some(document => document.normalized)
  let hasUnormalizedDocuments = state.documents.some(document => !document.normalized)
  let senderOk = true
  if (state.sendMode && state.sendMode.senderAvailable) {
    senderOk = state.sender.status === RecipientState.VALID
  }

  return hasRecipient && senderOk && hasDocuments && !hasUnormalizedDocuments
}

export const letterLocked = state => {
  return (state.step !== LetterStep.CREATION && !letterSyncError(state)) || state.priceRefreshing || state.pageRefreshing
}

export const letterSyncError = state => {
  return state.step === LetterStep.SYNCHRONIZING_ERROR
}

export const letterSent = state => {
  return (state.status && state.status !== LetterState.CREATION) || state.step === LetterState.SENT || state.step === LetterState.DEPOSIT || state.step === LetterState.DEPOSIT_FAILED
}

export const letterProgrammed = state => {
  return (state.status === LetterState.SENT || state.step === LetterStep.SENT) && state.programmedDate
}

export const letterSynchronizing = state => {
  return state.step === LetterStep.SYNCHRONIZING
}

export const canSendLetter = state => {
  return letterReady(state) && !letterLocked(state)
}

export const letterPriceRefreshing = state => {
  return state.priceRefreshing
}

export const letterCanceled = state => {
  return state.step === LetterStep.CANCELED
}

export const letterProgrammedPreview = state => {
  let today = moment().format('YYYY-MM-DD')
  let programmedDateFuture = moment(state.programmedDate).isAfter(today)

  return state.programmedDate && programmedDateFuture
}

export const isPublipostage = state => {
  return state.type === LetterType.PUBLIPOSTAGE
}

export const isLetterCleared = state => {
  return state.cleared
}

export const isStaplingAvailable = (state) => {
  return state.documents.some(document => document.pageCount > 2)
}

export const isStaplingAvailableForDocument = (state) => (documentId) => {
  let documentClone = state.documents.find(document => document.internalId === documentId)
  return isStaplingAvailable(state) && state.doubleSidedPrinting ? documentClone.pageCount > 2 : documentClone.pageCount > 1
}

export const canDragDocuments = (state) => {
  return !letterLocked(state) && state.documents.length > 1
}

export const contactRegionRefreshing = state => {
  return state.contactRegionRefreshing
}

export const isAddressPageNeeded = state => {
  return state.isAddressPageNeeded
}

export const displayContactRegion = state => {
  return hasDocuments(state) && !isAddressPageNeeded(state) && !letterSent(state)
}

export const recipientDetected = state => {
  return !isAddressPageNeeded(state) && state.contactRegion && state.contactRegion.contactDetected
}

export const displayRecipientBlock = state => {
  if (isAddressPageNeeded(state)) {
    return true
  } else {
    return registerRecipientDetected(state)
  }
}

export const registerRecipientDetected = state => {
  return !isAddressPageNeeded(state) && state.sendMode && state.sendMode.senderAvailable
}

export const canDisplayContactSheetPriceDifference = state => {
  return getContactSheetPriceDifference(state) !== 0 && !letterPriceRefreshing(state)
}

export const getContactSheetPriceDifference = state => {
  let priceDifferences = state.priceDifferences

  if (!priceDifferences || !priceDifferences.sendMode) {
    return 0
  }

  return priceDifferences.sendMode['CONTACTPAGE'].price
}

export const getDisplayContactSheetPriceDifference = state => {
  let price = getContactSheetPriceDifference(state)

  if (price > 0) {
    return '+' + price
  } else {
    return price
  }
}
