<template>
  <div>
      <label v-show="!loading" v-for="sendMode in sendModes" :key="sendMode.id"
             class="btn btn-tc send-mode-button"
             :class="{active: isSelected(sendMode), empty: emptySendMode(sendMode) }"
             :disabled="letterLocked || letterSent || !sendModeAllowed(sendMode)"
             v-tooltip="{content: getSendModeContent(sendMode), placement: 'top'}"
      >
        <input type="radio" :value="sendMode" v-model="sendModeChoice"
               :disabled="letterLocked || letterSent || !sendModeAllowed(sendMode)">
        <i class="fa fa-envelope" :style="{color: sendMode.displayColor}"></i>
        <div class="send-mode-name"><b>{{ sendMode.name }}</b></div>
        <i v-if="isSelected(sendMode)" class="fa fa-check-circle"></i>
        <div v-else-if="canDisplayPriceDifference(sendMode)">({{ getDisplayablePriceDifference(sendMode) | formatFloat }} €)</div>
        <i v-else-if="letterPriceRefreshing" class="fas fa-circle-notch fa-spin"></i>
        <i v-else-if="!sendModeAllowed(sendMode)" class="fa fa-ban"></i>
      </label>

      <div v-if="letter.sendMode" v-tooltip="{content: 'Le tarif du timbre est estimé en fonction du poids. Si jamais nous obtenons un tarif plus intéressant, vous serez remboursé de la différence.' }">
        <i class="fa fa-info-circle"></i>
        <i> Mode d'envoi : <b> {{ letter.sendMode.name }} </b> <br/>
          {{ getSendModeContent(letter.sendMode) }}. <br/>
          <template v-if="stampPrice && !letterPriceRefreshing">Tarif d'affranchissement estimé : <b> {{ stampPrice | formatFloat }} € </b></template>
        </i>
      </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {getSendModes} from '../../../../api/paperletter'
import LoadingService from '../../../../service/shelled_letter/LoadingService'

export default {
  data () {
    return {
      sendModes: []
    }
  },
  created () {
    LoadingService.register(getSendModes().then(
      response => {
        this.sendModes = response.data

        if (!this.$route.params.id) {
          this.chooseSendMode(response.data.find(sendMode => sendMode.byDefault))
        }
      }
    ))
  },
  methods: {
    ...mapActions('letter', [
      'chooseSendMode'
    ]),
    emptySendMode (sendMode) {
      return !this.isSelected(sendMode) && this.getPriceDifference(sendMode) === 0 && !this.letterPriceRefreshing && this.sendModeAllowed(sendMode)
    },
    isSelected (sendMode) {
      return this.sendModeChoice && sendMode.id === this.sendModeChoice.id
    },
    canDisplayPriceDifference (value) {
      return !this.isSelected(value) && this.getPriceDifference(value) !== 0 && !this.letterPriceRefreshing && this.sendModeAllowed(value)
    },
    getDisplayablePriceDifference (value) {
      let price = this.getPriceDifference(value)
      if (price > 0) {
        return '+' + this.getPriceDifference(value)
      } else {
        return this.getPriceDifference(value)
      }
    },
    getPriceDifference (value) {
      if (!this.priceDifferences || !this.priceDifferences.sendMode) {
        return 0
      }
      if (value) {
        return this.priceDifferences.sendMode[value.id].price
      } else {
        return 0
      }
    },
    sendModeAllowed (value) {
      if (!this.priceDifferences || !this.priceDifferences.sendMode) {
        return true
      }
      if (value) {
        return this.priceDifferences.sendMode[value.id].stampAllowed
      } else {
        return false
      }
    },
    getSendModeContent (value) {
      if (this.sendModeAllowed(value)) {
        return value.content
      } else {
        return 'Indisponible'
      }
    }
  },
  computed: {
    sendModeChoice: {
      get () {
        return this.sendMode
      },
      set (value) {
        this.chooseSendMode(value)
      }
    },
    ...mapState([
      'loading'
    ]),
    ...mapState('letter', {
      letter: state => state,
    }),
    ...mapState('letter', [
      'priceDifferences',
      'stampPrice',
      'sendMode',
      'letterSent'
    ]),
    ...mapGetters('letter', [
      'letterLocked',
      'letterPriceRefreshing'
    ])
  }
}
</script>

<style scoped>
    input[type=radio] {
        display: none;
    }

    label.send-mode-button {
        padding: 0px 10px 0px 10px;
        transition: color 0.2s linear;
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0) scale(1.0, 1.0);
    }

    label.send-mode-button.empty {
        padding-top: 10px;
    }

    .send-mode-name {
        padding-top: 4px;
    }

    .fa-envelope {
        font-size: 1.5em;
    }

    .btn.btn-tc {
      padding: 5px;
      font-size: 14px;
      box-shadow: none;
      outline: 0;
      height: 85px;
      border-radius: 3px;
      position: relative;
      margin: 0 0 10px 10px;
      min-width: 80px;
      text-align: center;
      color: #666;
      border: 1px solid #ddd;
      background-color: #f4f4f4;
    }

    .btn.btn-tc.active {
      border-color: #eea236;
      background-color: #f0ad4e;
      color: #fff;
    }

    .btn.btn-tc.active.focus {
      outline: 0;
    }

    .btn-tc > .fa {
      margin-top: 4px;
    }
</style>
