<template>
  <b-card
    header-tag="header"
    border-variant="info"
    header="Primary"
    header-bg-variant="info"
    header-text-variant="white"
    header-class="text-center"
    body-class="card-body-condensed"
  >
    <template v-slot:header>
      <div class="card-header-big float-left">Documents</div>
      <label v-if="!locked" for="file-input-documents" class="input-label btn btn-warning float-right btn-import" v-bind:class="{ disabled: isDocumentUploading}">
        <template v-if="!isUploadingGeneralDocument">
          <font-awesome-icon icon="file-download"></font-awesome-icon>
          Ajouter un document général
          <input type="file" id="file-input-documents" class="btn-document" @change="uploadDocument" :disabled="isDocumentUploading || locked" >
        </template>
        <template v-else>
          <b-spinner small></b-spinner>
          <span>&nbsp;Traitement...</span>
        </template>
      </label>
      <label v-if="!locked" for="file-input-specific-documents" class="input-label btn btn-warning float-right btn-import" v-bind:class="{ disabled: isDocumentUploading }">
        <template v-if="!isUploadingSpecificDocument">
          <font-awesome-icon icon="file-download"></font-awesome-icon>
          Ajouter un document nominatif
          <input type="file" id="file-input-specific-documents" class="btn-document" @change="uploadSpecificDocument" :disabled="isDocumentUploading || locked">
        </template>
        <template v-else>
          <b-spinner small></b-spinner>
          <span>&nbsp;Traitement...</span>
        </template>
      </label>
    </template>

    <div class="vld-parent">
      <loading :isLoading="isLoading"></loading>
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Nom</th>
          <th scope="col">Pages</th>
          <th scope="col">Type</th>
          <th scope="col">Action</th>
        </tr>
        </thead>
        <draggable v-model="documentList" tag="tbody" v-bind="dragOptions" :disabled="this.locked || documentList.length <= 1">
          <tr v-for="(document,index) in documentList" :key="document.id">
            <td scope="row">
              <div v-show="!locked && documentList.length > 1">
                <font-awesome-icon icon="sort-down" v-if="index === 0"></font-awesome-icon>
                <font-awesome-icon icon="sort-up" v-else-if="index === documentList.length-1"></font-awesome-icon>
                <font-awesome-icon icon="sort" v-else></font-awesome-icon>
              </div>
            </td>
            <td scope="row">{{ document.name }}</td>
            <td>{{ document.pageCount }}</td>
            <td>
              <span v-if="document.type === DocumentType.Specific || document.type === DocumentType.Calling">Nominatif</span>
              <span v-else>Général</span>
            </td>
            <td>
              <font-awesome-icon v-if="document.pageCount > 0" icon="eye" slot="action" style="margin-right: 17px" class="fa-action" @click="previewDocument(document)"></font-awesome-icon>
              <font-awesome-icon v-if="!locked" icon="trash" slot="action" class="fa-action" @click="deleteDocument(document.id)"></font-awesome-icon>
            </td>
          </tr>
        </draggable>
    </table>
    </div>
  </b-card>
</template>

<script>
import PreviewPopup from '@/components/PreviewPopup'
import api from '../api/index.js'
import draggable from 'vuedraggable'
import { EventBus } from '../EventBus'
import axios from 'axios'
import {DocumentType} from '@/service/document'
import authService from '@/service/auth'
import Loading from '@/components/Loading'

export default {
  props: {
    batchLetterId: Number,
    locked: Boolean
  },
  data () {
    return {
      DocumentType: DocumentType,
      documents: [],
      dragOptions: {
        animation: 200,
        ghostClass: 'blue-background-class'
      },
      isUploadingSpecificDocument: false,
      isUploadingGeneralDocument: false,
      firstDocumentId: null,
      isLoading: false
    }
  },
  components: {
    draggable,
    Loading
  },
  mounted () {
    this.isLoading = true
    this.refreshDocuments()
  },
  computed: {
    documentList: {
      get () {
        return this.documents
      },
      set: function (value) {
        this.documents = value
        this.reorderDocuments()
      }
    },
    isDocumentUploading () {
      return (this.isUploadingSpecificDocument || this.isUploadingGeneralDocument)
    }
  },
  methods: {
    reorderDocuments () {
      let documentArray = this.documentList.map(function (element, index) {
        return {document: element.id, position: index}
      })

      let fullArray = {
        ordering: documentArray
      }

      let newFirstDocumentId = this.documentList[0]['id']

      api.reorderDocuments(this.batchLetterId, fullArray).then(
        response => {
          this.firstDocumentId = newFirstDocumentId
        }
      )
    },
    previewDocument (document) {
      let url = '/api/batch_letters/' + this.batchLetterId + '/documents/' + document.id
      this.$modal.show(PreviewPopup, {
        url: url,
        name: document.name
      }, {
        width: '55%',
        height: 'auto',
        scrollable: true,
        adaptive: true
      })
    },
    uploadDocument (event, type = DocumentType.Global) {
      let file = event.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = () => {
        let json = {
          type: type,
          file: reader.result,
          name: file.name
        }

        if (type === DocumentType.Global) {
          this.isUploadingGeneralDocument = true
        }

        event.target.value = ''

        api.postDocument(this.batchLetterId, json)
          .then(response => {
            if (type === DocumentType.Global) {
              this.$swal('Traitement terminé avec succès', '', 'success')
            } else if (response.data['coOwnerFoundCount'] === 0) {
              this.$swal('Erreur lors du traitement', 'Ce document ne correspond à aucun co-propriétaire. Merci de le vérifier.', 'warning')
            } else {
              if (response.data['coOwnerNotFoundList'].length === 0 && response.data['notAssignedPageList'].length === 0) {
                this.$swal('Traitement terminé', response.data['coOwnerFoundCount'] + ' co-propriétaires ont été détectés dans ce document.', 'success')
              } else {
                let errorMsg = 'Dans ce document, <ul>'
                errorMsg += '<li> <b>' + response.data['coOwnerFoundCount'] + '</b> co-propriétaires ont été détectés. </li>'

                if (response.data['coOwnerNotFoundList'].length > 0) {
                  errorMsg += '<li> <b>' + response.data['coOwnerNotFoundList'].length + '</b> co-propriétaires n\'ont pas été détectés. </li>'
                }

                if (response.data['notAssignedPageList'].length > 0) {
                  errorMsg += '<li> <b>' + response.data['notAssignedPageList'].length + '</b> pages ne sont attribuées à aucun co-propriétaires. </li>'
                }
                errorMsg += '</ul> <br>'

                // if there are un-assigned coOwners
                if (response.data['coOwnerNotFoundList'].length > 0) {
                  errorMsg += ' Liste des copropriétaires non détectés : <br>'

                  let coOwnerList = response.data['coOwnerNotFoundList']
                  for (let i = 0; i < coOwnerList.length; i++) {
                    let name = ''
                    if (coOwnerList[i].isCompany) {
                      name = coOwnerList[i].contact.companyName
                    } else {
                      name =
                        (coOwnerList[i].contact.gender !== undefined ? coOwnerList[i].contact.gender : '')
                        + ' ' + (coOwnerList[i].contact.lastName !== undefined ? coOwnerList[i].contact.lastName : '')
                        + ' ' + (coOwnerList[i].contact.firstName !== undefined ? coOwnerList[i].contact.firstName : '')
                    }
                    errorMsg += '<li> Nom : ' + name + ',' +
                      ' Référence : ' + coOwnerList[i].reference +
                      '</li>'
                  }
                }
                // if there are un-assigned pages
                if (response.data['notAssignedPageList'].length > 0) {
                  errorMsg += ' <br> Numéros de page non attribuées : ' + response.data['notAssignedPageList']
                }

                this.$swal({
                  title: 'Traitement terminé',
                  icon: 'warning',
                  className: 'modal-list-msg',
                  content: {
                    element: 'ul',
                    attributes: {
                      innerHTML: `${errorMsg}`
                    }
                  }
                })
              }
            }

            this.isUploadingGeneralDocument = this.isUploadingSpecificDocument = false
            this.refreshDocuments()
            EventBus.$emit('document_uploaded_event', true)
          })
          .catch(
            error => {
              if (!authService.redirectToLoginIfNotLogged(error.response)) {
                this.$swal('Erreur de traitement', 'Vérifiez le fichier', 'error')
                this.isUploadingGeneralDocument = this.isUploadingSpecificDocument = false
                this.refreshDocuments()
              }
            }
          )
      }
    },
    uploadSpecificDocument (event) {
      this.isUploadingSpecificDocument = true
      this.uploadDocument(event, DocumentType.Specific)
    },
    deleteDocument (id) {
      this.isUploadingSpecificDocument = this.isUploadingGeneralDocument = true
      api.deleteDocument(this.batchLetterId, id).then(
        response => {
          this.refreshDocuments()
          this.isUploadingSpecificDocument = this.isUploadingGeneralDocument = false
          EventBus.$emit('document_uploaded_event', true)
        }
      )
    },
    refreshDocuments () {
      this.isLoading = true
      api.getDocumentList(this.batchLetterId).then(
        response => {
          this.documents = response.data
          if (this.documents.length !== 0) {
            this.firstDocumentId = this.documents[0]['id']
          } else {
            this.firstDocumentId = null
          }

          this.isLoading = false
        }
      )
    }
  }
}
</script>

<style scoped>
  .btn-document  {
    cursor: pointer;
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    filter: alpha(opacity=0); /* IE < 9 */
    opacity: 0;
  }

  .btn-import {
    margin-left: 10px;
    margin-bottom: -5px;
  }

  .blue-background-class {
    background-color: #C8EBFB !important;
  }
</style>
