<template>
  <b-container>
    <b-row>
      <b-col sm="8" offset="2">
        <b-card-group v-if="tokenState !== 'EXPIRED'">
          <b-card
            border-variant="info"
            header-bg-variant="info"
            header-text-variant="white"
            header-class="text-center"
            header-tag="header"
          >
            <template v-slot:header>
              <div class="card-header-big">Nouveau mot de passe</div>
            </template>
            <b-card-text>
              <b-form v-on:submit.prevent="resetPassword">
                <b-form-group
                id="fieldset-vertical"
                label="Nouveau mot de passe"
                label-class="font-weight-bold"
                >
                  <b-form-input
                    type="password"
                    v-model="plainPassword.first"
                    name="password"
                    v-validate="'required|passwordComplicity:8,1,1'"
                    ref="confirmation"
                  ></b-form-input>
                  <div class="text-danger" v-show="errors.has('password')">{{ errors.first('password') }}</div>
                </b-form-group>

              <b-form-group
                id="fieldset-vertical"
                label="Répéter le nouveau mot de passe"
                label-class="font-weight-bold"
              >
                <b-form-input
                  type="password"
                  v-model="plainPassword.second"
                  name="confirm-password"
                  v-validate="'confirmed:confirmation'"
                ></b-form-input>
                <div class="text-danger" v-show="errors.has('confirm-password')">{{ errors.first('confirm-password') }}</div>
              </b-form-group>

                <router-link to="/login" class="btn btn-danger"><i class="fa fa-chevron-left"></i> Annuler</router-link>
                <b-button type="submit" variant="warning" class="float-right"><i class="fas fa-key"></i>&nbsp;Modifier le mot de passe</b-button>
              </b-form>
            </b-card-text>
          </b-card>
        </b-card-group>
        <b-card-group v-else>
          <b-card
            border-variant=""
            header-bg-variant="danger"
            header-text-variant="white"
            header-class="text-center"
            header-tag="header"
          >
            <template v-slot:header>
              <div class="card-header-big">Demand réinitialisation du mot de passe</div>
            </template>
            <b-card-text>
              Demandez la réinitialisation du mot de passe en cliquant <router-link to="/resetting">ici</router-link>
              ou essayez de vous connecter en cliquant sur le bouton ci-dessous.
              <br/> <br/>
              <router-link to="/login" class="btn btn-warning"><i class="fas fa-key"></i> Connexion</router-link>
            </b-card-text>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import user from '@/api/user'
export default {
  created () {
    let token = this.$route.params.token

    if (token) {
      user.verifyPasswordToken(token).then().catch(
        error => {
          this.tokenState = error.response.data.token_state
        }
      )
    }
  },
  data () {
    return {
      tokenState: 'VALID',
      plainPassword: {
        first: '',
        second: ''
      }
    }
  },
  methods: {
    resetPassword () {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return
        }

        let token = this.$route.params.token
        user.resetPassword(token, this.plainPassword).then(
          response => {
            this.$swal({
              title: 'Le mot de passe a été modifié',
              text: 'Le mot de passe a été changé. Vous pouvez essayer de vous connecter maintenant.',
              icon: 'success',
              closeOnClickOutside: false,
              closeOnEsc: false
            }).then(
              this.$router.push({
                name: 'login'
              })
            )
          }).catch(
          error => {
            if (error.response.data.errors) {
              let first = error.response.data.errors.children.plainPassword.children.first
              let second = error.response.data.errors.children.plainPassword.children.second

              let errorMsg = '<ul>'
              if (first && first.errors && first.errors.length > 0) {
                for (let i = 0; i < first.errors.length; i++) {
                  errorMsg += '<li>' + first.errors[i] + ' </li>'
                }
              }

              if (second && second.errors && second.errors.length > 0) {
                for (let i = 0; i < second.errors.length; i++) {
                  errorMsg += '<li>' + second.errors[i] + ' </li>'
                }
              }

              errorMsg += '</ul>'
              this.$swal({
                title: 'Merci de vérifier les champs',
                icon: 'error',
                content: {
                  element: 'ul',
                  attributes: {
                    innerHTML: `${errorMsg}`
                  }
                }
              })
            } else {
              this.$swal('Merci de vérifier votre identifiant.', 'Identifiant incorrect', 'error')
            }
          })
      })
    }
  }
}
</script>
