import UserLayout from '@/layout/user/UserLayout.vue'
import BatchLetterDetails from '@/pages/user/BatchLetterDetails.vue'
import AnonymousLayoutWithLogo from '@/layout/anonymous/AnonymousLayoutWithLogo'
import Login from '@/components/Login'
import Registration from '@/components/Registration'
import RegistrationSuccess from '@/components/RegistrationSuccess'
import CoOwnerDetail from '@/pages/user/CoOwnerDetail'
import AllSendingList from '@/pages/user/AllSendingList'
import ShelledLetterList from '@/pages/shelled_letters/ShelledLetterList'
import ShelledLetterDetails from '@/pages/shelled_letters/ShelledLetterDetails'
import UserProfile from '@/pages/user/UserProfile'
import ResetForm from '@/components/user/ResetForm'
import ResetRequest from '@/components/user/ResetRequest'
import NeedsHelp from '@/pages/user/NeedsHelp'
import UserBilling from '@/pages/user/UserBilling'
import UserChangePassword from '@/pages/user/UserChangePassword'
import LetterPaymentSuccessPage from "../pages/shelled_letters/LetterPaymentSuccessPage";
import UserList from '../pages/user/UserList'
import BillingList from '../pages/user/BillingList'
import Homepage from '@/layout/anonymous/Homepage'

const routes = [
  { path: '*', redirect: 'login' },
  { path: '/home', name: 'home', component: Homepage },
  { path: '/default', name: 'default', redirect: { name: 'batchletters_all_sending_list' } },
  {
    path: '/',
    component: AnonymousLayoutWithLogo,
    redirect: 'home',
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login
      }, {
        path: '/register',
        name: 'register',
        component: Registration
      }, {
        path: '/registration_success',
        name: 'registration_success',
        component: RegistrationSuccess
      }, {
        path: '/resetting/reset/:token',
        name: 'reset',
        component: ResetForm
      }, {
        path: '/resetting/request',
        name: 'reset_request',
        component: ResetRequest
      }
    ]
  },
  {
    path: '/user',
    meta: { requiresAuth: true },
    component: UserLayout,
    children: [
      {
        path: '/user/profile/:id?',
        name: 'profile',
        component: UserProfile
      },{
        path: '/user/billing/list',
        name: 'billing_list',
        component: BillingList
      },{
        path: '/user/billing/:id?',
        name: 'billing',
        component: UserBilling
      }, {
        path: '/user/needs_help',
        name: 'needs_help',
        component: NeedsHelp
      }, {
        path: '/user/change_password',
        name: 'change_password',
        component: UserChangePassword
      },{
        path: '/user/list',
        name: 'user_list',
        component: UserList
      },
    ]
  },
  {
    path: '/batchletters',
    meta: { requiresAuth: true },
    component: UserLayout,
    children: [
      {
        path: '/batchletters',
        name: 'batchletters_all_sending_list',
        component: AllSendingList
      },
      {
        path: '/batchletters/create/:reason',
        name: 'batchletters_create',
        component: BatchLetterDetails
      },
      {
        path: '/batchletters/:id',
        name: 'batchletters_detail',
        component: BatchLetterDetails
      }, {
        path: '/batchletters/:batchLetterId/coowners/:coOwnerId',
        name: 'batchletters_coowners_detail',
        component: CoOwnerDetail
      }
    ]
  },
  {
    path: '/shelled_letters',
    meta: { requiresAuth: true },
    component: UserLayout,
    children: [
      {
        path: '/shelled_letters',
        name: 'shelled_letters_list',
        component: ShelledLetterList
      },
      {
        path: '/shelled_letters_new',
        name: 'shelled_letters_new',
        component: ShelledLetterDetails
      },
      {
        path: '/shelled_letters/:id',
        name: 'shelled_letters_edit',
        component: ShelledLetterDetails
      },
      {
        path: '/shelled_letters/:id/payment_success/:programmedDate?',
        name: 'shelled_letters_payment_success',
        component: LetterPaymentSuccessPage
      }
    ]
  }
]

export default routes
