<template>
    <b-container>
        <b-row>
            <b-col sm="8" offset="2">
                <b-card
                    border-variant="info"
                    header="Primary"
                    header-bg-variant="info"
                    header-text-variant="white"
                    header-class="text-center"
                    header-tag="header"
                >
                    <template v-slot:header>
                        <div class="card-header-big">Enregistrement terminé</div>
                    </template>

                    <p>
                        Merci ! Notre service commercial vous recontactera dans les plus brefs délais.
                    </p>

                    <p>
                        Pendant ce temps, n'hésitez pas à découvrir la présentation de notre service <a href="">à cette adresse</a>.
                    </p>

                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
export default {
}
</script>
