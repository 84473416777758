import axiosAuth from './axios-auth'

export const baseUrl = '/api/paperletter'

export const getSendModes = () => axiosAuth.get(baseUrl + '/send_modes')

export const getLetters = (params) => axiosAuth.get(baseUrl + '/letters', {params})
export const getLetter = (id) => axiosAuth.get(baseUrl + `/letters/${id}`)
export const getLetterPrice = (id) => axiosAuth.get(baseUrl + `/letters/${id}/price`)
export const getLetterPriceWithDate = (id, date) => axiosAuth.get(baseUrl + `/letters/${id}/price/${date}`)
export const getLetterPostingDate = (id, date) => axiosAuth.get(baseUrl + `/letters/${id}/posting_date/${date}`)
export const postLetter = (letter) => axiosAuth.post(baseUrl + '/letters', letter)
export const putLetter = (id, letter) => axiosAuth.put(baseUrl + `/letters/${id}`, letter)
export const postLetterDocument = (id, formData, config) => axiosAuth.post(baseUrl + `/letters/${id}/documents`, formData, config)
export const postSendLetter = (id, letter) => axiosAuth.put(baseUrl + `/letters/${id}/send`, letter)
export const putCancel = (id) => axiosAuth.put(baseUrl + `/letters/${id}/cancel`)
export const deleteLetter = (id) => axiosAuth.delete(baseUrl + `/letters/${id}`)

export const deleteDocument = (letterId, documentId) => axiosAuth.delete(baseUrl + `/letters/${letterId}/documents/${documentId}`)
export const stapleLetter = (letterId) => axiosAuth.put(baseUrl + `/letters/${letterId}/staple`)
export const unstapleLetter = (letterId) => axiosAuth.put(baseUrl + `/letters/${letterId}/unstaple`)
export const putReorderDocuments = (letterId, data) => axiosAuth.put(baseUrl + `/letters/${letterId}/reorder_documents`, data)

export const getUserInformations = () => axiosAuth.get('/api/user')
export const getCitiesForPostCode = (postCode, params) => axiosAuth.get(baseUrl + `/address/cities/${postCode}`, {params})

export default {
  getSendModes,
  getLetters,
  getLetter,
  getLetterPrice,
  getLetterPriceWithDate,
  getLetterPostingDate,
  postLetter,
  putLetter,
  postLetterDocument,
  postSendLetter,
  deleteDocument,
  stapleLetter,
  unstapleLetter,
  putReorderDocuments,
  putCancel,
  deleteLetter,
  getUserInformations,
  getCitiesForPostCode
}
